import { getImagesFromUploadedFiles } from '@actions/ConvertActions';
import { getNewsRelatedIds } from '@actions/NewsActions';
import { constraints, texts } from '@app';
import CustomCarousel from '@atoms/CustomCarousel';
import CustomDatePicker from '@atoms/CustomDatePicker';
import MuiDebouncedInput from '@atoms/MuiDebouncedInput';
import NewsConceptButton from '@atoms/NewsConceptButton';
import ConfirmActionModalWithButton from '@organisms/ConfirmActionModalWithButton';
import VisualsHandler from '@organisms/VisualsHandler';
import {
	CreatingNews,
	EnumEntityTypes,
	EnumNewsFields,
	EnumSports,
	NewsImageWithFile,
	UploadedFile,
} from '@types';
import { dayjs } from '@utils/date';

type VerticalNewsProps = Omit<CreatingNews, 'mainImage'> & {
	mainImage?: NewsImageWithFile;
	setMainImage?: (image?: NewsImageWithFile) => void;
	setSecondaryImages?: (images?: NewsImageWithFile[]) => void;
	deleteNews?: () => void;
	updateNewsField?: (field: EnumNewsFields, value: string) => void;
	preventImageDeletion?: boolean;
	entityType?: EnumEntityTypes;
	sport?: EnumSports;
};

const VerticalNews = ({
	setMainImage,
	mainImage,
	setSecondaryImages,
	secondaryImages = [],
	date,
	title,
	titleColor,
	subtitle,
	subtitleColor,
	description,
	descriptionColor,
	finalText,
	finalTextColor,
	mainImageBackgroundColor,
	backgroundColor,
	secondaryImagesBackgroundColor,
	deleteNews,
	updateNewsField,
	preventImageDeletion,
	entityType,
	sport,
	relatedId,
	secondaryRelatedId,
}: VerticalNewsProps) => {
	const handleSetSecondaryImages = setSecondaryImages
		? (images: UploadedFile[]) => {
				setSecondaryImages([
					...secondaryImages,
					...getImagesFromUploadedFiles(images).map((image) => ({
						...image,
						uri: image.mediaUri,
						file: image.file,
						isMain: false,
					})),
				]);
		  }
		: undefined;

	return (
		<div id={texts.verticalNews} className="border border-black px-4 py-2">
			<div
				className="w-full flex justify-center xs:p-4"
				style={{ backgroundColor: mainImageBackgroundColor }}>
				<VisualsHandler
					onMediaSaved={
						setMainImage
							? (images) =>
									setMainImage({
										...getImagesFromUploadedFiles(images)[0],
										uri: images[0].mediaUri,
										file: images[0].file,
										isMain: true,
									})
							: undefined
					}
					onMediaDeleted={
						setMainImage && !preventImageDeletion
							? () => setMainImage(undefined)
							: undefined
					}
					maxAmount={constraints.NEWS_MAX_PRIMARY_IMAGES}
					mediaUri={mainImage?.uri ?? undefined}
					visualName={texts.newsImage}
					editable={!!setMainImage}
					expandableWhenDisabled
				/>
			</div>
			<div
				className="p-2 flex-col flex justify-between "
				style={{
					backgroundColor,
					wordBreak: 'break-word',
				}}>
				{(date || updateNewsField) &&
					(updateNewsField ? (
						<div className="w-full m-2    md:w-2/3 md:mx-auto">
							<CustomDatePicker
								placeholder={texts.date}
								value={date ? dayjs.utc(date).format('YYYY-MM-DD') : undefined}
								setValue={(newValue) => {
									if (!newValue) return;
									const newDate = dayjs.utc(newValue).format('YYYY-MM-DD');
									if (date !== newDate)
										updateNewsField(EnumNewsFields.date, newDate);
								}}
							/>
						</div>
					) : (
						<div className="text-center">{texts.formatDateWithText(date)}</div>
					))}

				{updateNewsField ? (
					<div className="w-full m-2    md:w-2/3 md:mx-auto">
						<MuiDebouncedInput
							value={title ?? ''}
							updateText={(value) =>
								updateNewsField(EnumNewsFields.title, value)
							}
							type="text"
							placeholder={texts.title}
						/>
					</div>
				) : (
					<p
						className="text-3xl text-center"
						style={{ color: titleColor, wordBreak: 'break-word' }}>
						{title}
					</p>
				)}

				{updateNewsField ? (
					<div className="w-full m-2    md:w-2/3 md:mx-auto">
						<MuiDebouncedInput
							value={subtitle ?? ''}
							updateText={(value) =>
								updateNewsField(EnumNewsFields.subtitle, value)
							}
							type="text"
							placeholder={texts.subtitle}
						/>
					</div>
				) : (
					<p
						className="text-center"
						style={{ color: subtitleColor, wordBreak: 'break-word' }}>
						{subtitle}
					</p>
				)}

				{updateNewsField ? (
					<div className="w-full m-2    md:w-2/3 md:mx-auto">
						<MuiDebouncedInput
							value={description ?? ''}
							updateText={(value) =>
								updateNewsField(EnumNewsFields.description, value)
							}
							type="text"
							placeholder={texts.description}
						/>
					</div>
				) : (
					<p
						className="text-2xl text-center pb-10"
						style={{ color: descriptionColor, wordBreak: 'break-word' }}>
						{description}
					</p>
				)}
			</div>
			<div
				className="p-5"
				style={{ backgroundColor: secondaryImagesBackgroundColor }}>
				<CustomCarousel itemsToShow={3} isRTL={false}>
					{secondaryImages?.map((secondaryImage, index) => (
						<div
							className="px-1 sm:px-4"
							key={`${secondaryImage?.uri}${index}`}>
							<VisualsHandler
								onMediaSaved={handleSetSecondaryImages}
								onMediaDeleted={
									setSecondaryImages && !preventImageDeletion
										? () => {
												setSecondaryImages(
													secondaryImages.filter(
														(image) => image !== secondaryImage
													)
												);
										  }
										: undefined
								}
								visualName={texts.carouselImages}
								mediaUri={secondaryImage?.uri}
								editable={!!setSecondaryImages}
								expandableWhenDisabled
							/>
						</div>
					))}
					{setSecondaryImages && (
						<VisualsHandler
							onMediaSaved={handleSetSecondaryImages}
							maxAmount={constraints.NEWS_MAX_SECONDARY_IMAGES}
							visualName={texts.carouselImages}
						/>
					)}
				</CustomCarousel>

				{updateNewsField ? (
					<div className="w-full m-2    md:w-2/3 md:mx-auto">
						<MuiDebouncedInput
							value={finalText ?? ''}
							updateText={(value) =>
								updateNewsField(EnumNewsFields.finalText, value)
							}
							type="text"
							placeholder={texts.finalText}
						/>
					</div>
				) : (
					<p
						className="text-3xl text-center"
						style={{ color: finalTextColor, wordBreak: 'break-word' }}>
						{finalText}
					</p>
				)}
			</div>

			{entityType && (
				<div className="my-2">
					<NewsConceptButton
						relatedIds={getNewsRelatedIds({
							relatedId,
							secondaryRelatedId,
						})}
						entityType={entityType}
						sport={sport}
					/>
				</div>
			)}

			{deleteNews && (
				<div className="flex justify-center">
					<ConfirmActionModalWithButton
						onConfirm={deleteNews}
						buttonText={texts.deleteNews}
						modalQuestion={texts.confirmDeleteNews}
						modalTitle={texts.deleteNews}
						triggerButtonClassName="button-delete mt-2"
					/>
				</div>
			)}
		</div>
	);
};

export default VerticalNews;
