import './index.css';
import './styles/components.scss';
import './styles/dimentions.scss';
import './styles/colors.scss';
import './styles/texts.scss';
import './styles/buttons.scss';
import 'react-toastify/dist/ReactToastify.css';

import { persistor, store } from '@app';
import Loader from '@atoms/Loader';
import { ThemeProvider } from '@mui/material';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import Router from './Router';
import theme from './theme';

declare global {
	interface Window {
		Cypress?: any; // eslint-disable-line
		store?: any; // eslint-disable-line
	}
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate loading={<Loader />} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<Suspense fallback={<Loader />}>
						<Router />
						<ToastContainer
							position="bottom-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</Suspense>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</StrictMode>
);

// expose store when run in Cypress
if (window.Cypress) {
	window.store = store;
}
