// eslint-disable-next-line
import { convertToBackend } from '@actions/ConvertActions';
import {
	putFilesRequest as putUserFilesRequest,
	putNewsFilesRequest,
	putPlaceFilesRequest,
	putTeamFilesRequest,
	getBlobRequest,
	putClubFilesRequest,
	getRequest,
	putStageFilesRequest,
	putTournamentFilesRequest,
} from '@app';
import { EnumFileTypes, UploadedFile } from '@types';

const MediaService = {
	uploadTeamMediaService: async (
		files: UploadedFile[],
		teamId: number,
		type: EnumFileTypes
	) => putTeamFilesRequest('upload_team_images.php', files, type, teamId),

	uploadClubMediaService: async (
		files: UploadedFile[],
		clubId: number,
		type: EnumFileTypes
	) => putClubFilesRequest('upload_club_images.php', files, type, clubId),

	uploadUserMediaService: async (
		files: UploadedFile[] | null,
		type: EnumFileTypes,
		userId: number
	) => putUserFilesRequest('upload_user_images.php', files, type, userId),

	uploadStageMediaService: async (
		files: UploadedFile[],
		type: EnumFileTypes,
		stageNumber: number,
		tournamentId: number
	) =>
		putStageFilesRequest(
			'upload_stage_images.php',
			files,
			type,
			stageNumber,
			tournamentId
		),

	uploadTournamentMediaService: async (
		files: UploadedFile[],
		type: EnumFileTypes,
		tournamentId: number
	) =>
		putTournamentFilesRequest(
			'upload_tournament_images.php',
			files,
			type,
			tournamentId
		),

	// Expected files order: [locationImage, internalImage, entranceImage, logo]
	uploadFieldMediaService: async (
		files: (UploadedFile | undefined)[],
		placeId: number,
		fieldName: string,
		type: EnumFileTypes
	) =>
		putPlaceFilesRequest(
			'upload_field_images.php',
			files,
			type,
			placeId,
			fieldName
		),
	updatePlaceLogoService: async (placeId: number, logo: UploadedFile) => {
		const response = await putPlaceFilesRequest(
			'update_place_logo.php',
			[logo],
			EnumFileTypes.IMAGE,
			placeId
		);
		return response;
	},
	updatePlaceEntranceImageService: async (
		placeId: number,
		entranceImage: UploadedFile
	) => {
		const response = await putPlaceFilesRequest(
			'update_place_entrance_image.php',
			[entranceImage],
			EnumFileTypes.IMAGE,
			placeId
		);
		return response;
	},

	uploadNewsImagesService: async (
		files: UploadedFile[],
		newsId: number,
		type: EnumFileTypes,
		includesMainImage: boolean
	) =>
		putNewsFilesRequest(
			'upload_news_images.php',
			files,
			type,
			newsId,
			includesMainImage
		),

	getImageByProxy: async (url: string) =>
		getBlobRequest(
			'proxy.php',
			convertToBackend({
				url,
			}) as Record<string, string>
		),

	getLinkForQrTokenService: async (token: string) =>
		getRequest(
			'get_link_for_qr_token.php',
			convertToBackend({
				token,
			}) as Record<string, string>
		),
};

export default MediaService;
