import { EnumSports } from '@types';

const uris = {
	// --- PAGES ---
	// HOME
	glossary: 'glosario',
	rules: 'reglamentos',
	home: 'home',
	howItWorks: 'como-funciona',
	doAction: 'realizar-accion',
	notifications: 'notificaciones',
	searchResults: 'resultados-de-busqueda',
	terms: 'terminos-y-condiciones',

	// USAGE PLANS
	usagePlans: 'planes-de-uso',
	makePayment: 't3/make-payment',

	// LOGIN / REGISTER
	login: 'ingresar',
	register: 'registrarse',
	restorePassword: 'restaurar-clave',

	// MAIN
	mainTournamentManager: 'inicio-organizador-torneos',
	mainAdmin: 'inicio-admin',
	mainCoach: 'inicio-entrenador',
	mainPlayer: 'inicio-jugador',
	mainCollaborator: 'inicio-colaborador',
	mainReferee: 'inicio-arbitro',
	mainFieldResponsible: 'inicio-responsable-de-canchas',
	managerTeams: 'equipos-del-organizador',
	managerPlayers: 'jugadores-del-organizador',
	managerReferees: 'arbitros-del-organizador',
	coachPlayers: 'jugadores-del-entrenador',
	nextAdminTournaments: 'proximos-torneos-del-admin',
	inCourseAdminTournaments: 'torneos-en-curso-del-admin',
	adminTeams: 'equipos-del-admin',
	adminPlayers: 'jugadores-del-admin',
	adminReferees: 'arbitros-del-admin',

	// PROFILE
	profile: 'perfil',

	// QR
	qr: 'qr',

	// TOURNAMENTS AND STAGES
	createTournament: 'crear-torneo',
	createStageIntoTournament: 'crear-competencia-en-torneo',
	defineLeagueMatches: 'definir-cruces-liga',
	definePlayoffBrackets: 'definir-llaves-playoff',
	finishStage: 'terminar-competencia',
	listNews: 'listar-noticias',
	listTournaments: 'listar-torneos',
	listStages: 'listar-competencias',
	viewLeague: 'liga',
	viewPlayoff: 'playoff',
	viewTournament: 'torneo',

	// TEAMS
	viewTeam: 'equipo',

	// CLUBS
	viewClub: 'club',

	// MATCHES
	viewVolleyballMatch: 'partido-voleibol',
	viewBasketballMatch: 'partido-basquetbol',
	viewFootball11Match: 'partido-futbol-11',
	viewFootball5Match: 'partido-futbol-5',
	viewBabyFootballMatch: 'partido-baby-futbol',
	viewFootball7Match: 'partido-futbol-7',
	viewFootball8Match: 'partido-futbol-8',
	createFriendlyMatch: 'crear-partido-amistoso',
	viewMatch: (matchSport: EnumSports) => {
		if (!matchSport) console.error(`Wrong sport: ${matchSport}`); // eslint-disable-line no-console
		return matchSport === EnumSports.football11
			? 'partido-futbol-11'
			: matchSport === EnumSports.football5
			? 'partido-futbol-5'
			: matchSport === EnumSports.babyFootball
			? 'partido-baby-futbol'
			: matchSport === EnumSports.football7
			? 'partido-futbol-7'
			: matchSport === EnumSports.football8
			? 'partido-futbol-8'
			: matchSport === EnumSports.volleyball
			? 'partido-voleibol'
			: matchSport === EnumSports.basketball
			? 'partido-basquetbol'
			: null;
	},

	// FIELDS
	fields: 'canchas',
	viewPlace: 'ver-complejo',

	// PARAMS
	paramAction: 'accion',
	paramClubId: 'id-club',
	paramConcept: 'concepto',
	paramConceptId: 'id-concepto',
	paramConceptSecondaryId: 'id-concepto-secundaria',
	paramControl: 'control',
	paramFieldName: 'nombre-de-cancha',
	paramFilterResults: 'filtrar-resultados',
	paramMatchId: 'id-partido',
	paramNotificationId: 'id-notificacion',
	paramPageToRedirect: 'redirigir-a-pagina',
	paramPlaceId: 'id-complejo',
	paramSearch: 'buscar',
	paramSearchFilter: 'filtro',
	paramShowInCourseMatches: 'partidos-en-directo',
	paramStageNumber: 'num-competencia',
	paramTeamId: 'id-equipo',
	paramToken: 'token',
	paramTournamentId: 'id-torneo',
	paramUserId: 'id-usuario',

	// HASH PARAMS
	hashParamTeams: 'equipos',
	hashParamMatches: 'partidos',
};

export default uris;
