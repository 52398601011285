import ReactElasticCarousel, {
	ReactElasticCarouselProps,
} from 'react-elastic-carousel';

type CustomCarouselProps = ReactElasticCarouselProps & {
	children: React.ReactNode;
};

const CustomCarousel = ({ children, ...props }: CustomCarouselProps) => (
	<ReactElasticCarousel {...props}>{children}</ReactElasticCarousel>
);

export default CustomCarousel;
