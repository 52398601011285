import './styles.scss';

import Loader from '@atoms/Loader';

type LoaderModalProps = {
	title?: string;
};

const LoaderModal = ({ title }: LoaderModalProps) => (
	<div className="absolute h-full w-full">
		{title ? (
			<div className="w-full flex justify-center mt-12">
				<div className="load-1 text-center">
					<p
						className={`text-main-page1 ${
							title.length < 8
								? 'text-4xl'
								: title.length < 20
								? 'text-3xl'
								: 'text-2xl'
						} w-full flex justify-center mt-12 mb-4`}>
						{title}
					</p>
					<div className="line" />
					<div className="line" />
					<div className="line" />
				</div>
			</div>
		) : (
			<Loader />
		)}
	</div>
);

export default LoaderModal;
