import { TextAndValue } from '@types';

type SelectProps<T extends number | string> = {
	className?: string;
	defaultOptionText?: string;
	handleChange: (option: string) => void;
	id?: string;
	initialValue?: T;
	options: TextAndValue<T>[];
	tabIndex?: number;
	disabled?: boolean;
};

const Select = <T extends number | string>({
	className = 'form-select appearance-none block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none',
	defaultOptionText,
	handleChange,
	id,
	initialValue,
	options,
	tabIndex,
	disabled = false,
}: SelectProps<T>) => (
	<select
		id={id}
		className={className}
		onChange={(event) => handleChange(event.target.value)}
		value={initialValue ?? -1}
		tabIndex={tabIndex}
		disabled={disabled}>
		{defaultOptionText && (
			<option key={-1} value={-1}>
				{defaultOptionText}
			</option>
		)}
		{options.map((option, index) => (
			<option key={index} value={option.value}>
				{option.text}
			</option>
		))}
	</select>
);

export default Select;
