import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { LoggedUser, Notification, Team } from '@types';
import { dayjs } from '@utils/date';

const initialState: LoggedUser = {
	isCoach: false,
	isPlayer: false,
	isAdmin: false,
	isTournamentManager: false,
	isCollaborator: false,
	isReferee: false,
	isFieldResponsible: false,
	isUserWithFacebook: false,
	isUserWithGoogle: false,
	capabilities: [],
	notifications: [],
	belongingTeams: [],
	email: '',
};

export const Slice = createSlice({
	name: 'loggedUser',
	initialState,
	reducers: {
		login: (_, action: PayloadAction<LoggedUser>) => ({
			...action.payload,
			refreshDatetime: dayjs.utc().toDate(),
		}),
		logout: (_) => initialState,
		setNotifications: (
			state,
			{ payload }: PayloadAction<Notification[] | undefined>
		) => ({
			...state,
			notifications: payload,
		}),
		setProfilePhoto: (
			state,
			{
				payload: { profilePhotoUri, profilePhotoId },
			}: PayloadAction<{
				profilePhotoUri: string | undefined;
				profilePhotoId: number | undefined;
			}>
		) => ({
			...state,
			profilePhotoUri,
			profilePhotoId,
		}),
		setBelongingTeams: (state, { payload }: PayloadAction<Team[]>) => ({
			...state,
			belongingTeams: payload,
		}),
		addBelongingTeam: (state, { payload }: PayloadAction<Team>) => ({
			...state,
			belongingTeams: [...(state?.belongingTeams ?? []), payload],
		}),
		setLoggedUserRefreshed: (
			state,
			{ payload: { refreshDatetime } }: PayloadAction<{ refreshDatetime: Date }>
		) => ({
			...state,
			refreshDatetime,
		}),
	},
});

export const {
	login,
	logout,
	setNotifications,
	setProfilePhoto,
	addBelongingTeam,
	setBelongingTeams,
	setLoggedUserRefreshed,
} = Slice.actions;

export default Slice.reducer;
