import { texts, uris } from '@app';
import { EnumEntityTypes, EnumSports } from '@types';

type NewsConceptButtonProps = {
	relatedIds?: number[];
	entityType: EnumEntityTypes;
	sport?: EnumSports;
};

const NewsConceptButton = ({
	relatedIds,
	entityType,
	sport,
}: NewsConceptButtonProps) => {
	if (!relatedIds || (entityType === EnumEntityTypes.match && !sport))
		return null;

	const href = {
		[EnumEntityTypes.match]: `/${uris.viewMatch(
			sport ?? EnumSports.babyFootball
		)}?${uris.paramMatchId}=${relatedIds[0]}`,
		[EnumEntityTypes.league]: `/${uris.viewLeague}?${uris.paramStageNumber}=${relatedIds[0]}&${uris.paramTournamentId}=${relatedIds[1]}`,
		[EnumEntityTypes.playoff]: `/${uris.viewPlayoff}?${uris.paramStageNumber}=${relatedIds[0]}&${uris.paramTournamentId}=${relatedIds[1]}`,
		[EnumEntityTypes.tournament]: `/${uris.viewTournament}?${uris.paramTournamentId}=${relatedIds[0]}`,
		[EnumEntityTypes.team]: `/${uris.viewTeam}?${uris.paramTeamId}=${relatedIds[0]}`,
		[EnumEntityTypes.field]: `/${uris.viewPlace}?${uris.paramPlaceId}=${relatedIds[0]}`,
	}[entityType];

	const text = {
		[EnumEntityTypes.match]: texts.viewMatch,
		[EnumEntityTypes.league]: texts.viewLeague,
		[EnumEntityTypes.playoff]: texts.viewPlayoff,
		[EnumEntityTypes.tournament]: texts.viewTournament,
		[EnumEntityTypes.team]: texts.viewTeam,
		[EnumEntityTypes.field]: texts.viewPlace,
	}[entityType];

	return (
		<div className="flex justify-center items-center pb-4">
			<a
				href={href}
				title={text}
				className="mini-button-1 text-sm sm:text-base 2xl:text-2xl">
				{text}
			</a>
		</div>
	);
};

export default NewsConceptButton;
