const terms = [
	{
		term: 'Finalidad de este documento',
		definition:
			'El objetivo del presente documento es establecer las reglas básicas para que los usuarios puedan utilizar el sistema Multitorneos, propiedad de Panaweb Informática.',
	},
	{
		term: 'Objetivo general de Multitorneos',
		definition:
			'Multitorneos es un sistema en línea de gestión de torneos físicos en varias disciplinas deportivas, para ser utilizado por organizadores de torneos, jugadores, árbitros, directores técnicos, colaboradores y equipos que participan en deportes amateur, así como por espectadores de los mismos.',
	},
	{
		term: 'Objetivos funcionales',
		definition:
			'Los objetivos funcionales de Multitorneos son ayudar a mejorar la experiencia de los usuarios, a través de la máxima automatización posible de los procedimientos de creación y gestión de torneos, facilitando el registro y actualización de datos de los participantes a través de la autogestión de los mismos y ofreciendo a los usuarios, tanto participantes como espectadores, toda la información relevante de cada instancia de los torneos, antes, durante su ejecución y después de su finalización.',
	},
	{
		term: 'Registro y reglas de funcionamiento',
		definition:
			'Todo usuario que utiliza el sistema Multitorneos está obligado a cumplir con las reglas que se establecen en el presente documento, aceptando de forma expresa los términos y condiciones dispuestos en el mismo y sin contravenir la legislación vigente en la República Oriental del Uruguay, la buena fe y el orden público. El usuario queda informado, y acepta, que el mero acceso a Multitorneos no supone, en modo alguno, el inicio de una relación comercial con Panaweb Informática.',
	},
	{
		term: 'Campos obligatorios del formulario de registro',
		definition:
			'Para convertirse en usuario de Multitorneos se deberán completar todos los campos obligatorios del formulario de registro. Los datos se deben incorporar en forma completa y veraz; teniendo la carga el usuario de mantener actualizados sus datos realizar su actualización o modificación.',
	},
	{
		term: 'Edad mínima para ser usuario',
		definition:
			'Puede ser usuario toda persona mayor de 14 años. Menores de 14 años pueden ser incorporados al sistema por un usuario en una categoría denominada “noUsuario” y el usuario que lo incorpore será responsable de las informaciones incluidas en el perfil del “noUsuario”.',
	},
	{
		term: 'Verificación de los datos brindados',
		definition:
			'Multitorneos podrá requerir cualquier documentación pertinente para corroborar los datos brindados por el usuario. En el caso que el usuario se niegue a dar la documentación solicitada, se lo podrá suspender hasta corroborar que la información es correcta.',
	},
	{
		term: 'Cuenta personal y acceso a la misma',
		definition:
			'El usuario tendrá una cuenta personal, a la cual accederá a través del ingreso del usuario y la clave de seguridad personal que escoja. La cuenta es personal, intransferible y única, debiéndola mantener de forma confidencial.',
	},
	{
		term: 'Prohibición de uso de la información disponible en Multitorneos con fines comerciales',
		definition:
			'Ningún tercero podrá utilizar con fines comerciales, ni con ánimo de lucro, la información disponible en Multitorneos, ni tampoco copiar la información para la utilización en otro sitio web con semejante o diferente finalidad que perjudique de forma directa o indirecta a Panaweb Informática, salvo que exista consentimiento expreso y por escrito de los representantes de Panaweb Informática.',
	},
	{
		term: 'Uso responsable',
		definition:
			'El usuario deberá hacer uso del sistema Multitorneos procurando no causar ningún perjuicio a otros usuarios, ni a la seguridad del sistema; se prohíbe el envío de spam, mensajes no deseados, virus o cualquier otra conducta violatoria de los usos honestos de Internet. Se prohíbe el uso indebido de Multitorneos debiendo el usuario utilizarlo con la única finalidad de registrar y acceder a información relacionada con la práctica deportiva. Cualquier uso ilícito o indebido de Multitorneos y su contenido será responsabilidad exclusiva del usuario.',
	},
	{
		term: 'Publicación de avisos y mensajes',
		definition:
			'El usuario puede publicar avisos y/o enviar mensajes conforme a sus necesidades logístico - deportivas. El usuario tendrá distintas opciones en la publicación de avisos, de acuerdo a las reglas que establezca Panaweb Informática . El usuario es el responsable de la redacción del texto del aviso o mensaje. Dicho texto puede contener imágenes, logotipos, marcas, mensaje, audio y/o cualquier otra información pertinente. Los avisos, mensajes e información deportiva publicados por parte de los usuarios en el sistema Multitorneos deben ser reales y veraces. El usuario debe tener un comportamiento diligente y responsable, no publicando avisos o mensajes que vulneren la normativa legal, las buenas costumbres y/o el orden público. Se prohíbe la inserción de mensajes que vulneren la normativa nacional y/o internacional en la materia; que su objeto sea realizar actividades prohibidas por razones legales, de buenas costumbres y/o orden público; que puedan dañar la imagen de Panaweb Informática; que no cumpla con las condiciones establecidas en el presente documento o no tengan la calidad que Panaweb Informática pretende para los mensajes a publicarse en el sistema.',
	},
	{
		term: 'Incumplimiento de requisitos',
		definition:
			'En caso que publique un aviso que no cumpla con los requisitos establecidos en el presente documento, Multitorneos tiene derecho a no publicarlo o proceder a la baja o cancelación del mismo.',
	},
	{
		term: 'Respeto a derechos de terceros',
		definition:
			'El usuario debe abstenerse de publicar cualquier contenido que infrinja derechos de terceros en materia de marca, nombre comercial, derechos de imagen o derechos de autor. Asimismo, debe abstenerse de publicar cualquier contenido difamatorio o injurioso para cualquier tercero.',
	},
	{
		term: 'Política de Privacidad',
		definition:
			'La presente Política de Privacidad es de aplicación exclusiva para sitios, portales, servicios o contenidos de Multitorneos y no se extiende a los enlaces hacia otros sitios, portales, servicios o contenidos de distinta titularidad.',
	},
	{
		term: 'Tratamiento de datos',
		definition:
			'Autoriza expresamente a Panaweb Informática a que procese, use, transfiera, almacene y/o trate la información personal ingresada con la finalidad de los mismos sean publicados en el sistema Multitorneos, y/o para que Panaweb Informática desarrolle actividades promocionales individuales o conjuntas con las empresas aliadas y organizaciones deportivas que brinden beneficios a sus usuarios.',
	},
	{
		term: 'Derechos del usuario',
		definition:
			'Los datos que se brinden de los usuarios por parte de Panaweb Informática tendrán como finalidad la reflejada en el presente documento, ya sea para el registro de información deportiva y/o para la recepción de ofertas y promociones de bienes y servicios relacionados directa o indirectamente con la práctica deportiva. El usuario declara que los datos proporcionados son veraces, teniendo derecho a rectificar, incluir, suprimir y/o actualizar sus datos.',
	},
	{
		term: 'Perfil del usuario',
		definition:
			'El usuario de Multitorneos puede incluir informaciones para su perfil, consistente en equipos en los que juega o jugó, competiciones en las que participa, resultados deportivos, edad, peso, estatura, entre otras, así como incluir imágenes dentro del mismo, siempre que se encuadren dentro de las limitaciones establecidas en la presente Política de Privacidad. En el caso de los “noUsuarios”, los usuarios que incluyan imágenes de personas en los perfiles de los mismos deberán contar con el consentimiento de la persona cuya imagen fue incluida. En el caso de los menores de edad, los usuarios que incluyan imágenes de los mismos deberán contar con el consentimiento de los responsables de los menores en cuestión (Ley 17.823 de 07/09/2004, Artículo 11).',
	},
	{
		term: 'Datos sensibles',
		definition:
			'El usuario no tiene que ingresar datos sensibles acerca de sus opciones políticas, raza, preferencias sexuales u otro dato que no tenga relación a la práctica deportiva. Es potestad del usuario determinar si quiere o no ingresar datos sensibles. Se le recuerda al usuario que la base de datos opera de forma privada y no se permite a las empresas aliadas a Multitorneos la exploración o navegación en la misma. La Ley Nº 18.331, de 11 de agosto de 2008 (ley de habeas data) y el Decreto 414/009, de 21 de agosto de 2009 se aplican al tratamiento de los datos de los usuarios del sistema Multitorneos.',
	},
	{
		term: 'Visibilidad de datos',
		definition:
			'En caso de que el usuario se dé de alta en algún equipo, torneo o competencia ejecutado por alguna organización deportiva que tenga un acuerdo de cooperación tecnológica y comercial con Panaweb Informática, los datos referidos al perfil y su práctica deportiva serán visibles para dicha organización deportiva, otorgando el usuario pleno consentimiento tanto a Panaweb Informática como a la eventual organización deportiva para que los explore y/o navegue.',
	},
	{
		term: 'Transferencia de datos a terceros',
		definition:
			'La transferencia de datos a terceros se rige por los términos establecidos en la presente Política de Privacidad. Las empresas aliadas tienen acceso a algunos de los datos (nombre, correo electrónico, teléfono), cuando el usuario se comunica por una oferta comercial específica. En ese caso, sus datos son enviados a la empresa aliada. Es por eso que el usuario deberá ingresar exclusivamente aquellos datos que pretenda que sean conocidos por los demás. El usuario asume su exclusiva responsabilidad por los datos que ingresa en Multitorneos.',
	},
	{
		term: 'Compartición de datos',
		definition:
			'Panaweb Informática podrá compartir los datos de sus usuarios cuando lo requiera la autoridad competente, de acuerdo a lo previsto en la ley, o cuando se requiera para hacer cumplir las condiciones de uso establecidas en el presente documento.',
	},
	{
		term: 'Comunicaciones de ofertas',
		definition:
			'Sin perjuicio de lo anterior, Panaweb Informática podrá enviar comunicaciones de ofertas, promociones y descuentos de alguna empresa aliada tanto a la totalidad como a segmentos específicos de sus usuarios.',
	},
	{
		term: 'Derecho de baja',
		definition:
			'En el caso de no desear continuar registrado en las bases de datos de Multitorneos, el usuario tendrá opción a darse de baja (sign out) y a solicitar a Panaweb Informática la eliminación de sus datos personales. Panaweb eliminará los datos personales del usuario, pero las acciones del usuario en el sistema (participación en partidos, goles, etc.) permanecerán en la base de datos.',
	},
	{
		term: 'Medidas de seguridad',
		definition:
			'Panaweb Informática aplica medidas de actualización y monitoreo en materia de protección de datos. Se realizan las conductas pertinentes para contrarrestar posibles ataques a nuestro sistema informático. De todas formas, Panaweb Informática no puede garantizar la seguridad de la información brindada por los usuarios, ante posibles filtraciones de terceros a nuestros sistemas de seguridad.',
	},
	{
		term: 'Propiedad Intelectual',
		definition:
			'Panaweb Informática es propietario de los nombres de dominios: multitorneos.com y multitorneos.panaweb.uy, así como del sistema Multitorneos, y de todo el contenido del sitio web, consistente en: secciones, informaciones, imágenes, logos, marcas, etcétera; estando protegido dicho contenido por las leyes referentes a la propiedad intelectual, competencia desleal, régimen de competencia, y toda otra norma aplicable en el territorio de la República Oriental del Uruguay.',
	},
	{
		term: 'Marcas de Panaweb informática',
		definition:
			'Los usuarios deberán respetar los derechos de Panaweb Informática sobre sus marcas, contenidos, logos, etcétera; evitando cualquier comportamiento que suponga un aprovechamiento de los derechos de Panaweb Informática.',
	},
	{
		term: 'Ofertas comerciales',
		definition:
			'Panaweb Informática podrá enviar información a sus usuarios acerca de ofrecimientos comerciales de las empresas aliadas que ofrecen beneficios y descuentos a sus usuarios, promoción del sistema Multitorneos, información de organizaciones deportivas y cualquier otra información de interés; prestando su consentimiento expreso el usuario para recibir dicha información.',
	},
	{
		term: 'Ofertas de suscripción',
		definition:
			'Asimismo, el usuario presta su consentimiento para recibir ofertas de suscripción de pago a Multitorneos y de otros productos y servicios ofrecidos o promocionados por Panaweb Informática.',
	},
	{
		term: 'Responsabilidad de usuarios',
		definition:
			'El usuario deberá ingresar sus datos de forma veraz, siendo el único responsable al respecto, prestando su consentimiento de forma libre, previa e informada. El usuario es el único responsable ante los restantes usuarios, acerca de la veracidad y seriedad de los contenidos de la información que ingresa en Multitorneos, exonerando a Panaweb Informática de toda responsabilidad al respecto. Panaweb Informática no garantiza que los datos de los usuarios sean veraces; tampoco garantiza legitimidad, propiedad, cantidad, características de la información ingresada por los usuarios en el sistema Multitorneos. Cada usuario es responsable del uso que hace del sistema Multitorneos, a efectos de que el mismo sea conforme a los usos comerciales honestos. Panaweb Informática no responde por ningún tipo de daño que por el uso del sitio se le ocasione a otro usuario o un tercero. El usuario acepta que se hace exclusivo responsable de todo el contenido que publica en el sistema Multitorneos; que es propietario de dicho contenido; que el mismo no infringe con ninguna normativa legal; que no implica el incumplimiento de un contrato con otra persona.',
	},
	{
		term: 'Derechos de Panaweb Informática',
		definition:
			'<ul class="list-disc"><li>En caso de existir presuntas irregularidades en el uso de la web por parte de los usuarios, Panaweb Informática brindará toda la información necesaria cuando sea solicitada por la autoridad judicial competente y/o por la policía. Panaweb Informática se reserva el derecho de no publicar más información o dar de baja perfiles de usuario, en caso de constatar fehacientemente o mediante indicios claros de que hay usuarios que están adoptando conductas que perjudican a otros usuarios o terceros en general.</li><li>Panaweb Informática no publicará aquellos avisos o mensajes que sean contrarios a la ley, a las buenas costumbres y/o al orden público.</li><li>El usuario no tendrá derecho al reintegro del precio pagado por sus suscripciones, en caso que se proceda a bloquear su acceso al sistema por incumplir con las políticas de Panaweb Informática .</li><li>Panaweb Informática recopila datos de los usuarios a través de cookies y otras tecnologías similares.</li><li>El presente documento podrá ser modificado por Panaweb Informática cuando lo estime pertinente. El uso del sitio con posterioridad a la actualización, se interpretará como aceptación tácita del mismo.</li><li>Se pueden modificar tanto los términos y condiciones, como así también las políticas en materia de privacidad, cambios que siempre serán acordes a la normativa de la República Oriental del Uruguay.</li></ul>',
	},
	{
		term: 'Ley aplicable',
		definition:
			'La Jurisdicción competente será la de los Juzgados de la Ciudad de Montevideo Uruguay, quedando pactada la prórroga de competencia. La ley aplicable es la de la República Oriental del Uruguay. En el caso que Panaweb Informática constate incumplimientos a lo dispuesto en el presente contrato, se reservará el derecho a iniciar las acciones judiciales pertinentes.',
	},
	{
		term: 'Contacto',
		definition:
			'Por cualquier duda o acción que pretenda realizar el usuario con respecto a sus datos, se puede comunicar con Panaweb Informática enviando un email a <a class="font-bold" href="mailto:contacto@panaweb.uy">contacto@panaweb.uy</a>',
	},
];
export default terms;
