import { texts, uris } from '@app';
import { setIsLoading, setIsNotLoading } from '@reducers/GenericReducer';
import MatchService from '@services/MatchService';
import {
	BasketballMatch,
	BasketballMatchParticipation,
	EnumBasketballParticipationInMatchStatuses,
	EnumCapabilities,
	EnumEventTypes,
	EnumFootballParticipationInMatchStatuses,
	EnumFootballParticipationStatuses,
	EnumFoulTypes,
	EnumMatchStatuses,
	EnumParticipationStatuses,
	EnumRefereeRoles,
	EnumSides,
	EnumSports,
	EnumWalkoverTypes,
	Field,
	FootballMatch,
	FootballMatchParticipant,
	FootballMatchParticipation,
	Match,
	MatchEvent,
	MatchPenaltiesResult,
	MatchPeriod,
	MatchWithoutMembers,
	Place,
	Result,
	Set,
	TestingLinks,
	User,
} from '@types';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';

import { convertFromBackend } from './ConvertActions';
import { hasCapability } from './GenericActions';

type MatchTypes = Match | FootballMatch | BasketballMatch;

type ResultWithSet = Result & { set?: Set };
type ResultWithMatchId = Result & { matchId?: number };
type ResultWithTestingLinks = Result & TestingLinks;
export type ResultWithMatch<T extends MatchTypes> = Result & { match?: T } & {
	noUpdates?: boolean;
};

type GetLiveMatchesResponse = {
	matchesInCourse?: Match[];
	matchesUpcoming?: Match[];
};
type GetLiveMatchesActionResult = Result & GetLiveMatchesResponse;

export const updateMatchInfoAction =
	(
		matchId: number,
		field: string,
		value: string | number | null | { name: string; placeId: number },
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateMatchInfoService(
				matchId,
				field,
				value
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateFinishedMatchSetsAction =
	(matchId: number, sets: Set[], callback: (result: Result) => void) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateFinishedMatchSetsService(
				matchId,
				sets
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateFinishedBasketballMatchPeriodsAction =
	(
		matchId: number,
		periods: MatchPeriod[],
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response =
				await MatchService.updateFinishedBasketballMatchPeriodsService(
					matchId,
					periods
				);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const saveFootballMatchCompletedManuallyAction =
	(matchId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response =
				await MatchService.saveFootballMatchCompletedManuallyService(matchId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const inviteRefereeToMatchAction =
	(
		matchId: number,
		refereeId: number,
		role: EnumRefereeRoles,
		waitForConfirmation: boolean,
		callback: (result: ResultWithTestingLinks) => void
	) =>
	async () => {
		let result: ResultWithTestingLinks;

		try {
			const response = await MatchService.inviteRefereeToMatchService(
				matchId,
				refereeId,
				role,
				waitForConfirmation
			);
			const data = convertFromBackend(response.data) as TestingLinks;
			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				acceptLink: data.acceptLink,
				rejectLink: data.rejectLink,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const removeRefereeFromMatchAction =
	(matchId: number, refereeId: number, callback: (result: Result) => void) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.removeRefereeFromMatchService(
				matchId,
				refereeId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

type FieldsAndPlaces = { fields?: Field[]; places?: Place[] };
type ListFieldsForFriendlyActionResult = Result & FieldsAndPlaces;

export const listFieldsForFriendlyAction =
	(
		sport: EnumSports,
		callback: (result: ListFieldsForFriendlyActionResult) => void
	) =>
	async () => {
		let result: ListFieldsForFriendlyActionResult;

		try {
			const response = await MatchService.listFieldsForFriendlyService(sport);

			const { fields, places } = convertFromBackend(
				response.data
			) as FieldsAndPlaces;

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				fields,
				places,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const publishMatchAction =
	(
		tournamentId: number,
		stageNumber: number,
		matchId: number,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.publishMatchService(
				tournamentId,
				stageNumber,
				matchId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const inviteToFriendlyMatchAction =
	(
		localId: number,
		visitorId: number,
		fieldName: string | undefined,
		placeId: number | undefined,
		startDatetime: string | undefined,
		turn: number | undefined,
		message: string | undefined,
		setsAmount: number | undefined,
		scoreNoLastSet: number | undefined,
		scoreLastSet: number | undefined,
		callback: (result: ResultWithMatchId) => void
	) =>
	async () => {
		let result: ResultWithMatchId;

		try {
			const response = await MatchService.inviteToFriendlyMatchService(
				localId,
				visitorId,
				fieldName,
				placeId,
				startDatetime,
				turn,
				message,
				setsAmount,
				scoreNoLastSet,
				scoreLastSet
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				matchId: (convertFromBackend(response.data) as { matchId: number })
					.matchId,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const startMatchAction =
	(matchId: number, callback: (result: Result) => void) =>
	async (dispatch: Dispatch) => {
		let result: Result;

		try {
			dispatch(setIsLoading(texts.startingMatch));
			const response = await MatchService.startMatchService(matchId);
			dispatch(setIsNotLoading());

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			dispatch(setIsNotLoading());
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const completeMatchManuallyAction =
	(matchId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await MatchService.completeMatchManuallyService(matchId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const cancelCompleteMatchManuallyAction =
	(matchId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await MatchService.cancelCompleteMatchManuallyService(
				matchId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const resetMatchAction =
	(matchId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await MatchService.resetMatchService(matchId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateTeamParticipationListAction =
	(
		matchId: number,
		teamId: number,
		participationList:
			| FootballMatchParticipation[]
			| BasketballMatchParticipation[],
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateTeamParticipationListService(
				matchId,
				teamId,
				participationList
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const createFootballEventAction =
	(
		type: EnumEventTypes,
		periodNumber: number,
		time: number,
		matchId: number,
		playerId: number,
		side: EnumSides,
		sport: EnumSports,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.createFootballEventService(
				type,
				periodNumber,
				time,
				matchId,
				playerId,
				side,
				sport
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const createFootballSubstitutionsAction =
	(
		period: number,
		time: number,
		matchId: number,
		playersIncomingIds: number[],
		playersOutgoingIds: number[],
		side: EnumSides,
		sport: EnumSports,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.createFootballSubstitutionsService(
				period,
				time,
				matchId,
				playersIncomingIds,
				playersOutgoingIds,
				side,
				sport
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};
export const editFootballEventAction =
	(
		matchId: number,
		eventNumber: number,
		playerId: number,
		sport: EnumSports,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.editFootballEventService(
				matchId,
				eventNumber,
				playerId,
				sport
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const deleteFootballEventAction =
	(eventNumber: number, matchId: number, callback: (result: Result) => void) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.deleteFootballEventService(
				eventNumber,
				matchId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const finishPeriodAction =
	(matchId: number, periodNumber: number, callback: (result: Result) => void) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.finishPeriodService(
				matchId,
				periodNumber
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getMatchAction =
	<T extends MatchTypes>(
		matchId: number,
		callback: (result: ResultWithMatch<T>) => void
	) =>
	async () => {
		let result: ResultWithMatch<T>;

		try {
			const response = await MatchService.getMatchService(matchId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};

			if (result.success) {
				const match = convertFromBackend(response.data.match) as T;

				result.match = {
					...match,
					turn: match.turn !== null ? match.turn : undefined,
					local: {
						...match.local,
						members: match.local.members.map((member) => ({
							...member,
							shirtNumber: member.shirtNumber ?? undefined,
						})),
					},
					visitor: {
						...match.visitor,
						members: match.visitor.members.map((member) => ({
							...member,
							shirtNumber: member.shirtNumber ?? undefined,
						})),
					},
				};
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getMatchUpdatesAction =
	<T extends MatchTypes>(
		matchId: number,
		lastMutationNumber: number,
		callback: (result: ResultWithMatch<T>) => void
	) =>
	async () => {
		let result: ResultWithMatch<T>;

		try {
			const response = await MatchService.getMatchUpdatesService(
				matchId,
				lastMutationNumber
			);

			result = {
				success: response.data.code === 0,
				noUpdates: response.data.code === 204,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};

			if (result.success) {
				const match = convertFromBackend(response.data.match) as T;

				result.match = {
					...match,
					turn: match.turn !== null ? match.turn : undefined,
					local: {
						...match.local,
						members: match.local.members.map((member) => ({
							...member,
							shirtNumber: member.shirtNumber ?? undefined,
						})),
					},
					visitor: {
						...match.visitor,
						members: match.visitor.members.map((member) => ({
							...member,
							shirtNumber: member.shirtNumber ?? undefined,
						})),
					},
				};
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getLiveMatchesAction =
	(
		localeDiffInMinutes: number,
		callback: (result: GetLiveMatchesActionResult) => void
	) =>
	async () => {
		let result: GetLiveMatchesActionResult;

		try {
			const response = await MatchService.getLiveMatchesService(
				localeDiffInMinutes
			);
			const { matchesInCourse, matchesUpcoming } = convertFromBackend(
				response.data
			) as GetLiveMatchesResponse;
			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				matchesInCourse,
				matchesUpcoming,
			};
		} catch (error) {
			console.error(error); // eslint-disable-line no-console
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getCurrentSetAction =
	(matchId: number, callback: (result: ResultWithSet) => void) => async () => {
		let result: ResultWithSet;

		try {
			const response = await MatchService.getCurrentSetService(matchId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				set: convertFromBackend(response.data.set) as Set,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateBasketMatchFoulsAction =
	(input: {
		matchId: number;
		periodNumber: number;
		playerId: number;
		isLocalTeam: boolean;
		foulType: EnumFoulTypes;
		addFoul: boolean;
		callback: (result: Result) => void;
	}) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateBasketballMatchFouls(input);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		input.callback(result);
	};

export const updateFootballMatchFoulsAction =
	(input: {
		matchId: number;
		periodNumber: number;
		isLocalTeam: boolean;
		addFoul: boolean;
		callback: (result: Result) => void;
	}) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateFootballMatchFoulsService(
				input
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		input.callback(result);
	};

export const updateFootballMatchPenaltiesAction =
	(input: {
		matchId: number;
		firstKick: EnumSides;
		penalties: MatchPenaltiesResult;
		callback: (result: Result) => void;
	}) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.updateFootballMatchPenaltiesService(
				input
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		input.callback(result);
	};

export const applyWalkover =
	(
		matchId: number,
		walkoverType: EnumWalkoverTypes,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await MatchService.applyWalkover(matchId, walkoverType);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getBreadcrumbActivityTextForMatch = (
	matchStatus: EnumMatchStatuses,
	isEditable: boolean
) =>
	matchStatus === EnumMatchStatuses.IN_COURSE
		? isEditable
			? texts.manageInCourseMatch
			: texts.viewMatch
		: matchStatus === EnumMatchStatuses.PUBLISHED
		? isEditable
			? texts.manageNotStartedMatch
			: texts.viewMatch
		: matchStatus === EnumMatchStatuses.PENDING
		? isEditable
			? texts.managePendingMatch
			: texts.viewMatch
		: matchStatus === EnumMatchStatuses.FINISHED ||
		  matchStatus === EnumMatchStatuses.COMPLETING_MANUALLY
		? isEditable
			? texts.manageFinishedMatch
			: texts.viewMatch
		: isEditable
		? texts.manageNotPublishedMatch
		: texts.viewMatch;

export const handleMatchInfoChange = (
	field: string,
	value: string | number | null | Field,
	setMatch:
		| React.Dispatch<React.SetStateAction<MatchWithoutMembers>>
		| ((newMatch: MatchWithoutMembers) => void),
	match: MatchWithoutMembers,
	dispatch: Dispatch
) => {
	// Updating before going to the backend to enable match publishing immediately.
	const oldMatchInfo = { ...match };
	const newMatch: MatchWithoutMembers & Record<string, unknown> = match;
	newMatch[field] = value;
	setMatch({ ...newMatch });

	dispatch(
		updateMatchInfoAction(
			match.id,
			field,
			typeof value === 'object' && value && 'place' in value
				? { name: value.name, placeId: value.place.id }
				: value,
			(result) => {
				if (!result.success) {
					setMatch(oldMatchInfo);
					toast.error(
						result?.message !== '' ? result.message : texts.errorGeneric()
					);
				}
			}
		) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
	);
};

export const isPositiveGoalEvent = (type: EnumEventTypes) =>
	type === EnumEventTypes.goal ||
	type === EnumEventTypes.pkGoal ||
	type === EnumEventTypes.spkGoal;

export const isDrawInRegularTime = (match: FootballMatch) => {
	const eventsByPeriodMap = new Map<number, MatchEvent[]>();

	match.events?.forEach((event) => {
		if (!eventsByPeriodMap.has(event.period)) {
			eventsByPeriodMap.set(event.period, []);
		}
		eventsByPeriodMap.get(event.period)?.push(event);
	});

	const diffInRegularTime = match.periods?.reduce((diff: number, period) => {
		if (!match.periodAmount) return 0;

		let localScoreInPeriod = 0;
		let visitorScoreInPeriod = 0;

		const isNotRegularPeriod = period.number > match.periodAmount;

		if (!isNotRegularPeriod) {
			eventsByPeriodMap.get(period.number)?.forEach((event) => {
				localScoreInPeriod +=
					(event.side === EnumSides.LOCAL && isPositiveGoalEvent(event.type)) ||
					(event.side === EnumSides.VISITOR &&
						event.type === EnumEventTypes.ownGoal)
						? 1
						: 0;
				visitorScoreInPeriod +=
					(event.side === EnumSides.VISITOR &&
						isPositiveGoalEvent(event.type)) ||
					(event.side === EnumSides.LOCAL &&
						event.type === EnumEventTypes.ownGoal)
						? 1
						: 0;
			});
		}

		return diff + localScoreInPeriod - visitorScoreInPeriod;
	}, 0);

	return diffInRegularTime === 0;
};

export const isDrawInFullTime = (match: FootballMatch) => {
	const eventsByPeriodMap = new Map<number, MatchEvent[]>();

	match.events?.forEach((event) => {
		if (!eventsByPeriodMap.has(event.period)) {
			eventsByPeriodMap.set(event.period, []);
		}
		eventsByPeriodMap.get(event.period)?.push(event);
	});

	const goalsDiff = match.periods?.reduce((diff: number, period) => {
		let localScoreInPeriod = 0;
		let visitorScoreInPeriod = 0;

		eventsByPeriodMap.get(period.number)?.forEach((event) => {
			localScoreInPeriod +=
				(event.side === EnumSides.LOCAL && isPositiveGoalEvent(event.type)) ||
				(event.side === EnumSides.VISITOR &&
					event.type === EnumEventTypes.ownGoal)
					? 1
					: 0;
			visitorScoreInPeriod +=
				(event.side === EnumSides.VISITOR && isPositiveGoalEvent(event.type)) ||
				(event.side === EnumSides.LOCAL &&
					event.type === EnumEventTypes.ownGoal)
					? 1
					: 0;
		});

		return diff + localScoreInPeriod - visitorScoreInPeriod;
	}, 0);

	return goalsDiff === 0;
};

export const filterPlayerParticipatingInMatchCallback =
	(match: FootballMatch) => (member: FootballMatchParticipant) =>
		member.isPlayer &&
		((member.participationStatusInMatch &&
			member.participationStatusInMatch !==
				EnumFootballParticipationStatuses.pending &&
			member.participationStatusInMatch !==
				EnumFootballParticipationStatuses.notPlaying) ||
			match.status === // When completing manually, every player can be assigned to an event.
				EnumMatchStatuses.COMPLETING_MANUALLY ||
			match.status === // Once the match is finished, every player can be assigned to an event.
				EnumMatchStatuses.FINISHED);

export const getMatchParticipationFromStatus = <
	ParticipationStatuses extends
		| EnumFootballParticipationInMatchStatuses
		| EnumBasketballParticipationInMatchStatuses
		| undefined
>(
	status?: ParticipationStatuses | EnumParticipationStatuses
) => {
	if (
		status !== EnumParticipationStatuses.accepted &&
		status !== EnumParticipationStatuses.rejected
	) {
		return status as ParticipationStatuses;
	}

	return EnumParticipationStatuses.pending;
};

export const isCorrectViewMatchSport = (sport: EnumSports) => {
	const uri =
		sport === EnumSports.basketball
			? uris.viewBasketballMatch
			: sport === EnumSports.football11
			? uris.viewFootball11Match
			: sport === EnumSports.football5
			? uris.viewFootball5Match
			: sport === EnumSports.babyFootball
			? uris.viewBabyFootballMatch
			: sport === EnumSports.football7
			? uris.viewFootball7Match
			: sport === EnumSports.football8
			? uris.viewFootball8Match
			: uris.viewVolleyballMatch;
	return window.location.href.includes(uri);
};

export const redirectToCorrectViewMatch = (
	sport: EnumSports,
	idMatch: number
) => `/${uris.viewMatch(sport)}?${uris.paramMatchId}=${idMatch}`;

export const canManageOwnLiveMatch = (
	user: Partial<User>,
	match: MatchTypes,
	allowNotLiveMatches = false
) =>
	hasCapability(
		user.capabilities,
		EnumCapabilities.CAPABILITY_MANAGE_LIVE_OWN_MATCHES
	) &&
	(match.status === EnumMatchStatuses.IN_COURSE || allowNotLiveMatches) &&
	(match.local.members.some((member) => member.id === user.id) ||
		match.visitor.members.some((member) => member.id === user.id));
