const constraints = {
	TEAM_NAME_MAX_LENGTH: 32,
	TEAM_NAME_MIN_LENGTH: 3,

	CLUB_NAME_MAX_LENGTH: 64,
	CLUB_NAME_MIN_LENGTH: 3,

	STAGE_NAME_MIN_LENGTH: 3,

	TOURNAMENT_NAME_MIN_LENGTH: 4,

	USER_NICKNAME_MIN_LENGTH: 4,
	USER_NICKNAME_MAX_LENGTH: 32,

	SEARCH_CHARACTERS_MIN: 2,

	NEWS_MAX_PRIMARY_IMAGES: 1,
	NEWS_MAX_SECONDARY_IMAGES: 25,

	MIN_STARTERS_F5: 3,
	MIN_STARTERS_F7: 5,
	MIN_STARTERS_F8: 6,
	MIN_STARTERS_F11: 7,
	MIN_STARTERS_BASKETBALL: 5,
	MIN_STARTERS_VOLLEYBALL: 6,

	MAX_STARTERS_F5: 5,
	MAX_STARTERS_F7: 7,
	MAX_STARTERS_F8: 8,
	MAX_STARTERS_F11: 11,
	MAX_STARTERS_BASKETBALL: 5,
	MAX_STARTERS_VOLLEYBALL: 6,

	MAX_SCORE: 255,
};

export default constraints;
