const glossary = [
	{
		term: 'Competencias acumulativas.',
		definition:
			'Competencias que fusiona las tablas de posiciones de 2 o más ligas generando una única tabla de posiciones. Habitualmente son usadas para armar "tablas anuales" o "tablas generales".',
	},
	{
		term: 'Árbitro.',
		definition:
			'Usuario que participa imparcialmente en los partidos y tiene permisos para administrarlos, completando la información de éste (tanteadores, sanciones a jugadores, tiempos de juego y fuera de juego, así como otras características dependiendo del deporte). Los árbitros tienen subroles en los partidos como: principales, secundarios y asistentes; según el deporte.',
	},
	{
		term: 'Bye.',
		definition:
			'En el sistema de juego play-off, es la situación en que un equipo no tiene rival a quien enfrentar en una fase y pasa de fase sin jugar. Se usa para resolver situaciones en las que no hay una cantidad de participantes suficiente como para cruzar a todos los participantes de a pares y suele ser designado a los equipos que hayan llegado mejor posicionados a los play-offs.',
	},
	{
		term: 'Colaborador.',
		definition:
			'Es un usuario que participa en los equipos para colaborar con la gestión de los mismos. Al ingresar a un equipo el Colaborador será designado Dirigente.',
	},
	{
		term: 'Competencia.',
		definition:
			'Cada uno de los grupos de enfrentamientos entre los equipos que forman parte de un torneo. Un torneo puede tener una o más competencias. Las competencias pueden tener dos o más equipos y uno o más partidos. Se organizan bajo dos sistemas: liga y play-off.',
	},
	{
		term: 'Campeonato.',
		definition:
			'No se usa el término como tal en el sistema sino que se usa "competencia" como un sinónimo de este término',
	},
	{
		term: 'Dirigente.',
		definition:
			'Es un cargo que pueden tener los integrantes de un equipo (entrenadores, jugadores o colaboradores). Tiene que haber al menos un dirigente por cada equipo y puede haber tantos como se decida. Tiene permisos para: gestionar los integrantes del equipo; designar dirigentes; retirar cargo de dirigente a otros dirigentes; responder invitaciones hechas al equipo; organizar partidos amistosos para su equipo. Para ser dirigente de un equipo, un entrenador, jugador o colaborador debe crear el equipo (y queda automáticamente como dirigente) o haber sido designado como dirigente por un dirigente del equipo.',
	},
	{
		term: 'Entrenador.',
		definition:
			'Es un usuario que participa de los equipos y puede gestionar las listas de participación de los equipos en partidos si corresponde al deporte. Los equipos pueden tener cualquier cantidad de entrenadores y los entrenadores pueden integrar cualquier cantidad de equipos. En caso de no haber entrenadores, la gestión de las listas de participación queda a cargo de los dirigentes del equipo',
	},
	{
		term: 'Director técnico.',
		definition:
			'No se usa el término como tal en el sistema sino que se usa "entrenador" como sinónimo de este término.',
	},
	{
		term: 'Equipo.',
		definition:
			'Son las entidades que participan en las competencias y partidos. Están compuestos por información del equipo (Nombre, logo, fotos y otros) e integrantes (Colaboradores, Entrenadores y Jugadores). Los equipos estarán "En formación" hasta que alcancen las condiciones mínimas para poder competir, las cuales dependen del deporte del equipo.',
	},
	{
		term: 'Espectador.',
		definition:
			'Es un usuario que no ingresó al sistema. No requiere estar registrado en el sistema. Es cualquier persona que accede al sitio del sistema sin loguearse. Puede ver información de jugadores, torneos, partidos terminados y partidos en directo.',
	},
	{
		term: 'Ingresar (log in).',
		definition:
			'Procedimiento de usuario para identificarse ante el sistema y acceder a las funcionalidades restringidas para usuarios.',
	},
	{
		term: 'Jugador.',
		definition:
			'Es un usuario que participa de los equipos y puede protagonizar los eventos de los partidos si corresponde al deporte. Los equipos pueden tener cualquier cantidad de jugadores y los jugadores pueden integrar cualquier cantidad de equipos.',
	},
	{
		term: 'Liga (todos contra todos).',
		definition:
			'Sistema de enfrentamientos entre varios equipos en el que todos los competidores se tienen que enfrentar a cada uno de los demás. A lo largo de la competencia, los equipos van sumando puntos en función de sus resultados en cada enfrentamiento; los puntos ganados dependen del deporte. De este modo, se establece una tabla de posiciones donde los competidores quedan clasificados en función de los puntos acumulados a lo largo de la liga.',
	},
	{
		term: 'MultiTorneos.',
		definition:
			'Sistema online de gestión de torneos y competencias físicos (no eSports) en varias disciplinas deportivas, para ser utilizado por personas que se dediquen a la organización de ese tipo de torneos, para los participantes en esos torneos (dirigentes, entrenadores, jugadores y árbitros) y para los espectadores de esos torneos.',
	},
	{
		term: 'Jugadores/Entrenadores noUsuarios.',
		definition:
			'Son integrantes de equipos que no corresponden a personas del mundo real sino que están registrados en el sistema como si fueran usuarios pero son registrados y gestionados por los dirigentes del equipo al que pertenecen. Son ideales para que menores de edad puedan ser registrados en el sistema por parte de los adultos a su cargo. Pueden ser convertidos en usuarios si el dirigente lo decide y al hacerlo se les debe asociar la dirección de email de la persona del mundo real que lo gestionará a partir de la conversión.',
	},
	{
		term: 'Noticias.',
		definition:
			'Una noticia es una información sobre un evento ocurrido o por ocurrir en la vida real que se considera interesante divulgar en nuestro sistema. Dicho evento se expresa a través de  texto, imágenes, videos u otros por parte de quien lo creó. Las noticias no son notificaciones automáticas.',
	},
	{
		term: 'Notificaciones automáticas.',
		definition:
			'Una notificación automática es el proceso mediante el cual se informa a un usuario acerca de un acontecimiento en el sistema que le incumbe. Las notificaciones se envían automáticamente y en dos vías: como notificación en el sistema y como email al usuario.',
	},
	{
		term: 'Organizador de torneos.',
		definition:
			'Es un usuario que gestiona torneos en el sistema, desde la creación y ejecución de competencias hasta la difusión de información en estos a través de noticias. Para gestionar los partidos dentro de las competencias de sus torneos, se encarga de establecer qué árbitros participarán de cada partido, en qué momento se disputará y en qué lugar.',
	},
	{
		term: 'Partido.',
		definition:
			'Enfrentamiento entre dos equipos, dentro del marco de una Competencia o como partido amistoso.',
	},
	{
		term: 'Planillero (árbitro planillero) de vóleibol.',
		definition:
			'Es el encargado de llenar la planilla del partido. Desempeña sus funciones sentado ante la mesa del anotador, en el lado opuesto y de frente al primer árbitro. Se registra en el sistema como árbitro. Puede gestionar un partido.',
	},
	{
		term: 'Play-off.',
		definition:
			'Sistema de enfrentamientos en el que todos los equipos competidores van enfrentándose por parejas, pero en este caso los perdedores de los enfrentamientos van abandonando la competencia y  los ganadores pasan de fase y se enfrentan a otro que haya ganado su llave o tenga “bye”. El número de participantes se reduce en cada nueva ronda de partidos, llegando a un partido final (la Final) entre los dos últimos equipos no eliminados. Cada cruce puede ser a partido único o al mejor de un número impar de partidos. Si el deporte admite empates, en el último partido del cruce se debe desempatar de alguna forma (pateando penales, por ejemplo, en caso del fútbol)',
	},
	{
		term: 'Registro.',
		definition:
			'Inscripción de personas en el sistema. Es el requisito necesario para convertirse en usuario. Se puede hacer directamente por medio del formulario del sistema o a través de un sistema externo admitido por el sistema (Google, por ejemplo)',
	},
	{
		term: 'Rol.',
		definition:
			'Un rol de usuario es un conjunto de privilegios que se asigna a un usuario para permitir que realice determinadas tareas dentro del sistema. Al registrarse en el sistema, un usuario nuevo debe seleccionar un rol entre los siguientes: Jugador; Entrenador; Árbitro; Colaborador. De acuerdo al rol se definen los privilegios y limitaciones del usuario dentro del sistema.',
	},
	{
		term: 'Torneo.',
		definition: 'Es un conjunto de competencias.',
	},
	{
		term: 'Tabla de posiciones.',
		definition:
			'Es donde se refleja el estado de la competencia. Dependiendo del deporte, tiene distintas columnas que representan los logros obtenidos y los partidos jugados.',
	},
	{
		term: 'Transiciones entre competencias.',
		definition:
			'En torneos con competencias relacionadas, al terminar una competencia se puede clasificar a otra/s, por ejemplo con ascensos, permanencias y descensos. El sistema ofrece esta funcionalidad automatizada indicando anticipadamente cómo serán las transiciones entre las competencias y otorgando la flexibilidad para armar torneos con cualquier formato formado por ligas y play-offs relacionadas entre sí.',
	},
	{
		term: 'Usuario.',
		definition:
			'Los usuarios son personas físicas que se registran en el sistema. Los usuarios tienen que tener uno y sólo uno de los siguientes tipos: Organizador de torneos, Jugador, Entrenador, Árbitro o Colaborador.',
	},
];
export default glossary;
