import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '1em',
					color: 'black',
					backgroundColor: 'white',
					boxShadow:
						'0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);',
				},
				arrow: {
					color: 'white',
				},
			},
		},
	},
});

export default theme;
