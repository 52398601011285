const rules = [
	{
		term: 'Reglas de volleyball.',
		image: 'home/rules/fivb-2021-2024.png',
		definition: 'Reglamento oficial de la FIVB del 2021 a 2024',
		link: 'https://www.fvbpa.com/wp-content/uploads/FIVB-Volleyball_Rules2021_2024-EN.en_.es_.pdf',
		download:
			'https://www.fivb.com/-/media/2022/coorporate/volleyball/rules 2021-2024/fivb-volleyball_rules2021_2024-sp-v01b.pdf?la=en&hash=8F7F56AD29DD6867299E8C42E8BCB551',
	},
	{
		term: 'Reglas de baby fútbol.',
		image: 'home/rules/onfi-2021.png',
		definition: 'Reglamento oficial de ONFI de 2021',
		link: 'https://onfi.org.uy/wp-content/uploads/2021/08/Reglas-de-juego-2021-.pdf',
	},
];
export default rules;
