const mTexts = {
	makePayment: 'Realizar pago',
	male: 'Masculino',
	main: 'Inicio',
	mainPage: 'Página de inicio',
	makeNotificationRead: 'Marcar notificación leída',
	makeNotificationUnread: 'Marcar notificación como no leída',
	manageInCourseMatch: 'Gestionar partido',
	manageNotStartedMatch: 'Gestionar partido',
	manageNotPublishedMatch: 'Gestionar partido',
	manageFinishedMatch: 'Gestionar partido',
	managePendingMatch: 'Gestionar partido',
	manageTournamentManagers: 'Gestionar organizadores de torneos',
	manager: 'Dirigente',
	managerInvitedYouToTeam: (managerName: string, teamName: string) =>
		`${managerName} te invitó a unirte al equipo ${teamName}. Podés ver el equipo en `,
	manageStage: 'Gestionar competencia',
	markAllAsRead: 'Marcar todas como leídas',
	markAllAsUnread: 'Marcar todas como no leídas',
	match: 'Partido',
	matches: 'Partidos',
	matchesAmount: 'Cantidad de partidos',
	matchesFinalBracket: 'Partidos en llave final y 3er puesto',
	matchesFor: 'Partidos para',
	matchesNoFinalBracket: 'Partidos en llave no final',
	matchesNotDefinedYet: 'Los cruces aún no están definidos.',
	matchEvents: 'Eventos del partido',
	matchFormKebbabCase: 'formulario-partido',
	matchIdentifier: 'Id',
	matchInfo: 'Información del partido',
	matchNotFound: 'No se encontró el partido.',
	matchReferees: 'Árbitros del partido',
	matchSaved: '¡El partido ha sido modificado correctamente!',
	matchStatusCompletingManually: 'Ingresando resultados',
	matchStatusFinished: 'Finalizado',
	matchStatusInCourse: 'En curso',
	matchStatusNotPublished: 'No publicado',
	matchStatusPending: 'Pendiente',
	matchStatusPublished: 'No iniciado',
	matchWasPublished:
		'¡El partido ha sido publicado! Ahora podés verlo y gestionarlo en directo cuando llegue el momento de empezarlo',
	matchWillBeStarted: 'Se va a iniciar el partido',
	maxParticipants: 'Máxima cantidad de participantes',
	maxImageSize: (maxMBSize: number) => `Tamaño máximo ${maxMBSize} MB`,
	maxImageSizeExceeded: 'La imagen excede el tamaño máximo permitido',
	medicalRecordExpirationDate: 'Vencimiento de ficha médica',
	medicalRecordInitials: 'FM',
	messageToAsk: 'Opcional: Ingresá un mensaje a enviar junto con la solicitud',
	messageToInvite:
		'Opcional: Ingresá un mensaje a enviar junto con la invitación',
	memberAdded: '¡El miembro ha sido agregado correctamente!',
	memberAlreadyAdded: '¡El miembro ya estaba agregado!',
	members: 'Integrantes',
	minParticipants: 'Mínima cantidad de participantes',
	minutes: 'min',
	minutesExtended: 'Minutos',
	mixed: 'mixto',
	modifyScore: 'Modificar Puntaje',
	moreImagesThanMaximum: (maxImages: number) =>
		`El máximo de imágenes es ${maxImages}.`,
	moreParticipantsThan: (maxParticipants: number) =>
		`Demasiados participantes, como máximo la competencia admite ${maxParticipants}.`,
	moreVisualsThanMaximum: (maxVisuals: number) =>
		`Sólo se puede subir ${
			maxVisuals === 1 ? 'una' : maxVisuals
		} imagen por vez`,
	moreThanOneGoalKeeper: 'Hay más de un arquero.',
	moveUp: 'Subir',
	moveDown: 'Bajar',
	mainImageRequired: 'La imagen es requerida',
	mainReferee: 'Principal',
	mainRefereeNotInvited: 'El árbitro principal no ha sido invitado.',
	mainRefereeNotAccepted:
		'El árbitro principal no ha aceptado la participación.',
	mainRefereeRejected: 'El árbitro principal ha rechazado la participación.',
	multiTournament: 'MultiTorneos',
	mustBeLoggedIn: 'Debés estar logueado para acceder a esta página.',
	mustBeLoggedInToDoAction:
		'No se pudo realizar la acción pedida, ¿ya ingresaste al sistema?',
	mustBeManagerToCreateFriendly:
		'Tenés que ser dirigente de algún equipo para crear amistosos.',
	mustCompleteAdjustmentInfo:
		'Debés seleccionar equipo e ingresar cantidad de puntos a ajustar para seguir.',
	mustSelectLosingTeam: 'Se debe seleccionar el equipo perdedor',
	myMatches: 'Mis partidos',
	myProfile: 'Mi perfil',
	myTeams: 'Mis equipos',
	myTournaments: 'Mis torneos',
};

// eslint-disable-next-line import/prefer-default-export
export { mTexts };
