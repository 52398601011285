import { constants } from '@app';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback } from 'react';

type DebouncedInputProps = {
	defaultValue: string;
	updateText: (newText: string) => void;
	placeholder: string;
	className?: string;
	editable?: boolean;
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
	min?: number;
};

const debounced = debounce(
	(
		event: React.ChangeEvent<HTMLInputElement>,
		updateText: DebouncedInputProps['updateText']
	) => {
		updateText(event.target.value);
	},
	constants.DEBOUNCED_INPUT_TIMEOUT
);

const DebouncedInput = ({
	defaultValue,
	updateText,
	placeholder = '',
	className,
	editable = true,
	inputRef,
	min,
}: DebouncedInputProps) => {
	const handleTextChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			debounced(e, updateText);
		},
		[updateText]
	);

	return (
		<input
			defaultValue={defaultValue}
			readOnly={!editable}
			onChange={handleTextChange}
			placeholder={placeholder}
			className={className}
			type="text"
			ref={inputRef}
			min={min}
		/>
	);
};

export default DebouncedInput;
