import { texts } from '@app';
import { ReactNode, useState } from 'react';

type BigSelectOption<T extends number | string> = {
	text: string;
	value: T;
	content: ReactNode;
};

type BigSelectProps<T extends number | string> = {
	options: BigSelectOption<T>[];
	selectedText: string;
	setSelectedText: (value: T) => void;
	className?: string;
	noElementsText?: string;
	searchText?: string;
	withInput?: boolean;
};

const BigSelect = <T extends number | string>({
	options,
	selectedText,
	setSelectedText,
	className = '',
	noElementsText = texts.notDefined,
	withInput = false,
	searchText,
}: BigSelectProps<T>) => {
	const [isOpen, setIsOpen] = useState(false);
	const randomId = Math.random() * 10000;
	const [filter, setFilter] = useState('');

	const filteredOptions =
		withInput && filter
			? options.filter(
					(option) =>
						option.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1
			  )
			: options;

	return (
		<div className={`relative ${className}`}>
			{withInput ? (
				<input
					type="text"
					placeholder={searchText}
					className={className}
					onChange={(event) => {
						setFilter(event.target.value);
					}}
					onFocus={() => {
						setIsOpen(true);
					}}
					onBlur={() => {
						setTimeout(() => {
							setIsOpen(false);
						}, 100);
					}}
				/>
			) : (
				<div className="h-8 md:h-12 bg-gray-200 flex border border-gray-200 rounded-r-lg items-center overflow-hidden">
					<button
						type="button"
						className="px-1 md:px-4 appearance-none outline-none text-gray-800 w-full text-xs md:text-lg"
						onFocus={() => setIsOpen(!isOpen)}
						onBlur={() => setIsOpen(false)}
						id={`show_more-${randomId}`}>
						{selectedText}
					</button>
				</div>
			)}
			<div
				className={`absolute rounded shadow bgWhite overflow-hidden z-20 ${
					isOpen ? '' : 'hidden'
				} peer-checked:flex flex-col w-full mt-1 border border-gray-200`}>
				{filteredOptions.length > 0 ? (
					filteredOptions.map((option, index) => (
						<button
							type="button"
							className="bg-gray-200 hover:bg-gray-300 cursor-pointer block py-2 border-transparent borderL-4 w-full"
							key={index}
							onMouseDown={() => {
								setSelectedText(option.value);
							}}>
							{option.content}
						</button>
					))
				) : (
					<div className="bg-gray-200 py-2 border-transparent flex justify-center">
						{noElementsText}
					</div>
				)}
			</div>
		</div>
	);
};

export default BigSelect;
