import { texts } from '@app';
import Modal from '@atoms/Modal';
import { useMemo, useState } from 'react';

type ExpandableVisualModalProps = {
	visualUri: string;
	className?: string;
	containerClassName?: string;
};

const ExpandableVisualModal = ({
	visualUri,
	className,
	containerClassName,
}: ExpandableVisualModalProps) => {
	const [isShowingModal, setIsShowingModal] = useState(false);

	const renderModal = useMemo(
		() =>
			isShowingModal && (
				<Modal
					startOpened
					cancelButtonText={texts.close}
					cancelButtonAction={() => setIsShowingModal(false)}
					content={
						<img
							alt={texts.visual}
							src={visualUri}
							className="w-full mx-auto"
						/>
					}
					okWithEnterKeyPressing
				/>
			),
		[visualUri, isShowingModal]
	);

	return (
		<>
			<button
				type="button"
				onClick={() => {
					setIsShowingModal(true);
				}}
				className={containerClassName}>
				<img alt={texts.visual} src={visualUri} className={className} />
			</button>
			{renderModal}
		</>
	);
};

export default ExpandableVisualModal;
