import { texts } from '@app';
import {
	login,
	setNotifications,
	setProfilePhoto,
} from '@reducers/LoggedUserReducer';
import MediaService from '@services/MediaService';
import UserService from '@services/UserService';
import {
	EnumEntityTypes,
	EnumFileTypes,
	EnumSports,
	EnumUserTypes,
	FileImage,
	Image,
	LoggedUser,
	Notification,
	Result,
	ResultWithImages,
	User,
	UserIsRole,
} from '@types';
import { Dispatch } from 'redux';

import { convertFromBackend } from './ConvertActions';

type ResultWithUser = Result & { user?: User };
type ResultWithUserId = Result & { userId?: number };
type ResultWithNotifications = Result & { notifications?: Notification[] };
type CompletableUser = User & {
	completed: boolean;

	accessTokenValidUntil: string;
	accessToken: string;
};
type ResultWithCompletableUser = Result & {
	user?: CompletableUser;
};

export const setProfileImageAction =
	(
		userId: number,
		image: Image | null,
		updateImage: boolean,
		callback: (result: ResultWithImages) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithImages;

		try {
			const response = await UserService.setProfilePhotoService(
				userId,
				image?.id ?? undefined
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				images: response.data.files as Image[],
			};
			if (result.success && updateImage) {
				dispatch(
					setProfilePhoto({
						profilePhotoUri: image?.uri ?? undefined,
						profilePhotoId: image?.id ?? undefined,
					})
				);
			}
		} catch (error) {
			console.error(error); // eslint-disable-line no-console
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateProfileFieldAction =
	(
		userId: number,
		fieldName: string,
		newValue: string | number | null,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.updateProfileFieldService(
				userId,
				fieldName,
				newValue
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const deleteNoUserAction =
	(userId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.deleteNoUserService(userId);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const convertToUserAction =
	(noUserId: number, email: string, callback: (result: Result) => void) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.convertToUserService(noUserId, email);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const uploadUserImagesAction =
	(
		images: Required<FileImage>[] | null,
		userId: number,
		callback: (result: ResultWithImages) => void
	) =>
	async () => {
		let result: ResultWithImages;

		try {
			const response = await MediaService.uploadUserMediaService(
				images,
				EnumFileTypes.IMAGE,
				userId
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				images: response.data.files,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const deletePhotoAction =
	(image: Image, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.deletePhotoService(image.id);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getProfilePartAction =
	(
		userId: number,
		profilePartToLoad: string,
		callback: (result: ResultWithUser) => void
	) =>
	async () => {
		let result: ResultWithUser;

		try {
			const response = await UserService.getProfilePartService(
				userId,
				profilePartToLoad
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: convertFromBackend(response.data.user) as User,
			};

			if (result.user) {
				result.user = {
					...result.user,
					isCoach: result.user.isCoach,
					isPlayer: result.user.isPlayer,
					isAdmin: result.user.isAdmin,
					isTournamentManager: result.user.isTournamentManager,
					isReferee: result.user.isReferee,
					isActive: result.user.isActive,
				};
			}
		} catch (error) {
			console.error(error); // eslint-disable-line no-console
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const createInactiveUserAction =
	(
		email: string,
		userType: EnumUserTypes,
		sport: EnumSports,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.createInactiveUserService(
				email,
				userType,
				sport
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getInactiveUserInfoAction =
	(token: string, callback: (result: ResultWithCompletableUser) => void) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithCompletableUser;

		try {
			const response = await UserService.getInactiveUserInfoService(token);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: convertFromBackend(response.data.user) as CompletableUser,
			};

			if (result.user?.completed) {
				// If the register is completed, then make the login automatically.
				localStorage.setItem('validUntil', result.user.accessTokenValidUntil);
				localStorage.setItem('userId', result.user.id.toString());
				localStorage.setItem('accessToken', result.user.accessToken);

				dispatch(login({ ...result.user, notifications: [] }));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const completeUserRegistrationAction =
	(
		userId: number,
		nickname: string,
		token: string,
		password: string,
		callback: (result: ResultWithCompletableUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithCompletableUser;

		try {
			const response = await UserService.completeUserRegistrationService(
				userId,
				nickname,
				token,
				password
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: convertFromBackend(response.data.user) as CompletableUser,
			};

			if (result.user?.completed) {
				// If the register is completed, then make the login automatically.
				localStorage.setItem('validUntil', result.user.accessTokenValidUntil);
				localStorage.setItem('userId', result.user.id.toString());
				localStorage.setItem('accessToken', result.user.accessToken);

				dispatch(login({ ...result.user, notifications: [] }));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const completeUserConvertionAction =
	(
		userId: number,
		nickname: string,
		token: string,
		password: string,
		callback: (result: ResultWithCompletableUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithCompletableUser;

		try {
			const response = await UserService.completeUserConvertionService(
				userId,
				nickname,
				token,
				password
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: convertFromBackend(response.data.user) as CompletableUser,
			};

			if (result.user?.completed) {
				// If the register is completed, then make the login automatically.
				localStorage.setItem('validUntil', result.user.accessTokenValidUntil);
				localStorage.setItem('userId', result.user.id.toString());
				localStorage.setItem('accessToken', result.user.accessToken);

				dispatch(
					login({
						...result.user,
						notifications: [],
					})
				);
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updatePasswordAction =
	(password: string, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.updatePasswordService(password);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const restorePasswordAction =
	(email: string, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.restorePasswordService(email);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const reSendEmailValidationAction =
	(email: string, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.reSendEmailValidationService(email);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const completePasswordRestoringAction =
	(
		password: string,
		email: string,
		token: string,
		callback: (result: ResultWithCompletableUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithCompletableUser;

		try {
			const response = await UserService.completePasswordRestoringService(
				password,
				email,
				token
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: convertFromBackend(response.data.user) as CompletableUser,
			};

			if (result.user) {
				localStorage.setItem('validUntil', result.user.accessTokenValidUntil);
				localStorage.setItem('userId', result.user.id.toString());
				localStorage.setItem('accessToken', result.user.accessToken);

				dispatch(login({ ...result.user, notifications: [] }));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getNotificationsAction =
	(callback: (result: ResultWithNotifications) => void) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithNotifications;

		try {
			const response = await UserService.getNotificationsService();

			const { notifications } = convertFromBackend(response.data) as {
				notifications: Notification[];
			};

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				notifications,
			};

			if (result.success) {
				dispatch(setNotifications(notifications));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const changeNotificationReadPropertyAction =
	(
		notification: Notification,
		markAsRead: boolean,
		notifications: Notification[],
		dontUpdateDinamycally: boolean,
		callback: (result: Result) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: Result;

		const previous = notifications;
		const newNotifications = notifications;

		if (dontUpdateDinamycally === false) {
			dispatch(
				setNotifications(
					newNotifications.map((oldNotification) =>
						oldNotification.id !== notification.id
							? oldNotification
							: { ...notification, wasRead: !notification.wasRead }
					)
				)
			);
		}

		try {
			const response = await UserService.changeNotificationReadPropertyService(
				notification.id,
				markAsRead
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};

			if (!result.success) {
				dispatch(setNotifications(previous));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}

		if (!result.success) {
			dispatch(setNotifications(previous));
		}
		callback(result);
	};

export const changeNotificationsReadPropertyAction =
	(
		markAsRead: boolean,
		notifications: Notification[],
		callback: (result: Result) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: Result;

		const previous = notifications;
		const newNotifications = notifications;

		dispatch(
			setNotifications(
				newNotifications.map((oldNotification) => ({
					...oldNotification,
					wasRead: markAsRead,
				}))
			)
		);

		try {
			const response = await UserService.changeNotificationsReadPropertyService(
				notifications.map((notification) => notification.id),
				markAsRead
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}

		if (!result.success) {
			dispatch(setNotifications(previous));
		}
		callback(result);
	};

export const answerRefereeParticipationAction =
	(
		matchId: number,
		notificationId: number | null,
		isConfirmation: boolean,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.answerRefereeParticipationService(
				matchId,
				isConfirmation,
				notificationId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const answerMemberParticipationAction =
	(
		matchId: number,
		notificationId: number | null,
		isConfirmation: boolean,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.answerMemberParticipationService(
				matchId,
				isConfirmation,
				notificationId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const answerFriendlyInvitationAction =
	(
		matchId: number,
		notificationId: number | null,
		isConfirmation: boolean,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.answerFriendlyInvitationService(
				matchId,
				isConfirmation,
				notificationId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const followEntityAction =
	(
		entityId: number,
		entityType: EnumEntityTypes,
		follow: boolean,
		secondaryEntityId: number | undefined,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await UserService.followEntityService(
				entityId,
				entityType,
				follow,
				secondaryEntityId
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const followEntityAsSpectatorAction =
	(
		email: string,
		entityId: number,
		entityType: EnumEntityTypes,
		follow: boolean,
		secondEntityId: number | undefined,
		callback: (result: ResultWithUserId) => void
	) =>
	async () => {
		let result: ResultWithUserId;

		try {
			const response = await UserService.followEntityAsSpectatorService(
				email,
				entityId,
				entityType,
				follow,
				secondEntityId
			);

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};

			if (result.success) {
				result.userId = (
					convertFromBackend(response.data) as { userId?: number }
				).userId;
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getUserType = (user: User) =>
	user.isTournamentManager
		? EnumUserTypes.TOURNAMENT_MANAGER
		: user.isCoach
		? EnumUserTypes.COACH
		: user.isCollaborator
		? EnumUserTypes.COLLABORATOR
		: user.isReferee
		? EnumUserTypes.REFEREE
		: user.isAdmin
		? EnumUserTypes.ADMIN
		: EnumUserTypes.PLAYER;

export const canSeeUserImage = (
	user: UserIsRole & Pick<LoggedUser, 'sport' | 'id'>,
	loggedUser: UserIsRole & Pick<User, 'sport' | 'belongingTeams'>
) => {
	if (!user.isPlayer || user.sport !== EnumSports.babyFootball) return true;

	if (
		loggedUser.isAdmin ||
		(user.sport && user.sport !== EnumSports.babyFootball)
	)
		return true;

	if (loggedUser.belongingTeams) {
		return loggedUser.belongingTeams.some((team) =>
			team.players?.some((player) => player.id === user.id)
		);
	}

	return false;
};

export const canUserFollowEntity = (
	user: Pick<User, 'isAdmin' | 'isTournamentManager'> &
		Partial<Pick<User, 'id'>>,
	entityId: number,
	entityType: EnumEntityTypes
) =>
	user.id ||
	// Spectators can follow teams and matches
	entityType === EnumEntityTypes.team ||
	entityType === EnumEntityTypes.match;

export const signOutMTAction =
	(callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await UserService.signOutMTService();

			result = {
				success: parseInt(response.data.code) === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: false,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};
