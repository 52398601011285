import { getNewsAction } from '@actions/NewsActions';
import { texts } from '@app';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { setIsLoading, setIsNotLoading } from '@reducers/GenericReducer';
import { News } from '@types';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';

type NewsCardProps = {
	height?: string;
	newsItem: News;
	setSelectedNewsItem: (news: News) => void;
	setShowNewsModal: React.Dispatch<React.SetStateAction<boolean>>;
	width?: string;
};

const NewsCard = ({
	newsItem,
	setSelectedNewsItem,
	setShowNewsModal,
	width = '280px !important',
	height = '398px !important',
}: NewsCardProps) => {
	const {
		backColor,
		descColor,
		description,
		headerColor,
		id,
		title,
		titleColor = '000000',
		uri,
	} = newsItem;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const newsDate = newsItem.date
		? dayjs(newsItem.date).format('DD/MM/YYYY')
		: undefined;

	const onNewsClick = useCallback(() => {
		if (!id) {
			toast.error(texts.errorGeneric());
			return;
		}

		setShowNewsModal(true);
		dispatch(setIsLoading());
		dispatch(
			getNewsAction(id, (result) => {
				dispatch(setIsNotLoading());
				if (!result.success || !result.news) {
					toast.error(result.message ?? texts.errorGeneric());
					navigate('/');
					return;
				}

				const {
					texts: textsArray,
					colors,
					files,
					title: innerTitle,
					titleColor: innerTitleColor,
					date,
				} = result.news;
				const subtitleColor =
					textsArray?.find((text) => text.type === 'subtitle')?.color ??
					'000000';
				const subtitle =
					textsArray?.find((text) => text.type === 'subtitle')?.text ?? '';
				const mainImageBackgroundColor =
					colors?.find((color) => color.type === 'header')?.color ?? '000000';
				const backgroundColor =
					colors?.find((color) => color.type === 'background')?.color ??
					'000000';

				const mainImage = files?.filter((file) => file.isMain)[0];

				const secondaryImages = files?.filter((file) => !file?.isMain);

				const secondaryImagesBackgroundColor =
					colors?.find((color) => color.type === 'footer')?.color ?? '000000';

				const innerDescription =
					textsArray?.find((text) => text.type === 'description')?.text ?? '';

				const descriptionColor =
					textsArray?.find((text) => text.type === 'description')?.color ??
					'000000';

				const finalText =
					textsArray?.find((text) => text.type === 'final')?.text ?? '';

				const finalTextColor =
					textsArray?.find((text) => text.type === 'final')?.color ?? '000000';

				const mainImageForModal = mainImage;

				setSelectedNewsItem({
					...result.news,
					mainImage: mainImageForModal,
					secondaryImages,
					date,
					title: innerTitle,
					titleColor:
						innerTitleColor &&
						innerTitleColor.length > 0 &&
						innerTitleColor.charAt(0) === '#'
							? innerTitleColor
							: `#${innerTitleColor}`,
					subtitle,
					subtitleColor:
						subtitleColor?.length > 0 && subtitleColor.charAt(0) === '#'
							? subtitleColor
							: `#${subtitleColor}`,
					description: innerDescription,
					descriptionColor:
						descriptionColor?.length > 0 && descriptionColor.charAt(0) === '#'
							? descriptionColor
							: `#${descriptionColor}`,
					finalText,
					finalTextColor:
						finalTextColor?.length > 0 && finalTextColor.charAt(0) === '#'
							? finalTextColor
							: `#${finalTextColor}`,
					mainImageBackgroundColor:
						mainImageBackgroundColor?.length > 0 &&
						mainImageBackgroundColor.charAt(0) === '#'
							? mainImageBackgroundColor
							: `#${mainImageBackgroundColor}`,
					backgroundColor:
						backgroundColor?.length > 0 && backgroundColor.charAt(0) === '#'
							? backgroundColor
							: `#${backgroundColor}`,
					secondaryImagesBackgroundColor:
						secondaryImagesBackgroundColor?.length > 0 &&
						secondaryImagesBackgroundColor.charAt(0) === '#'
							? secondaryImagesBackgroundColor
							: `#${secondaryImagesBackgroundColor}`,
				});
				setShowNewsModal(true);
			}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);
	}, [id, dispatch, navigate, setSelectedNewsItem, setShowNewsModal]);

	return (
		<Card sx={{ width, height, cursor: 'pointer' }}>
			<CardActionArea
				sx={{
					height: '100%',
				}}
				onClick={onNewsClick}>
				<CardMedia
					sx={{
						bgcolor:
							headerColor &&
							headerColor.length > 0 &&
							headerColor.charAt(0) === '#'
								? headerColor
								: `#${headerColor}`,
						justifyContent: 'center',
						display: 'flex',
					}}>
					<img
						src={uri}
						alt={texts.newsImage}
						style={{ width: 'auto', height: '166px' }}
					/>
				</CardMedia>
				<CardContent
					sx={{
						bgcolor:
							backColor && backColor.length > 0 && backColor.charAt(0) === '#'
								? backColor
								: `#${backColor}`,
						height: '232px',
					}}>
					<Typography
						variant="body1"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							color: '#04859D',
							fontFamily: 'Roboto',
						}}
						component="div">
						{newsDate}
					</Typography>
					<Typography
						variant="h5"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							color:
								titleColor?.length > 0 && titleColor.charAt(0) === '#'
									? titleColor
									: `#${titleColor}`,
							fontFamily: 'Roboto Condensed',
						}}
						component="div">
						{title}
					</Typography>
					<Typography
						variant="body2"
						className="newsCard"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							color:
								descColor && descColor.length > 0 && descColor.charAt(0) === '#'
									? descColor
									: `#${descColor}`,
							fontFamily: 'Roboto',
						}}>
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default NewsCard;
