import { checkExpiredSession, loginAuth } from '@actions/AuthActions';
import { getNewsForUserAction } from '@actions/NewsActions';
import { texts, uris, useAppSelector } from '@app';
import useLoggedUser from '@hooks/useLoggedUser';
import QuickAccessInCourseMatchesIcon from '@organisms/QuickAccessInCourseMatchesIcon';
import QuickAccessWhatsAppIcon from '@organisms/QuickAccessWhatsAppIcon';
import { setIsLoading, setIsNotLoading } from '@reducers/GenericReducer';
import { isLoadingSelector } from '@selectors/GenericSelector';
import { News } from '@types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';

import MainBreadcrumb from './MainBreadcrumb';
import MainContent from './MainContent';

const MainFieldResponsiblePage = () => {
	document.title = texts.viewMainFieldResponsible;

	const { loggedUser } = useLoggedUser();
	const isLoading = useAppSelector(isLoadingSelector);
	const [news, setNews] = useState<News[]>([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		/**
		 * Checking the user logged values.
		 */
		const loginWithRedirect = `/${uris.login}?${uris.paramPageToRedirect}=${
			location.pathname + location.search.replace('&', '%26')
		}`;
		if (!loginAuth()) {
			toast.error(texts.mustBeLoggedIn);
			navigate(loginWithRedirect);
			return;
		}
		/**
		 * Checking the token validation to avoid
		 * users using an expired one.
		 */
		dispatch(
			checkExpiredSession((result) => {
				if (!result) {
					toast.error(texts.mustBeLoggedIn);
					navigate(loginWithRedirect);
				}
			}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);

		if (!loggedUser.isFieldResponsible) {
			toast.error(texts.notAllowed);
			navigate('/');
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (!loggedUser.id) return;

		dispatch(setIsLoading());
		dispatch(
			getNewsForUserAction(loggedUser.id, 10, 0, (result) => {
				dispatch(setIsNotLoading());
				if (!result.success || !result.news) {
					toast.error(result.message ?? texts.errorGeneric());
					navigate('/');
					return;
				}
				setNews(result.news);
			}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<div className="min-w-full select-none justify-center md:flex">
			<div className="min-h-screen flex-1">
				{!isLoading && (
					<MainBreadcrumb id={loggedUser.id} nickName={loggedUser.nickname} />
				)}
				<div className="w-full bg-body pb-1">
					<MainContent showNews news={news} />
				</div>
			</div>
			<QuickAccessWhatsAppIcon />
			<QuickAccessInCourseMatchesIcon />
		</div>
	);
};

export default MainFieldResponsiblePage;
