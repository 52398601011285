import { texts, uris } from '@app';
import Breadcrumb from '@atoms/Breadcrumb';
import { EnumBreadcrumbCategories } from '@types';

type MainBreadcrumbProps = {
	nickName?: string;
	id?: number;
};

const MainBreadcrumb = (props: MainBreadcrumbProps) => {
	const { nickName, id } = props;
	return (
		<Breadcrumb
			path={[
				{
					type: EnumBreadcrumbCategories.container,
					category: texts.user,
					text: `${nickName}`,
					uriOnClick: `/${uris.profile}?${uris.paramUserId}=${id}`,
				},
				{
					type: EnumBreadcrumbCategories.activity,
					text: texts.viewMainTournamentManager,
				},
			]}
		/>
	);
};

export default MainBreadcrumb;
