import { constraints, texts, uris } from '@app';
import BigSelect from '@atoms/BigSelect';
import DebouncedInput from '@atoms/DebouncedInput';
import { useNavigate } from 'react-router-dom';

type SearchBarProps = {
	searchValue: string;
	searchFilter: string;
	setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
	handleSearch: (newSearchValue: string) => void;
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
};

const SearchBar = ({
	searchValue,
	searchFilter,
	setSearchFilter,
	handleSearch,
	inputRef,
}: SearchBarProps) => {
	const navigate = useNavigate();

	return (
		<div className="flex flex-wrap content-around w-full justify-center">
			<div className="grid gap-0 grid-cols-5 content-around w-full text-xs     sm:grid-cols-3     md:text-lg md:w-full    lg:w-5/6">
				<div className="col-span-3 w-full px-2 h-8 bg-white shadow-sm rounded-l-lg text-slate-600     sm:col-span-2     md:h-12     lg:px-4">
					<DebouncedInput
						placeholder={texts.search}
						className="w-full h-full     focus:outline-none"
						defaultValue={searchValue}
						updateText={handleSearch}
						inputRef={inputRef}
					/>
				</div>
				<BigSelect<string>
					options={[
						texts.usersAndTeams,
						texts.players,
						texts.teams,
						texts.matches,
						texts.tournamentsAndStages,
						texts.fieldsAndPlaces,
					].map((text) => ({
						content: text,
						value: text,
						text,
					}))}
					selectedText={searchFilter}
					setSelectedText={(value) => {
						if (value === searchFilter || typeof value !== 'string') return;

						setSearchFilter(value);

						if (
							!searchValue ||
							searchValue.length < constraints.SEARCH_CHARACTERS_MIN
						)
							return;

						navigate(
							`/${uris.searchResults}?${uris.paramSearch}=${searchValue}&${uris.paramSearchFilter}=${value}`
						);
					}}
					className="col-span-2     sm:col-span-1"
				/>
			</div>
		</div>
	);
};

export default SearchBar;
