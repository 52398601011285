import { texts } from '@app';
import {
	CumulativeTransition,
	CumulativeTransitionForBackend,
	EnumCapabilities,
	EnumGameModes,
	EnumGenders,
	EnumMatchStatuses,
	EnumParticipationStatuses,
	EnumSports,
	Field,
	LoggedUser,
	Match,
	Place,
	SimplifiedMatch,
	Stage,
	StagePosition,
	TeamMember,
	Transition,
	TransitionForBackend,
	User,
} from '@types';

import { isFootball } from './SportActions';

export const hasCapability = (
	userCapabilities: EnumCapabilities[] | undefined,
	capabilityToCheck: EnumCapabilities
) => {
	if (!userCapabilities) return false;

	return (
		userCapabilities &&
		userCapabilities.some((capability) => capability === capabilityToCheck)
	);
};

export const canUserSeeNewsButton = (
	userId?: number,
	capabilities?: EnumCapabilities[],
	entityManagerIds?: number[]
) =>
	hasCapability(capabilities, EnumCapabilities.CAPABILITY_CREATE_NEWS) ||
	entityManagerIds?.some((managerId) => managerId === userId);

export const canUserSeeFoulsButtons = (
	userId?: number,
	capabilities?: number[],
	entityManagerIds?: number[]
) =>
	(hasCapability(capabilities, EnumCapabilities.CAPABILITY_EDIT_TOURNAMENT) ||
		entityManagerIds?.some((managerId) => managerId === userId)) ??
	false;

export const canUserSeeMatch = (
	userId?: number,
	matchStatus?: EnumMatchStatuses
) => {
	if (!matchStatus) return false;

	/**
	 * Letting all the users see the match before
	 * starting to view the already set values
	 * and be able to follow the match and
	 * receive news in it.
	 */
	return true;
	// return (
	//   match.status !== EnumMatchStatuses.NOT_STARTED ||
	//   (user.capabilities &&
	//     (user.capabilities.indexOf(EnumCapabilities.CAPABILITY_VIEW_MATCH) !==
	//       -1 ||
	//       user.capabilities.indexOf(EnumCapabilities.CAPABILITY_EDIT_MATCH) !==
	//         -1)) ||
	//   _userIsRefereeInMatch(user, match) ||
	//   _userIsManagerOfMatch(user, match)
	// );
};

export const capitalizeFirst = (string: string) =>
	`${string.charAt(0).toUpperCase()}${string.substring(1).toLowerCase()}`;

export const capitalizeFirstInWords = (string: string) =>
	string
		.split(' ')
		.map((word) => capitalizeFirst(word))
		.join(' ');

export const canRefereeAnswerInMatch = (
	userId: number,
	match: Omit<SimplifiedMatch, 'round' | 'status'>
) => {
	if (!match || !userId) return false;

	return (
		match.referees &&
		match.referees.length > 0 &&
		match.referees.some(
			(referee) =>
				referee.id === userId &&
				referee.participationStatus === EnumParticipationStatuses.pending
		)
	);
};

export const isIndependentMatch = (gameMode?: EnumGameModes) =>
	gameMode !== EnumGameModes.league && gameMode !== EnumGameModes.playoff;

export const userIsManagerOfStage = (
	userId: number | undefined,
	stage: Pick<Stage, 'managerIds'>
) => userId && stage.managerIds.some((managerId) => managerId === userId);

export const userIsManagerOfMatch = (userId?: number, stage?: Stage) =>
	stage && userId && userIsManagerOfStage(userId, stage);

export const userIsRefereeInMatch = (userId?: number, refereeIds?: number[]) =>
	userId && refereeIds?.some((refereeId) => refereeId === userId);

type MemberForCheck = Pick<TeamMember, 'isTeamManager' | 'id'>;

export const matchCanBeEditedByUser = (
	user: User | LoggedUser,
	matchInfo: Omit<Match, 'local' | 'visitor'> & {
		local: { members?: MemberForCheck[] };
		visitor: { members?: MemberForCheck[] };
	}
) => {
	const refereeIds = matchInfo.referees?.map((referee) => referee.id);
	return (
		(user.capabilities &&
			(user.capabilities?.indexOf(EnumCapabilities.CAPABILITY_EDIT_MATCH) !==
				-1 ||
				userIsManagerOfMatch(user.id, matchInfo.stage) ||
				userIsRefereeInMatch(user.id, refereeIds) ||
				(matchInfo.gameMode &&
					isIndependentMatch(matchInfo.gameMode) &&
					(matchInfo.local?.members?.some(
						(member) => member.isTeamManager && member.id === user.id
					) ||
						matchInfo.visitor?.members?.some(
							(member) => member.isTeamManager && member.id === user.id
						))))) ??
		false
	);
};

export const getMatchStatusText = (status: EnumMatchStatuses) =>
	status === EnumMatchStatuses.NOT_STARTED
		? texts.matchStatusNotPublished
		: status === EnumMatchStatuses.PUBLISHED
		? texts.matchStatusPublished
		: status === EnumMatchStatuses.IN_COURSE
		? texts.matchStatusInCourse
		: status === EnumMatchStatuses.PENDING
		? texts.matchStatusPending
		: status === EnumMatchStatuses.COMPLETING_MANUALLY
		? texts.matchStatusCompletingManually
		: texts.matchStatusFinished;

export const getMatchStatusTextClass = (status: EnumMatchStatuses) =>
	status === EnumMatchStatuses.NOT_STARTED
		? 'text-black my-4 border-2 p-2 border-black'
		: status === EnumMatchStatuses.PUBLISHED ||
		  status === EnumMatchStatuses.COMPLETING_MANUALLY
		? 'text-blue-900 my-4 border-2 p-2 border-blue-900'
		: status === EnumMatchStatuses.IN_COURSE
		? 'text-transparent'
		: status === EnumMatchStatuses.PENDING
		? 'text-rose-900 my-4 border-2 p-2 border-rose-900'
		: 'text-red-600 my-4 border-2 p-2 border-red-600';

export const getMatchResumeStatusBgColorClass = (status: EnumMatchStatuses) =>
	status === EnumMatchStatuses.NOT_STARTED
		? 'text-black'
		: status === EnumMatchStatuses.PUBLISHED ||
		  status === EnumMatchStatuses.COMPLETING_MANUALLY
		? 'text-blue-900'
		: status === EnumMatchStatuses.IN_COURSE
		? 'text-green-500'
		: status === EnumMatchStatuses.PENDING
		? 'text-rose-900'
		: 'text-red-600';

export const getColorForParticipationStatus = (
	participationStatus: EnumParticipationStatuses
) =>
	participationStatus === EnumParticipationStatuses.accepted
		? 'color-user-accepted'
		: participationStatus === EnumParticipationStatuses.rejected
		? 'color-user-rejected'
		: 'color-user-invited';

export const canMatchBeStarted = (
	localIsNoGame?: boolean,
	visitorIsNoGame?: boolean
) => {
	const can = !(visitorIsNoGame || localIsNoGame);
	return { can, errorText: can ? '' : texts.errorWithoutRival };
};

export const canMatchBePublished = (
	localIsNoGame?: boolean,
	visitorIsNoGame?: boolean,
	startDatetime?: string
) => {
	const canStartResult = canMatchBeStarted(localIsNoGame, visitorIsNoGame);

	let errorFound = !canStartResult.can;
	let { errorText } = canStartResult;

	if (startDatetime && new Date(startDatetime) < new Date()) {
		errorText += ` ${texts.errorStartDatetimeBeforeThanNow}`;
		errorFound = true;
	}

	return { can: !errorFound, errorText };
};

export const isValidDate = (input?: Date) =>
	input &&
	input instanceof Date &&
	!Number.isNaN(input) &&
	Number.isFinite(+input) &&
	input.getFullYear() > 1970 &&
	input.getFullYear() < 2038; // While using TIMESTAMP the max year is 2037.

export const isInProduction = () =>
	process.env.REACT_APP_API_URL ===
	'https://multitorneos.panaweb.uy/backend/api';

export const isInTesting = () =>
	process.env.REACT_APP_API_URL === 'https://mt-testing.panaweb.uy/backend/api';

export const isInLocalhost = () =>
	process.env.REACT_APP_URL?.startsWith('http://localhost');

export const integrationWithFacebookEnabled = () => !isInProduction();
export const integrationWithGoogleEnabled = () => true;

export const isFieldOwner = (userId: number, field: Field) =>
	field.userOwners?.some((owner) => owner.id === userId);

export const isPlaceOwner = (userId: number, place: Place) =>
	place?.fields?.every((field) => isFieldOwner(userId, field));

export const getStagePositionsSortedByStandings: (
	stage: Stage
) => StagePosition[] = (stage) =>
	stage.standings?.length > 0
		? stage.standings.map((standing, index) => {
				const position = stage.positions.find(
					(innerPosition) =>
						innerPosition.team &&
						innerPosition.team?.id === (standing.team?.id ?? standing.team?.id)
				) as StagePosition;

				/**
				 * The idea with index adding is that it's used to define
				 * the graphical order keeping the position number
				 * to send the position to the backend. The backend
				 * will not know anything about position indexes.
				 */
				return {
					...position,
					team: position.team,
					index,
				};
		  })
		: stage.positions.map((position, index) => ({ ...position, index }));

export const adaptTransitionsForFrontend = (transitions: Transition[]) =>
	transitions?.map((transition) => ({
		...transition,
		originPositionIndex: transition.originPositionNumber - 1,
		destinationPositionIndex: transition.destinationPositionNumber - 1,
	}));

export const adaptCumulativeTransitionsForFrontend = (
	cumulativeTransitions: CumulativeTransition[]
) =>
	cumulativeTransitions?.map((cumulativeTransition) => ({
		...cumulativeTransition,
		originPositionIndex: cumulativeTransition.originPositionNumber - 1,
		destinationPositionIndex:
			cumulativeTransition.destinationPositionNumber - 1,
	}));

export const adaptTransitionsForBackend: (
	transitions: Transition[]
) => TransitionForBackend[] = (transitions) =>
	transitions.map((transition) =>
		transition.originPositionNumber && transition.destinationPositionNumber
			? {
					...transition,
					originPositionIndex: undefined,
					destinationPositionIndex: undefined,
			  }
			: {
					...transition,
					originPositionNumber: Number(transition.originPositionIndex) + 1,
					destinationPositionNumber:
						Number(transition.destinationPositionIndex) + 1,
					originPositionIndex: undefined,
					destinationPositionIndex: undefined,
			  }
	);

export const adaptCumulativeTransitionsForBackend: (
	transitions: CumulativeTransition[]
) => CumulativeTransitionForBackend[] = (transitions) =>
	transitions.map((transition) =>
		transition.originPositionNumber && transition.destinationPositionNumber
			? {
					...transition,
					originPositionIndex: undefined,
					destinationPositionIndex: undefined,
			  }
			: {
					...transition,
					originPositionNumber: Number(transition.originPositionIndex) + 1,
					destinationPositionNumber:
						Number(transition.destinationPositionIndex) + 1,
					originPositionIndex: undefined,
					destinationPositionIndex: undefined,
			  }
	);

export const getDescriptiveImage = (
	sport?: EnumSports,
	competitionGender?: EnumGenders,
	isCoach = false,
	isCollaborator = false,
	isReferee = false,
	isPlayerOrTeam = true,
	isAdmin = false
) =>
	isCoach
		? 'coach.png'
		: isCollaborator
		? 'collaborator.png'
		: isReferee
		? 'referee.png'
		: isPlayerOrTeam
		? sport === EnumSports.volleyball
			? competitionGender === EnumGenders.female
				? 'volleyball-player-female.png'
				: 'volleyball-player-male.png'
			: sport === EnumSports.basketball
			? competitionGender === EnumGenders.female
				? 'basketball-player-female.png'
				: 'basketball-player-male.png'
			: isFootball(sport)
			? competitionGender === EnumGenders.female
				? 'football-player-female.png'
				: 'football-player-male.png'
			: 'default.png'
		: isAdmin
		? 'admin.png'
		: 'default.png';
