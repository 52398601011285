import { texts } from '@app';

type EmptyStateProps = {
	marginTop?: number;
	imageURL?: string;
	text?: string;
};

const EmptyState = ({
	imageURL = `${process.env.REACT_APP_BACKEND_URL}/media/images/no-results.png`,
	marginTop = 20,
	text,
}: EmptyStateProps) => (
	<div className="flex justify-center">
		<div className="grid-col justify-center w-1/2">
			<div className="w-full mx-auto">
				<img
					src={imageURL}
					alt={texts.noResults}
					className={`flex items-center w-full h-full md:w-1/3 md:h-1/3 mx-auto mt-${marginTop}`}
				/>
			</div>
			{text && (
				<div className="items-center py-14 text-2xl text-center">{text}</div>
			)}
		</div>
	</div>
);

export default EmptyState;
