import { useAppSelector } from '@app';
import LoaderModal from '@molecules/LoaderModal';
import Header from '@organisms/Header';
import {
	isLoadingSelector,
	loadingTextSelector,
} from '@selectors/GenericSelector';
import { Outlet } from 'react-router-dom';

const Layout = () => {
	const isLoading = useAppSelector(isLoadingSelector);
	const loadingText = useAppSelector(loadingTextSelector);
	return (
		<div className="min-w-full select-none justify-center md:flex">
			<div className="min-h-screen flex-1">
				<Header />
				<div className="w-full bg-body pb-1">
					{isLoading && <LoaderModal title={loadingText} />}
					<div className={`${isLoading ? 'hidden' : ''}`}>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Layout;
