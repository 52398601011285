import { getImagesFromUploadedFiles } from '@actions/ConvertActions';
import { getNewsRelatedIds } from '@actions/NewsActions';
import { constraints, texts } from '@app';
import CustomDatePicker from '@atoms/CustomDatePicker';
import MuiDebouncedInput from '@atoms/MuiDebouncedInput';
import NewsConceptButton from '@atoms/NewsConceptButton';
import ConfirmActionModalWithButton from '@organisms/ConfirmActionModalWithButton';
import VisualsHandler from '@organisms/VisualsHandler';
import {
	CreatingNews,
	EnumEntityTypes,
	EnumNewsFields,
	EnumSports,
	NewsImageWithFile,
} from '@types';
import { dayjs } from '@utils/date';

type HorizontalNewsProps = Omit<CreatingNews, 'mainImage'> & {
	mainImage?: NewsImageWithFile;
	setMainImage?: (image?: NewsImageWithFile) => void;
	deleteNews?: () => void;
	updateNewsField?: (field: EnumNewsFields, value: string) => void;
	preventImageDeletion?: boolean;
	entityType?: EnumEntityTypes;
	sport?: EnumSports;
};

const HorizontalNews = ({
	backgroundColor,
	title,
	description,
	date,
	mainImage,
	setMainImage,
	titleColor,
	descriptionColor,
	deleteNews,
	updateNewsField,
	preventImageDeletion,
	entityType,
	sport,
	relatedId,
	secondaryRelatedId,
}: HorizontalNewsProps) => (
	<div
		id={texts.horizontalNews}
		className="grid grid-rows-1 border border-black px-4 py-2"
		style={{ background: backgroundColor }}>
		<div className="grid grid-cols-2">
			<div className="flex items-center">
				<VisualsHandler
					onMediaSaved={
						setMainImage
							? (images) =>
									setMainImage({
										...getImagesFromUploadedFiles(images)[0],
										uri: images[0].mediaUri,
										file: images[0].file,
										isMain: true,
									})
							: undefined
					}
					onMediaDeleted={
						setMainImage && !preventImageDeletion
							? () => setMainImage(undefined)
							: undefined
					}
					maxAmount={constraints.NEWS_MAX_PRIMARY_IMAGES}
					mediaUri={mainImage?.uri ?? undefined}
					visualName={texts.newsImage}
					editable={!!setMainImage}
					expandableWhenDisabled
				/>
			</div>
			<div className="w-full">
				<div className=" align:center">
					{(date || updateNewsField) &&
						(updateNewsField ? (
							<div className="w-full m-2    md:w-2/3 md:mx-auto">
								<CustomDatePicker
									placeholder={texts.date}
									value={
										date ? dayjs.utc(date).format('YYYY-MM-DD') : undefined
									}
									setValue={(newValue) => {
										if (!newValue) return;
										const newDate = dayjs.utc(newValue).format('YYYY-MM-DD');
										if (date !== newDate)
											updateNewsField(EnumNewsFields.date, newDate);
									}}
								/>
							</div>
						) : (
							<div className="text-center pb-2.5">
								{texts.formatDateWithText(date)}
							</div>
						))}
					<div
						className="text-3xl text-center pb-2.5"
						style={{ wordBreak: 'break-word', color: titleColor }}>
						{updateNewsField ? (
							<div className="w-full m-2    md:w-2/3 md:mx-auto">
								<MuiDebouncedInput
									value={title ?? ''}
									updateText={(value) =>
										updateNewsField(EnumNewsFields.title, value)
									}
									type="text"
									placeholder={texts.title}
								/>
							</div>
						) : (
							title
						)}
					</div>
					<p
						className=" text-center w-full"
						style={{ wordBreak: 'break-word', color: descriptionColor }}>
						{updateNewsField ? (
							<div className="w-full m-2    md:w-2/3 md:mx-auto">
								<MuiDebouncedInput
									value={description ?? ''}
									updateText={(value) =>
										updateNewsField(EnumNewsFields.description, value)
									}
									type="text"
									placeholder={texts.description}
								/>
							</div>
						) : (
							description
						)}
					</p>
				</div>
			</div>
		</div>

		{entityType && (
			<div className="my-2">
				<NewsConceptButton
					relatedIds={getNewsRelatedIds({
						relatedId,
						secondaryRelatedId,
					})}
					entityType={entityType}
					sport={sport}
				/>
			</div>
		)}

		{deleteNews && (
			<div className="flex justify-center">
				<ConfirmActionModalWithButton
					onConfirm={deleteNews}
					buttonText={texts.deleteNews}
					modalQuestion={texts.confirmDeleteNews}
					modalTitle={texts.deleteNews}
					triggerButtonClassName="button-delete mt-2"
				/>
			</div>
		)}
	</div>
);

export default HorizontalNews;
