import { texts } from '@app';
import { login, logout } from '@reducers/LoggedUserReducer';
import AuthService from '@services/AuthService';
import {
	EnumGenders,
	EnumSports,
	EnumUserTypes,
	FacebookUser,
	FbUserDetails,
	GgUserDetails,
	GoogleUser,
	Result,
	User,
} from '@types';
import dayjs from 'dayjs';
import { Dispatch } from 'redux';

import { convertFromBackend } from './ConvertActions';

const doLogout = (dispatch: Dispatch) => {
	localStorage.setItem('validUntil', '');
	localStorage.setItem('userId', '');
	localStorage.setItem('accessToken', '');
	dispatch(logout());
};

type ResultWithUser = Result & { user?: User };
type ResultWithFacebookUser = Result & {
	user?: FacebookUser & {
		accessToken?: string;
	};
};
type ResultWithFbUserDetails = Result & { user?: FbUserDetails };
type ResultWithGoogleUser = Result & { user?: GoogleUser };
type ResultWithLink = Result & { link?: string };
type UserAndAccess = {
	user: User;
	accessTokenValidUntil: string;
	accessToken: string;
};

export const loginAction =
	(
		email: string,
		password: string,
		callback: (result: ResultWithUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithUser;

		try {
			const response = await AuthService.loginService(email, password);
			const data = convertFromBackend(response.data) as UserAndAccess & {
				notifications: Notification[];
			};
			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: data.user,
			};

			if (
				result.success &&
				!!data.accessToken &&
				!!data.accessTokenValidUntil &&
				!!data.user?.id
			) {
				localStorage.setItem('validUntil', data.accessTokenValidUntil);
				localStorage.setItem('userId', data.user.id.toString());
				localStorage.setItem('accessToken', data.accessToken);

				dispatch(login(data.user));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const logoutAction =
	(callback: () => void) => async (dispatch: Dispatch) => {
		try {
			await AuthService.logoutService();
			doLogout(dispatch);

			callback();
		} catch (error) {
			callback();
		}
	};

export const checkExpiredSession =
	(callback?: (result: boolean) => void) => async (dispatch: Dispatch) => {
		try {
			const response = await AuthService.checkExpiredSession();

			if (
				!(
					response?.status === 200 && parseInt(response.data.code ?? '-1') === 0
				)
			) {
				doLogout(dispatch);
				if (callback) callback(false);
				return false;
			}
		} catch (error) {
			console.error('checkExpiredSession error:', error); // eslint-disable-line no-console
			doLogout(dispatch);
			if (callback) callback(false);
			return false;
		}
		if (callback) callback(true);
		return true;
	};

export const loginAuth = () =>
	localStorage.getItem('accessToken') &&
	localStorage.getItem('userId') &&
	(localStorage.getItem('validUntil') ?? '0') >
		dayjs().format('YYYY-MM-DD HH:mm:ss');

export const autoRegisterUserAction =
	(
		nickname: string,
		email: string,
		password: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined,
		callback: (result: ResultWithLink) => void
	) =>
	async () => {
		let result: ResultWithLink;

		try {
			const response = await AuthService.autoRegisterUserService(
				nickname,
				email,
				password,
				userType,
				competitionGender,
				sport
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				link: response.data.link,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const autoRegisterUserWithFacebookAction =
	(
		nickname: string,
		email: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined,
		fbUserId: string,
		fbUserAccessToken: string,
		callback: (result: ResultWithFacebookUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithFacebookUser;

		try {
			const response = await AuthService.autoRegisterUserWithFacebookService(
				nickname,
				email,
				userType,
				competitionGender,
				sport,
				fbUserId,
				fbUserAccessToken
			);

			const data = convertFromBackend(response.data) as Omit<
				UserAndAccess,
				'user'
			> & { user: FacebookUser };

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: data.user,
			};

			if (result.success) {
				localStorage.setItem('validUntil', data.accessTokenValidUntil);
				localStorage.setItem('userId', data.user.id.toString());
				localStorage.setItem('accessToken', data.accessToken);

				dispatch(login(data.user));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const autoRegisterUserWithGoogleAction =
	(
		nickname: string,
		email: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined,
		ggUserId: string,
		googleUserAccessToken: string,
		callback: (result: ResultWithGoogleUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithGoogleUser;

		try {
			const response = await AuthService.autoRegisterUserWithGoogleService(
				nickname,
				email,
				userType,
				competitionGender,
				sport,
				ggUserId,
				googleUserAccessToken
			);

			const data = convertFromBackend(response.data) as Omit<
				UserAndAccess,
				'user'
			> & { user: GoogleUser };

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: data.user,
			};

			if (result.success) {
				localStorage.setItem('validUntil', data.accessTokenValidUntil);
				localStorage.setItem('userId', data.user.id.toString());
				localStorage.setItem('accessToken', data.accessToken);

				dispatch(login(data.user));
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getFacebookUserInfoAction =
	(callback: (result: ResultWithFbUserDetails) => void) => async () => {
		let result: ResultWithFbUserDetails;

		try {
			AuthService.loginWithFacebookService((userDetails) => {
				result = {
					success: !!userDetails,
					forbidden: false,
					message: texts.errorGeneric(),
					user: userDetails,
				};
				callback(result);
			});
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
			callback(result);
		}
	};

export const loginWithFacebookAction =
	(callback: (result: ResultWithFacebookUser) => void) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithFacebookUser;

		try {
			AuthService.loginWithFacebookService(async (userDetails) => {
				if (!userDetails) {
					result = {
						success: false,
						forbidden: true,
						message: texts.errorGeneric(),
					};
					callback(result);
					return;
				}

				const response = await AuthService.loginFacebookUserInternally(
					userDetails
				);

				const data = convertFromBackend(response.data) as Omit<
					UserAndAccess,
					'user'
				> & { user: FacebookUser };

				result = {
					success: response.data.code === 0,
					forbidden: response.data.code === 403,
					message: response.data.desc ?? '',
					user: data.user,
				};

				if (result.success) {
					localStorage.setItem('validUntil', data.accessTokenValidUntil);
					localStorage.setItem('userId', data.user.id.toString());
					localStorage.setItem('accessToken', data.accessToken);

					dispatch(login(data.user));
				}
				callback(result);
			});
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
			callback(result);
		}
	};

export const loginWithGoogleAction =
	(
		userDetails: GgUserDetails,
		callback: (result: ResultWithGoogleUser) => void
	) =>
	async (dispatch: Dispatch) => {
		let result: ResultWithGoogleUser;

		try {
			const response = await AuthService.loginGoogleUserInternally(userDetails);

			const data = convertFromBackend(response.data) as Omit<
				UserAndAccess,
				'user'
			> & { user: GoogleUser };

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				user: data.user,
			};

			if (result.success) {
				localStorage.setItem('validUntil', data.accessTokenValidUntil);
				localStorage.setItem('userId', data.user.id.toString());
				localStorage.setItem('accessToken', data.accessToken);

				dispatch(login(data.user));
			}
			callback(result);
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
			callback(result);
		}
	};

export const initFacebookSdkAction = () => async () => {
	await AuthService.initFacebookSdkService();
};
