import { EnumEventTypes } from '@types';

import { texts } from '.';

const eTexts = {
	editCumulativeLeague: 'Editar liga acumulativa',
	editEvent: 'Editar evento',
	editMatch: 'Editar partido',
	editNotEditMatch: 'Editar/No editar partido',
	editProfile: 'Editar perfil',
	editStage: 'Editar competencia',
	editTime: 'Editar tiempo',
	email: 'Correo',
	emailAlreadyUsed:
		'El email ingresado ya está usado en el sistema, para registrar un nuevo usuario intentá con un email diferente.',
	emailNotValid:
		'El email no es válido, sólo se admiten letras, números, puntos, guiones, guiones bajos y una aparición del caracter "@".',
	emailRequired: 'El email es requerido',
	enterTextToConfirm: (text: string) =>
		`Ingresá "${text}" para poder confirmar`,
	entranceImage: 'Entrada al complejo',
	errorAddingSetMaximumAchieved:
		'No se pueden agregar más sets, el máximo fue alcanzado.',
	errorAddingPenalty: 'Los penales deben agregarse de arriba hacia abajo.',
	errorBabyFootballPlayerWithWrongCategory: (years: number) =>
		`No se pueden agregar jugadores mayores que ${years} años, si querés cambiar de categoría tenés que retirar todos los jugadores y registrar al mayor primero.`,
	// errorBabyFootballTooYoung:
	// 	'No se puede agregar un/a jugador/a de baby fútbol que cumpla menos de 6 años este año.',
	errorBabyFootballTooOld:
		'No se puede agregar un/a jugador/a de baby fútbol que cumpla más de 13 años este año.',
	errorCannotRemoveCumulatedLeagueFromCumulativeWithTransitions:
		'No se pueden quitar ligas acumuladas de una acumulativa con transiciones salientes, para quitarla: borrá las transiciones y volvé a intentarlo',
	errorChangingTournamentName: 'Error cambiando el nombre del equipo',
	errorCreatingNews:
		'Ocurrió un error creando la noticia, por favor reintentalo y si el error persiste comunicate con algún administrador',
	errorConnectingStages: 'Error conectando posiciones',
	errorConnectingStagesDestinationHasTeam:
		'Hay un equipo en la posición de destino, podés borrarlo si querés que resulte de una transición.',
	errorConnectingStagesRepeatsOrigin:
		'Error conectando posiciones: la posición ya fue usada de origen.',
	errorConnectingStagesCannotCreateCumulativeTransitionsInFinishStage:
		'Error conectando posiciones: no se pueden crear transiciones desde acumulativas en la página de finalizar competencia.',
	errorConnectingStagesRepeatsDestination:
		'Error conectando posiciones: la posición ya fue usada de destino.',
	errorConnectingStagesCumulativeCannotBeDestination:
		'Error conectando posiciones: no se puede transicionar a una liga acumulativa.',
	errorCopyingLink: 'Error copiando enlace',
	errorFinishingPeriod: 'Error finalizando período',
	errorFoundInMatch: (localName: string, visitorName: string) =>
		`No se puede iniciar el partido entre ${localName} y ${visitorName}:`,
	errorsFoundWithTeam: (isLocal: boolean, teamName: string) =>
		`Se encontraron errores con el equipo ${
			isLocal ? 'local' : 'visitante'
		} (${teamName})`,
	errorGeneratingQrCode:
		'Error generando el código QR, probá recargar la página o comunicate con un administrador si sigue fallando.',
	errorGettingCoordinates:
		'Ocurrió un error obteniendo las coordenadas, intentá abrir el mapa y buscar la latitud y longitud para registrar la ubicación de la cancha',
	errorIncorrectSetScores:
		'Error con los scores ingresados, por favor revisá antes de continuar',
	errorLessThanTwoParticipants: (stageName: string) =>
		`Como mínimo debe haber 2 participantes en ${stageName}`,
	errorLessThanTwoLeagues: 'Como mínimo debe haber 2 ligas acumuladas',
	errorLoadingNotifications: 'Error cargando notificaciones',
	errorLoadingTournamentWithId: 'Error cargando torneo con id: ',
	errorLoadingTeamWithId: 'Error cargando equipo con id: ',
	errorLoadingMatch: 'Error cargando el partido',
	errorLoadingPage: 'Error cargando página.',
	errorLoadingStage: (tournamentId: number, stageNumber: number) =>
		`Error cargando la competencia ${stageNumber} del torneo ${tournamentId}`,
	errorLogginWithGoogle:
		'Error obteniendo la información de Google, quizás las credenciales no fueron correctas.',
	errorMatchAmountCannotBeEvenForThisSport: (stageName: string) =>
		`La cantidad de partidos de llaves finales o no finales no puede ser par para este deporte. Ajustá esto en la etapa ${stageName} e intentalo de vuelta.`,
	errorMatchCouldntBePublished:
		'El partido no pudo ser publicado, por favor verificá los datos e intentá nuevamente',
	errorMatchCantBePublished:
		'El partido no puede ser publicado, por favor verificá que los datos del partido estén completos e intentá nuevamente',
	errorMatchCouldntBeStarted: 'El partido no puede ser empezado.',
	errorMatchCouldntBeSaved: 'El partido no pudo ser guardado.',
	errorMatchPlaceNotDefined: 'La ubicación del partido no está definida.',
	errorMatchNotStarted: 'El partido no ha empezado y no se puede mostrar',
	errorMovingOutOfBounds:
		'Error moviendo el elemento, no se puede ir de los límites.',
	errorMustBePositiveInteger: 'Se debe ingresar un número entero y positivo.',
	errorMustBeEqualOrSmallerThan: (max: number) =>
		`Se debe ingresar un número igual o menor a ${max}.`,
	errorNicknameEmpty: 'El nickname no puede ser vacío.',
	errorNoLocationDefined:
		'Se debe ingresar una ubicación para crear una cancha',
	errorNoPlaceName: 'Se debe ingresar un nombre de complejo',
	errorNoFieldName: 'Se debe ingresar un nombre de cancha',
	errorNoSport: 'Se debe ingresar un deporte para la cancha',
	errorNoAddress: 'Se debe ingresar una dirección para el complejo',
	errorNoZone: 'Se debe ingresar un departamento para el complejo',
	errorNoArrivingIndications: 'Se deben ingresar indicaciones para el complejo',
	errorNoLocationImage:
		'Se debe ingresar una imagen de ubicación interna de la cancha',
	errorNoInternalImage: 'Se debe ingresar una imagen interna de la cancha',
	errorNoEntranceImage:
		'Se debe ingresar una imagen de la entrada del complejo',
	errorNoLogo: 'Se debe ingresar un logo para el complejo',
	errorNoContactPerson: 'Se debe ingresar persona de contacto para el complejo',
	errorNoContactPhone: 'Se debe ingresar teléfono de contacto para el complejo',
	errorGeneric: () => {
		if (
			process.env.REACT_APP_API_URL !==
			'https://multitorneos.panaweb.uy/backend/api'
		) {
			// eslint-disable-next-line no-console
			console.error('generic error');
		}
		return 'Ocurrió un error, por favor reintentá más tarde.';
	},
	errorPasswordEmpty: 'La contraseña no puede ser vacía.',
	errorPendingParticipants:
		'No se pueden definir los cruces mientras haya participantes pendientes.',
	errorRegistering:
		'Error registrando al usuario, intentá de nuevo más tarde o envianos un email.',
	errorRegisterFailIdNumberAlreadyExists: (userId: number) =>
		`La cédula de identidad ya está usada en el sistema por el no usuario con id <${userId}>, para registrar un nuevo no usuario intentá con una diferente.`,
	errorRemovingSetMinimumAchieved:
		'No se pueden remover más sets, el mínimo fue alcanzado.',
	errorRoundCantBePublished:
		'La fecha no puede ser publicada, por favor verificá que los datos de los partidos estén completos e intentá nuevamente',
	errorRoundCouldntBePublished:
		'La fecha no pudo ser publicada, es probable que alguno de los partidos haya sido publicado pero no todos, por favor verificá los datos e intentá nuevamente',
	errorMissingDate: 'Falta completar la fecha de inicio y fin',
	errorNameShorterThanMinimum: (minLength: number) =>
		`El nombre es demasiado corto, tiene que tener al menos ${minLength} caracteres`,
	errorSearchCharacter:
		'El caracter "%" no está permitido, podés usar letras, números y otros caracteres para tu búsqueda.',
	errorStageCouldntBePublished:
		'La competencia no pudo ser publicada, por favor verificá los datos e intentá nuevamente',
	errorStageNotFound: 'La competencia no fue encontrada',
	errorStartDatetimeNotDefined: 'La fecha y hora del partido no está definida.',
	errorStartDatetimeBeforeThanNow:
		'La fecha y hora del partido es previa al momento actual.',
	errorRejectedParticipants:
		'Por favor eliminá a los participantes que rechazaron la invitación, antes de definir los cruces.',
	errorUpdatingSet: 'Error actualizando los datos del set',
	errorUpadtingMatchParticipationList:
		'Error actualizando lista de participación',
	errorUploadingImages: 'Error subiendo imágenes',
	errorUpdatingNotification: 'Error actualizando la notificación',
	errorWithIncomingPlayers:
		'Error con los jugadores entrantes, por favor revisá y volvé a intentarlo',
	errorWithOutgoingPlayers:
		'Error con los jugadores salientes, por favor revisá y volvé a intentarlo',
	errorWithScoresBetweenPeriods: (
		periodNumber: number,
		previousPeriodNumber: number
	) =>
		`Error con los resultados de los períodos ${previousPeriodNumber} y ${periodNumber}, por favor revisá y volvé a intentarlo.`,
	errorWithScoreNullInPeriod: (periodNumber: number) =>
		`Error con los resultados del período ${periodNumber}, por favor revisá y volvé a intentarlo.`,
	errorWithWonSetAmount: (setsToWin: number) =>
		`Error con la cantidad de sets ganados, debe haber un equipo con ${setsToWin} y otro con menos de ${setsToWin} para finalizar el partido. Por favor revisá los datos y volvé a intentarlo.`,
	errorWithoutRival: 'No tiene rival asignado, buscá uno y volvé a intentarlo.',
	errorWithUrlParam: 'Error con el parámetro de la URL.',
	event: 'Evento',
	eventCannotBeCreated:
		'No se puede crear este evento porque cambiaría el resultado del partido.',
	eventCannotBeDeleted:
		'No se puede eliminar este evento porque cambiaría el resultado del partido.',
	eventImg: (eventType: EnumEventTypes) => {
		switch (eventType) {
			case 'GOAL':
				return 'goal.png';
			case 'OWN_GOAL':
				return 'own_goal.png';
			case 'PK_GOAL':
				return 'pk_goal.png';
			case 'SPK_GOAL':
				return 'spk_goal.png';
			case 'YELLOW_CARD':
				return 'yellow_card.png';
			case 'RED_CARD':
				return 'red_card.png';
			case 'STARTS_PLAYING':
			case 'FINISHES_PLAYING':
			case 'STARTS_GOALKEEPING':
			case 'FINISHES_GOALKEEPING':
				return 'substitution.png';
			default:
				return '';
		}
	},
	eventName: (eventType: EnumEventTypes) => {
		switch (eventType) {
			case 'GOAL':
				return texts.goal;
			case 'OWN_GOAL':
				return texts.ownGoal;
			case 'PK_GOAL':
				return texts.pkGoal;
			case 'SPK_GOAL':
				return texts.spkGoal;
			case 'YELLOW_CARD':
				return texts.yellowCard;
			case 'RED_CARD':
				return texts.redCard;
			case 'STARTS_PLAYING':
				return texts.startsPlaying;
			case 'FINISHES_PLAYING':
				return texts.finishesPlaying;
			case 'STARTS_GOALKEEPING':
				return texts.startsGoalkeeping;
			case 'FINISHES_GOALKEEPING':
				return texts.finishesGoalkeeping;
			default:
				return texts.event;
		}
	},
	explanationOfPlayoffsPublishingImplication:
		'Al publicar los play-offs ya no podrás editar los equipos que participan en ellos. ' +
		'Lo que sí podrás hacer es asignar fecha, hora y lugar a los partidos así como árbitros. ' +
		'También podrás actualizar los partidos en tiempo real para que puedan ser presenciados por los interesados y las interesadas. ' +
		'Si no los querés actualizar en tiempo real, también podrás asignar los resultados después de terminados los partidos.',
	explanationOfMemberDeletingImplication:
		'Al quitar un miembro ya no podrás volver a agregarlo sin que él acepte unirse.',
	explanationOfMemberDeletingImplicationWhenLastTeamAndNoUser:
		'Este equipo es el último del noUsuario que estás retirando, si confirmás se lo retirará del equipo y se lo eliminará del sistema.',
	explanationOfNoUserDeletingImplication:
		'Al eliminar un noUsuario se lo quitará de los equipos a los que pertenece y se eliminará toda la información del mismo en el sistema.',
	explanationOfConvertToUserImplication:
		'Le enviaremos un email al nuevo usuario para que complete su conversión de noUsuario a usuario. Una vez convertido perderás el control sobre el noUsuario y el nuevo usuario podrá loguearse y autogestionarse en el sistema.',
	explanationOfInvitationUndoingImplication:
		'La invitación ya fue enviada, al retirarla no podrá aceptarse ni rechazarse pero sí podrá verse.',
	explanationOfRequestUndoingImplication:
		'La solicitud ya fue enviada, al retirarla no podrá aceptarse ni rechazarse pero sí podrá verse.',
	explanationOfMemberAcceptingImplication:
		'Al aceptar al miembro pasará a formar parte del equipo.',
	explanationOfInvitationAcceptingImplication:
		'Al aceptar pasarás a formar parte del equipo.',
	explanationOfInvitationRejectingImplication:
		'Al rechazar ya no podrás integrar el equipo hasta que te hagan otra invitación o te acepten una solicitud de ingreso.',
	explanationOfCoachInvitationRejectingImplication:
		'Al rechazar ya no podrás integrar el equipo hasta que te hagan otra invitación.',
	explanationOfMemberRejectingImplication:
		'Al rechazar al miembro ya no podrá aceptarse hasta que haga otra solicitud para unirse.',
	explanationOfImageDeletingImplication:
		'Al borrar la imagen ya no podrá restaurarse pero podrás subirla de vuelta si querés.',
	explanationOfRoundPublishingImplication:
		'Al publicar la fecha ya no podrás editar datos de los partidos de la misma como ubicación de los partidos ni fecha y hora de inicio. ' +
		'Lo que sí podrás hacer es comenzar la gestión del partido en directo donde podrás cargar los resultados del mismo mientras que se juega. ' +
		'Mientras cargás los resultados, todas las personas que quieran podrán seguirlos accediendo al link del partido. ',
	explanationOfStageFinishingImplication:
		'Al terminar la competencia ya no podrás editar resultados de los partidos de la misma.',
	explanationOfStageDeleting:
		'Se va a eliminar la competencia. Recordá que no podrá ser restaurada.',
	explanationOfStageRestoring:
		'Se va a restaurar la competencia. Luego de hacerlo podrás publicarla para que se juegue.',
	explanationOfStagePublishingImplication:
		'Al publicar la competencia ya no podrás editar los equipos que participan en ella ni el orden de los partidos de la misma. ' +
		'Lo que sí podrás hacer es asignar fecha, hora y lugar a los partidos así como árbitros. ' +
		'También podrás actualizar los partidos en tiempo real para que puedan ser presenciados por los interesados y las interesadas. ' +
		'Si no los querés actualizar en tiempo real, también podrás asignar los resultados después de terminados los partidos.',
	explanationOfStageFinishingWithoutTransitionImplication:
		'Al terminar la competencia ya no podrás editar resultados de los partidos de la misma. Además, si la terminás sin realizar transiciones a próximas competencias, luego tendrás que indicar los ascensos, descensos y permanencias manualmente.',
	explanationOfMatchPublishingImplication:
		'Mientras cargás los resultados, todas las personas que quieran podrán seguirlos accediendo al link del partido.',
	explanationOfFriendlyInvitatingImplication:
		'Al enviar la invitación ya no podrás editar datos como la ubicación del partido ni el momento de inicio. ' +
		'Cuando tu rival acepte o rechace la invitación recibirás una notificación.',
	explanationOfMemberSettingAsManagerImplication:
		'Si se designa como dirigente a un usuario el mismo puede realizar cambios en todo el equipo.',
	explanationOfMemberUnsettingAsOrganizerImplication:
		'Si se remueve como directivo a un usuario el mismo ya no puede realizar cambios en el club.',
	explanationOfMemberUnsettingAsManagerImplication:
		'Si se remueve como dirigente a un usuario el mismo ya no puede realizar cambios en el equipo.',
	explanationOfSetFinishing:
		'Al terminar el set ya no podrás cambiar los resultados de los equipos. ' +
		'Además, las personas que estén asistiendo al partido verán el mismo como finalizado. ' +
		'Por favor confirmá que realmente querés terminar el set para continuar.',
	explanationOfMatchFinishing:
		'Al terminar el partido ya no podrás cambiar los resultados de los equipos. ' +
		'Además, las personas que estén asistiendo al partido lo verán como finalizado. ' +
		'Por favor confirmá que realmente querés terminar el partido para continuar.',
	explanationOfTournamentManagerCreation:
		'Los organizadores de torneos pueden gestionar torneos y competencias, ' +
		'es importante que sólo se creen aquellos organizadores de torneos que ya se hayan comprometido a usar correctamente ' +
		'el sistema y asistirlos para que lo hagan.',
	expand: 'Expandir',
	expandToSeeMatches: 'Expandí para ver los partidos',
	extraTime: (extraTimeNumber: number) => `Alargue ${extraTimeNumber}`,
};

// eslint-disable-next-line import/prefer-default-export
export { eTexts };
