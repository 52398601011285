import { texts } from '@app';
import { BackendObject, FileImage, FrontendObject, UploadedFile } from '@types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const convertToLocalDatetimeFromUTC = (datetime: string) =>
	dayjs.utc(datetime).local().format('YYYY-MM-DD HH:mm:ss');

const convertToUTCFromLocalDatetime = (localDatetime: string) =>
	dayjs(localDatetime).utc().format('YYYY-MM-DD HH:mm:ss');

export const convertFromBackend: (
	objectFromBackend: BackendObject
) => FrontendObject = (objectFromBackend) => {
	if (objectFromBackend === null || objectFromBackend === undefined)
		return objectFromBackend;

	if (Array.isArray(objectFromBackend)) {
		return objectFromBackend.map((backendObject) =>
			convertFromBackend(backendObject)
		);
	}

	if (typeof objectFromBackend === 'object') {
		const objectCopy = { ...objectFromBackend };
		const props = Object.getOwnPropertyNames(objectCopy);

		if (props.length === 0) {
			return objectCopy;
		}
		props.forEach((value) => {
			const convertedValue = value
				.replace(/_([a-z])/g, (v) => v.toUpperCase())
				.replaceAll('_', '');

			objectCopy[convertedValue] = convertFromBackend(
				objectCopy[value] as BackendObject
			);
			if (convertedValue !== value) {
				delete objectCopy[value];
			}
		});

		return objectCopy;
	}

	if (typeof objectFromBackend === 'number') return objectFromBackend;

	if (
		/[0-9]{2,4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/.test(objectFromBackend)
	) {
		return convertToLocalDatetimeFromUTC(objectFromBackend);
	}

	return objectFromBackend;
};

export const convertStringForBackend = (string: string) =>
	string
		.split(/(?=[A-Z])/)
		.join('_')
		.toLowerCase();

export const convertToBackend: (
	frontObject: FrontendObject
) => BackendObject = (objectToBackend) => {
	if (objectToBackend === null || objectToBackend === undefined)
		return objectToBackend;

	// converting arrays
	if (Array.isArray(objectToBackend)) {
		const arrayCopy = [...objectToBackend];
		for (let index = 0; index < arrayCopy.length; index += 1) {
			const element = arrayCopy[index];
			arrayCopy[index] = convertToBackend(element);
		}
		return arrayCopy;
	}

	// converting objects
	if (typeof objectToBackend === 'object') {
		const objectCopy = { ...objectToBackend };
		const props = Object.getOwnPropertyNames(objectCopy);
		if (props.length === 0) {
			return objectCopy;
		}
		props.forEach((value) => {
			const convertedValue = convertStringForBackend(value);

			objectCopy[convertedValue] = convertToBackend(
				objectCopy[value] as FrontendObject
			);
			if (convertedValue !== value) {
				delete objectCopy[value];
			}
		});
		return objectCopy;
	}

	if (typeof objectToBackend === 'number') return objectToBackend;

	// converting dates
	if (/[0-9]{2,4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(objectToBackend)) {
		return convertToUTCFromLocalDatetime(objectToBackend);
	}

	return objectToBackend;
};

export const convertUriToBase64 = (imageUri: string) =>
	// TODO implement this.
	null;

export const getImagesFromUploadedFiles: (
	images: UploadedFile[]
) => Required<FileImage>[] = (images) =>
	images.map((image) => ({
		...image,
		imageUri: image.mediaUri,
		data_url: '',
	}));

export const getOptionsFromEnum = <T>(entries: [string, T][]) =>
	entries.map((entry) => ({
		value: entry[1],
		text: (texts[entry[0] as keyof typeof texts] ?? entry[0]) as string,
	}));
