import { constants, texts } from '@app';
import { useEffect, useState } from 'react';

const QuickAccessWhatsAppIcon = () => {
	const [hideContent, setHideContent] = useState(false);

	useEffect(() => {
		const handleScroll = () => setHideContent(window.scrollY > 10);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className={`z-10 fixed right-5 bottom-5 ${
				hideContent ? 'hidden' : 'flex flex-col'
			}`}>
			<a
				href={`https://wa.me/${constants.WHATSAPP_CONTACT_NUMBER}`}
				target="_blank"
				rel="noopener noreferrer"
				className="shadow-sm color-bg-whatsapp w-12 h-12 rounded-full flex mx-auto    sm:w-16 sm:h-16">
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/media/images/whatsapp-logo.png`}
					alt="WhatsApp"
				/>
			</a>
			<span className="text-sm bg-white rounded-lg">{texts.anyDoubts}</span>
		</div>
	);
};

export default QuickAccessWhatsAppIcon;
