// eslint-disable-next-line
import {
	convertStringForBackend as _convertStringForBackend,
	convertToBackend,
} from '@actions/ConvertActions';
import {
	MatchPenaltiesResult,
	MatchPeriod,
	Set,
	EnumSides,
	EnumSports,
	FootballMatchParticipation,
	BasketballMatchParticipation,
} from '@types';
import {
	EnumEventTypes,
	EnumFoulTypes,
	EnumRefereeRoles,
	EnumWalkoverTypes,
} from 'src/types/Enums';
import { deleteRequest, getRequest, patchRequest, postRequest } from '@app';

const MatchService = {
	updateMatchInfoService: async (
		matchId: number,
		field: string,
		value: string | number | null | { name: string; placeId: number }
	) => {
		const fieldToBackend = _convertStringForBackend(field);
		return postRequest(
			'update_match_info.php',
			convertToBackend({
				matchId,
				field: fieldToBackend,
				value,
			}) as Record<string, string>
		);
	},
	updateFinishedMatchSetsService: async (matchId: number, sets: Set[]) =>
		postRequest(
			'update_finished_match_sets.php',
			convertToBackend({
				matchId,
				sets,
			}) as Record<string, string>
		),

	updateFinishedBasketballMatchPeriodsService: async (
		matchId: number,
		periods: MatchPeriod[]
	) =>
		postRequest(
			'update_finished_basketball_match_periods.php',
			convertToBackend({
				matchId,
				periods,
			}) as Record<string, string>
		),

	saveFootballMatchCompletedManuallyService: async (matchId: number) =>
		postRequest(
			'save_football_match_completed_manually.php',
			convertToBackend({
				matchId,
			}) as Record<string, string>
		),

	inviteRefereeToMatchService: async (
		matchId: number,
		refereeId: number,
		role: EnumRefereeRoles,
		waitForConfirmation: boolean
	) =>
		postRequest(
			'invite_referee_to_match.php',
			convertToBackend({
				matchId,
				refereeId,
				role,
				waitForConfirmation,
			}) as Record<string, string>
		),
	removeRefereeFromMatchService: async (matchId: number, refereeId: number) =>
		postRequest(
			'remove_referee_from_match.php',
			convertToBackend({
				matchId,
				refereeId,
			}) as Record<string, string>
		),
	publishMatchService: async (
		tournamentId: number,
		stageNumber: number,
		matchId: number
	) =>
		postRequest(
			'publish_match.php',
			convertToBackend({ tournamentId, stageNumber, matchId }) as Record<
				string,
				string
			>
		),
	inviteToFriendlyMatchService: async (
		localId: number,
		visitorId: number,
		fieldName?: string,
		placeId?: number,
		startDatetime?: string,
		turn?: number,
		message?: string,
		setsAmount?: number,
		scoreNoLastSet?: number,
		scoreLastSet?: number
	) =>
		postRequest(
			'invite_to_friendly_match.php',
			convertToBackend({
				localId,
				visitorId,
				fieldName,
				placeId,
				startDatetime,
				turn,
				message,
				setsAmount,
				scoreNoLastSet,
				scoreLastSet,
			}) as Record<string, string>
		),
	startMatchService: async (matchId: number) =>
		postRequest(
			'start_match.php',
			convertToBackend({ matchId }) as Record<string, string>
		),
	completeMatchManuallyService: async (matchId: number) =>
		postRequest(
			'complete_match_manually.php',
			convertToBackend({ matchId }) as Record<string, string>
		),
	cancelCompleteMatchManuallyService: async (matchId: number) =>
		postRequest(
			'reset_match.php',
			convertToBackend({ matchId }) as Record<string, string>
		),

	resetMatchService: async (matchId: number) =>
		postRequest(
			'reset_match.php',
			convertToBackend({ matchId }) as Record<string, string>
		),

	updateTeamParticipationListService: async (
		matchId: number,
		teamId: number,
		participationList:
			| FootballMatchParticipation[]
			| BasketballMatchParticipation[]
	) => {
		const response = await postRequest(
			'update_team_participation_list.php',
			convertToBackend({ matchId, teamId, participationList }) as Record<
				string,
				string
			>
		);
		return response;
	},

	createFootballEventService: async (
		type: EnumEventTypes,
		periodNumber: number,
		time: number,
		matchId: number,
		playerId: number,
		side: EnumSides,
		sport: EnumSports
	) =>
		postRequest(
			'create_football_event.php',
			convertToBackend({
				type,
				period: periodNumber,
				time,
				matchId,
				playerId,
				side,
				sport,
			}) as Record<string, string>
		),

	createFootballSubstitutionsService: async (
		period: number,
		time: number,
		matchId: number,
		playersIncomingIds: number[],
		playersOutgoingIds: number[],
		side: EnumSides,
		sport: EnumSports
	) =>
		postRequest(
			'create_football_substitutions.php',
			convertToBackend({
				period,
				time,
				matchId,
				playersIncomingIds,
				playersOutgoingIds,
				side,
				sport,
			}) as Record<string, string>
		),

	editFootballEventService: async (
		matchId: number,
		eventNumber: number,
		playerId: number,
		sport: EnumSports
	) =>
		patchRequest(
			'update_football_event.php',
			convertToBackend({
				matchId,
				eventNumber,
				playerId,
				sport,
			}) as Record<string, string>
		),

	deleteFootballEventService: async (eventNumber: number, matchId: number) => {
		const response = await deleteRequest(
			'delete_football_event.php',
			convertToBackend({ eventNumber, matchId }) as Record<string, string>
		);
		return response;
	},
	finishPeriodService: async (matchId: number, periodNumber: number) => {
		const response = await postRequest(
			'finish_period.php',
			convertToBackend({ matchId, periodNumber }) as Record<string, string>
		);
		return response;
	},

	getMatchService: async (matchId: number) => {
		const response = await getRequest(
			'get_match.php',
			convertToBackend({ matchId }) as Record<string, string>
		);
		return response;
	},

	getMatchUpdatesService: (matchId: number, lastMutationNumber: number) =>
		getRequest(
			'get_match_updates.php',
			convertToBackend({
				matchId,
				lastMutationNumber,
			}) as Record<string, string>
		),

	getLiveMatchesService: async (localeDiffInMinutes: number) =>
		getRequest(
			'get_in_course_matches.php',
			convertToBackend({ localeDiffInMinutes }) as Record<string, string>
		),

	getCurrentSetService: async (matchId: number) =>
		getRequest(
			'get_current_set.php',
			convertToBackend({ matchId }) as Record<string, string>
		),

	listFieldsForFriendlyService: async (sport: EnumSports) =>
		getRequest(
			'list_fields_for_friendly.php',
			convertToBackend({ sport }) as Record<string, string>
		),

	updateBasketballMatchFouls: async (input: {
		matchId: number;
		periodNumber: number;
		playerId: number;
		isLocalTeam: boolean;
		foulType: EnumFoulTypes;
		addFoul: boolean;
	}) =>
		postRequest(
			'update_basketball_match_fouls.php',
			convertToBackend(input) as Record<string, string>
		),

	updateFootballMatchFoulsService: async (input: {
		matchId: number;
		periodNumber: number;
		isLocalTeam: boolean;
		addFoul: boolean;
	}) =>
		postRequest(
			'update_football_match_fouls.php',
			convertToBackend(input) as Record<string, string>
		),

	updateFootballMatchPenaltiesService: async (input: {
		matchId: number;
		firstKick: EnumSides;
		penalties: MatchPenaltiesResult;
	}) =>
		postRequest(
			'update_football_match_penalties.php',
			convertToBackend(input) as Record<string, string>
		),

	applyWalkover: async (matchId: number, walkoverType: EnumWalkoverTypes) =>
		postRequest(
			'apply_walkover.php',
			convertToBackend({ matchId, walkoverType }) as Record<string, string>
		),
};

export default MatchService;
