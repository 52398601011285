import { isValidDate } from '@actions/GenericActions';
import { texts } from '@app';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

type CustomDatePickerProps = {
	editable?: boolean;
	max?: string;
	min?: string;
	placeholder?: string;
	setValue: (value: '' | Date | null) => void;
	value?: string;
	fullWidth?: boolean;
};

const CustomDatePicker = ({
	editable = true,
	max,
	min,
	placeholder = texts.selectDate,
	setValue,
	value,
	fullWidth,
}: CustomDatePickerProps) => (
	<LocalizationProvider
		dateAdapter={AdapterDayjs}
		adapterLocale="es"
		localeText={
			esES.components.MuiLocalizationProvider.defaultProps.localeText
		}>
		<DatePicker
			label={placeholder}
			defaultValue={value && dayjs(value)}
			onChange={(newValue) => {
				const date = newValue ? newValue.toDate() : undefined;

				if (date && isValidDate(date)) {
					setValue(date);
					return;
				}
				setValue(null);
			}}
			minDate={min ? dayjs(min) : undefined}
			maxDate={max ? dayjs(max) : undefined}
			readOnly={!editable}
			slotProps={{
				textField: { size: 'small' },
			}}
			sx={fullWidth ? { width: '100%' } : {}}
		/>
	</LocalizationProvider>
);
export default CustomDatePicker;
