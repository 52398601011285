import { getOptionsFromEnum } from '@actions/ConvertActions';
import {
	Category,
	CreatingMatch,
	EnumBasketballPositions,
	EnumF5Positions,
	EnumF11Positions,
	EnumFootballParticipationStatuses,
	EnumGameModes,
	EnumMatchStatuses,
	EnumMemberRoles,
	EnumSports,
	EnumStageStatuses,
	EnumUserTypes,
	EnumVolleyballPositions,
	NewField,
	Position,
	TextAndValue,
	VisualStage,
} from '@types';

import { texts } from '../language';
import imageConstants from './images';
import urlConstants from './urls';

const constants = {
	...urlConstants,

	sessionDaysDuration: 90,

	CUMULATIVE: 'cumulative',

	CATEGORIES: [
		// {
		// 	name: 'CAT_LESS_THAN_5_YEARS',
		// 	title: texts.babyFootballCategoryNotYet,
		// 	isNotSelectable: true,
		// },
		{
			name: 'CAT_5_YEARS',
			title: texts.babyFootballCategory5Years,
			femaleTitle: texts.babyFootballCategoryU9,
			yearsThisYear: 5,
		},
		{
			name: 'CAT_6_YEARS',
			title: texts.babyFootballCategory6Years,
			femaleTitle: texts.babyFootballCategoryU9,
			yearsThisYear: 6,
		},
		{
			name: 'CAT_7_YEARS',
			title: texts.babyFootballCategory7Years,
			femaleTitle: texts.babyFootballCategoryU9,
			yearsThisYear: 7,
		},
		{
			name: 'CAT_8_YEARS',
			title: texts.babyFootballCategory8Years,
			femaleTitle: texts.babyFootballCategoryU9,
			yearsThisYear: 8,
		},
		{
			name: 'CAT_9_YEARS',
			title: texts.babyFootballCategory9Years,
			femaleTitle: texts.babyFootballCategoryU9,
			yearsThisYear: 9,
		},
		{
			name: 'CAT_10_YEARS',
			title: texts.babyFootballCategory10Years,
			femaleTitle: texts.babyFootballCategoryU11,
			yearsThisYear: 10,
		},
		{
			name: 'CAT_11_YEARS',
			title: texts.babyFootballCategory11Years,
			femaleTitle: texts.babyFootballCategoryU11,
			yearsThisYear: 11,
		},
		{
			name: 'CAT_12_YEARS',
			title: texts.babyFootballCategory12Years,
			femaleTitle: texts.babyFootballCategoryU13,
			yearsThisYear: 12,
		},
		{
			name: 'CAT_13_YEARS',
			title: texts.babyFootballCategory13Years,
			femaleTitle: texts.babyFootballCategoryU13,
			yearsThisYear: 13,
		},
		{
			name: 'CAT_MORE_THAN_13_YEARS',
			title: texts.babyFootballCategoryAlreadyFinished,
			femaleTitle: texts.babyFootballCategoryAlreadyFinished,
			isNotSelectable: true,
		},
	] as Category[],

	DEFAULT_MATCH: {
		status: EnumMatchStatuses.NOT_STARTED,
		sport: EnumSports.volleyball,
		local: {
			isNoGame: false,
			name: '',
			members: [],
			sport: EnumSports.volleyball,
		},
		visitor: {
			isNoGame: false,
			name: texts.withoutOpponent,
			members: [],
			sport: EnumSports.volleyball,
		},
		availableFields: [],
		availablePlaces: [],
		availableReferees: [],
	} as CreatingMatch,
	DEFAULT_STAGE: {
		color: 0x19366e,
		gameMode: EnumGameModes.league,
		isDeleted: false,
		managerIds: [],
		matches: [],
		matchesFinalBracket: 1,
		matchesNoFinalBracket: 1,
		name: '',
		number: -1,
		periodAmount: 2,
		periodDuration: 20,
		positions: [],
		sport: EnumSports.football11,
		standings: [],
		status: EnumStageStatuses.NOT_STARTED,
		tournamentId: -1,
		tournamentName: '',
		x: 0,
		y: 0,
	} as VisualStage,
	DEFAULT_POSITIONS: [
		{
			index: 0,
			number: 1,
			stageNumber: 1,
		},
		{
			index: 1,
			number: 2,
			stageNumber: 1,
		},
	],
	DEFAULT_CUMULATIVE_LEAGUE: {
		id: -1,
		name: '',
		isActive: true,
		isCumulative: true,
		leagueNumbers: [],
		positions: [],
		standings: [],
		x: 0,
		y: 0,
		color: 0x19366e,
	},
	DEFAULT_TOURNAMENT: {
		cumulativeLeagues: [],
		id: -1,
		name: '',
		periodDuration: 20,
		periodAmount: 2,
		scoreLastSet: 15,
		scoreNoLastSet: 25,
		setsAmount: 5,
		sport: EnumSports.football11,
		stages: [],
		transitions: [],
		cumulativeTransitions: [],
	},
	DEFAULT_FOOTBALL_EVENT: {
		side: 'LOCAL',
		seconds: '',
		minutes: '',
		period: texts.periodWithNumber(1),
	},

	DEFAULT_PLAYER: {
		email: '',
		nickname: '',
		isAdmin: false,
		isCoach: false,
		isCollaborator: false,
		isPlayer: true,
		isReferee: false,
		isTournamentManager: false,
		type: EnumUserTypes.PLAYER,
		notifications: [],
	},
	DEFAULT_PLACE: {
		address: '',
		arrivingIndications: '',
		contactPerson: '',
		contactPhone: '',
		fields: [],
		latitude: -34.78509135163514,
		longitude: -56.242793080158684,
		name: '',
		zone: '',
	} as NewField['place'],

	STAGE_COLORS: [
		0x19366e, 0x05671b, 0xad0202, 0x091bb9, 0x307933, 0x8d0202, 0x0e25f8,
		0x0f561e, 0x690404, 0x0066b3, 0x083c13, 0xd80606,
	],

	MEMBER_ROLES: [
		{ text: texts.noTeamManager, value: EnumMemberRoles.BASIC_MEMBER },
		{ text: texts.teamManager, value: EnumMemberRoles.ORGANIZER },
	] as TextAndValue<EnumMemberRoles>[],

	USER_TYPES: [
		{ text: texts.player, value: EnumUserTypes.PLAYER, autoRegister: true },
		{
			text: texts.collaborator,
			value: EnumUserTypes.COLLABORATOR,
			autoRegister: true,
		},
		{ text: texts.coach, value: EnumUserTypes.COACH, autoRegister: true },
		{ text: texts.referee, value: EnumUserTypes.REFEREE, autoRegister: true },
		{
			text: texts.fieldResponsible,
			value: EnumUserTypes.FIELD_RESPONSIBLE,
			autoRegister: true,
		},
		{
			text: texts.tournamentManager,
			value: EnumUserTypes.TOURNAMENT_MANAGER,
		},
		{ text: texts.admin, value: EnumUserTypes.ADMIN },
	] as { text: string; value: EnumUserTypes; autoRegister?: boolean }[],

	FOOTBALL_FIRST_EXTRA_TIME_PERIOD_INDEX: (periodAmount = 2) =>
		periodAmount + 1,
	FOOTBALL_BETWEEN_EXTRA_TIME_INDEX: (periodAmount = 2) => periodAmount + 1.5,
	FOOTBALL_SECOND_EXTRA_TIME_PERIOD_INDEX: (periodAmount = 2) =>
		periodAmount + 2,
	FOOTBALL_PENALTY_SHOTOUT_PERIOD_INDEX: (periodAmount = 2) =>
		periodAmount + 2.5,

	INTERMEDIATE_FOOTBALL_DEFAULT_PENALTY_AMOUNT: 3,

	F11_DEFAULT_PENALTIES_QUANTITY: 5,

	MATCH_PARTICIPATION_STATUSES: [
		{
			text: texts.starterGoalkeeper,
			value: EnumFootballParticipationStatuses.gkStarter,
		},
		{
			text: texts.starter,
			value: EnumFootballParticipationStatuses.starter,
		},
		{
			text: texts.substitute,
			value: EnumFootballParticipationStatuses.substitute,
		},
		{
			text: texts.notPlaying,
			value: EnumFootballParticipationStatuses.notPlaying,
		},
		{
			text: texts.pending,
			value: EnumFootballParticipationStatuses.pending,
		},
	] as { text: string; value: EnumFootballParticipationStatuses }[],

	TIMEOUTS_AVAILABLE_FOR_SET: 2,
	TIMEOUTS_AVAILABLE_FOR_PERIOD: 4,

	TEMPLATE_NEWS_HORIZONTAL: 2,
	TEMPLATE_NEWS_VERTICAL: 1,

	PENALTY_STATUS_GOAL: 'Goal',
	PENALTY_STATUS_MISS: 'Miss',
	PENALTY_STATUS_PENDING: 'Pending',

	PK_EXTRA_GOAL: 'PK_EXTRA_GOAL',
	PK_EXTRA_MISS: 'PK_EXTRA_MISS',

	CONFIRM_MATCH_PARTICIPATION: 'CONFIRM_MATCH_PARTICIPATION',
	REJECT_MATCH_PARTICIPATION: 'REJECT_MATCH_PARTICIPATION',

	MATCH_SIDE_LOCAL: 'LOCAL',
	MATCH_SIDE_VISITOR: 'VISITOR',

	STAGE_FINISHED_TRANSITION_ASCENDS: 'ASC',
	STAGE_FINISHED_TRANSITION_DESCENDS: 'DESC',
	STAGE_FINISHED_TRANSITION_STAYS: 'STAY',
	STAGE_FINISHED_TRANSITION_DISQUALIFIED: 'DISQ',
	STAGE_FINISHED_TRANSITION_QUALIFIED: 'QUAL',

	GAME_MODES: [
		{ text: texts.league, value: 'LEAGUE' },
		{ text: texts.playoff, value: 'PLAYOFF' },
	],

	FILTER_MY_TOURNAMENTS: 'mis-torneos',
	FILTER_MY_TEAMS: 'mis-equipos',
	FILTER_UPCOMING_STAGES: 'proximas-competencias',
	FILTER_IN_COURSE_STAGES: 'competencias-en-curso',

	SEARCH_PARAMETER_EXCLUDE_PLAYERS_FROM_TEAM: 1,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL: 2,
	SEARCH_PARAMETER_INCLUDE_USER_NICKNAME: 3,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL_AND_NICKNAME: 4,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL_AND_NICKNAME_AND_PLAYER_NAME_AND_SURNAME: 41,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL_NICKNAME_NAME_SURNAME_ID_NUMBER: 42,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL_AND_NICKNAME_AND_COLLABORATOR_NAME_AND_SURNAME: 43,
	SEARCH_PARAMETER_ONLY_ACTIVE_USERS: 5,
	SEARCH_PARAMETER_EXCLUDE_TEAM_IDS: 6,
	SEARCH_PARAMETER_INCLUDE_TEAM_NAME: 7,
	SEARCH_PARAMETER_INCLUDE_TOURNAMENT_NAME_OR_STAGE_NAME: 8,
	SEARCH_PARAMETER_INCLUDE_MATCH_PARTICIPANTS: 9,
	SEARCH_PARAMETER_IGNORE_MATCHES_NO_MATCH: 10,
	SEARCH_PARAMETER_INCLUDE_FIELD_NAME_OR_PLACE_NAME: 11,
	SEARCH_PARAMETER_INCLUDE_PLACE_NAME: 12,
	SEARCH_PARAMETER_INCLUDE_USER_EMAIL_AND_NICKNAME_AND_COACH_NAME_AND_SURNAME: 13,
	SEARCH_PARAMETER_FILTER_TEAM_SPORT: 14,
	SEARCH_PARAMETER_FILTER_PLAYER_SPORT: 15,
	SEARCH_PARAMETER_FILTER_PLAYER_GENDER: 151,
	SEARCH_PARAMETER_FILTER_COACH_SPORT: 16,
	SEARCH_PARAMETER_FILTER_FIELD_SPORT: 17,
	SEARCH_PARAMETER_FILTER_MANAGER_TEAMS: 18,
	SEARCH_PARAMETER_FILTER_NO_MANAGER_TEAMS: 19,
	SEARCH_PARAMETER_FILTER_MANAGER_PLAYERS: 20,
	SEARCH_PARAMETER_FILTER_NO_MANAGER_PLAYERS: 21,
	SEARCH_PARAMETER_EXCLUDE_COLLABORATORS_FROM_TEAM: 122,
	SEARCH_PARAMETER_EXCLUDE_NO_USER_COACHES: 123,
	SEARCH_PARAMETER_EXCLUDE_NO_USER_PLAYERS: 124,
	SEARCH_PARAMETER_FILTER_MANAGER_REFEREES: 24,
	SEARCH_PARAMETER_FILTER_NO_MANAGER_REFEREES: 25,
	SEARCH_PARAMETER_FILTER_REFEREE_SPORT: 26,
	SEARCH_PARAMETER_FILTER_COACH_PLAYERS: 27,
	SEARCH_PARAMETER_FILTER_NO_COACH_PLAYERS: 28,
	SEARCH_PARAMETER_INCLUDE_TEAM_GENDER: 29,
	SEARCH_PARAMETER_INCLUDE_TEAM_CATEGORY: 30,
	SEARCH_PARAMETER_EXCLUDE_ORGANIZER_FROM_CLUB: 33,
	SEARCH_PARAMETER_INCLUDE_CLUB_NAME: 34,

	SETS_AMOUNT_OPTIONS: [
		{ text: '1', value: 1 },
		{ text: '3', value: 3 },
		{ text: '5', value: 5 },
		{ text: '7', value: 7 },
	],

	MIN_STAGE_NAME_LENGTH: 3,

	DEFAULT_TEXT_COLOR_CREATE_NEWS_MODAL: '#090808',
	DEFAULT_BG_COLOR_CREATE_NEWS_MODAL: '#FFFFFF',

	DEBOUNCED_INPUT_TIMEOUT: 500,
	RELOAD_IN_COURSE_MATCH_MODAL: 10000,
	RELOAD_MATCH_WHEN_NOT_EDITING_TIMEOUT_IN_MS: 500,
	RELOAD_FOOTBALL_MATCH_WHEN_NOT_EDITING_TIMEOUT_IN_MS: 1000,
	RELOAD_MATCH_WHEN_EDITING_TIMEOUT: 15000,

	CONTACT_EMAIL: texts.contactEmail,

	WHATSAPP_CONTACT_NUMBER: '+59895731837',

	...imageConstants,
};

const positionsForSport = (sport?: EnumSports) =>
	(sport === EnumSports.football11 ||
	sport === EnumSports.babyFootball ||
	sport === EnumSports.football7 ||
	sport === EnumSports.football8
		? getOptionsFromEnum<EnumF11Positions>(Object.entries(EnumF11Positions))
		: sport === EnumSports.football5
		? getOptionsFromEnum<EnumF5Positions>(Object.entries(EnumF5Positions))
		: sport === EnumSports.basketball
		? getOptionsFromEnum<EnumBasketballPositions>(
				Object.entries(EnumBasketballPositions)
		  )
		: getOptionsFromEnum<EnumVolleyballPositions>(
				Object.entries(EnumVolleyballPositions)
		  )) as unknown as {
		text: string;
		value: Position;
	}[];

export { constants, positionsForSport, texts };
