import { isInProduction } from '@actions/GenericActions';
import genericReducer from '@reducers/GenericReducer';
import loggedUserReducer from '@reducers/LoggedUserReducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const loggedUserPersistConfig = {
	key: 'loggedUser',
	storage,
	blacklist: [],
};

const genericPersistConfig = {
	key: 'generic',
	storage,
	blacklist: [],
};

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));

const rootReducer = combineReducers({
	loggedUser: persistReducer(loggedUserPersistConfig, loggedUserReducer),
	generic: persistReducer(genericPersistConfig, genericReducer),
});
const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
	enhancers: [composedEnhancer],
	devTools: !isInProduction(),
});

const persistor = persistStore(store);
// const store = configureStore({
// 	reducer: {
// 		generic: genericReducer,
// 		loggedUser: loggedUserReducer,
// 	},
// });

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export { type AppDispatch, persistor, type RootState, store };
