/* eslint-disable */
import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { ChangeEvent, Component } from 'react';

import { constants } from '@app';

type MuiDebouncedInputProps = {
	value: string;
	updateText: (newText: string) => void;
	placeholder: string;
	editable: boolean;
	autoFocus: boolean;
	type: 'text' | 'number';
	multiline?: boolean;
};

type MuiDebouncedInputState = {
	value: string;
	updateText: (newText: string) => void;
	placeholder: string;
	editable: boolean;
	autoFocus: boolean;
	type: 'text' | 'number';
	multiline?: boolean;
};

class MuiDebouncedInput extends Component<
	MuiDebouncedInputProps,
	MuiDebouncedInputState
> {
	static propTypes = {
		value: PropTypes.string.isRequired,
		updateText: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		editable: PropTypes.bool,
		autoFocus: PropTypes.bool,
		type: PropTypes.oneOf(['text', 'number']),
	};

	static defaultProps = {
		placeholder: '',
		editable: true,
		autoFocus: false,
		type: 'text',
	};

	constructor(props: MuiDebouncedInputProps) {
		super(props);
	}

	componentDidMount() {
		this.sendTextChange = debounce(
			this.sendTextChange,
			constants.DEBOUNCED_INPUT_TIMEOUT
		);
		this.setState({ value: this.props.value });
	}

	componentDidUpdate(nextProps: MuiDebouncedInputProps) {
		if (nextProps.value !== this.props.value)
			this.setState({ value: this.props.value });
	}

	render() {
		return (
			<TextField
				defaultValue={this.props.value}
				InputProps={{
					readOnly: !this.props.editable,
					onChange: (e) => {
						if (this.props.editable) {
							this.handleTextChange(e);
						}
					},
					autoFocus: this.props.autoFocus,
					placeholder: this.props.placeholder,
				}}
				variant="outlined"
				label={this.props.placeholder}
				type={this.props.type}
				fullWidth
				multiline={this.props.multiline}
			/>
		);
	}

	handleTextChange = (e: ChangeEvent) => {
		this.setState({ value: (e.target as HTMLInputElement).value });
		this.sendTextChange((e.target as HTMLInputElement).value.trim());
	};

	sendTextChange = (value: string) => this.props.updateText(value);
}

export default MuiDebouncedInput;
