import { getProfilePartAction } from '@actions/UserActions';
import { texts, useAppSelector } from '@app';
import {
	setBelongingTeams,
	setLoggedUserRefreshed,
} from '@reducers/LoggedUserReducer';
import { loggedUserSelector } from '@selectors/LoggedUserSelector';
import { dayjs } from '@utils/date';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

const useLoggedUser = () => {
	const loggedUser = useAppSelector(loggedUserSelector);
	const dispatch = useDispatch();

	if (
		loggedUser.id &&
		(!loggedUser.refreshDatetime ||
			dayjs.utc().diff(dayjs(loggedUser.refreshDatetime), 'minutes') > 2) // Refresh the logged user data every 2 minutes.
	) {
		dispatch(setLoggedUserRefreshed({ refreshDatetime: dayjs.utc().toDate() }));
		dispatch(
			getProfilePartAction(
				loggedUser.id,
				texts.teams.toLowerCase(),
				(result) => {
					if (!result.success || !result.user) {
						return;
					}

					const loadedUser = result.user;

					// TODO: Update here any other logged user fields that should be refreshed frequently.
					// TODO: Use the useLoggedSelector in every page that needs to access the logged user data instead of loggedUserSelector + useAppSelector.

					dispatch(setBelongingTeams(loadedUser.belongingTeams ?? []));
				}
			) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);
	}

	return { loggedUser };
};

export default useLoggedUser;
