import { EnumFileTypes, Image, UploadedFile } from '@types';
import axios, { AxiosResponse } from 'axios';
import FormData from 'form-data';

const baseURL = process.env.REACT_APP_API_URL;
let authorization = localStorage.getItem('userId')
	? `${localStorage.getItem('userId')}:${localStorage.getItem('accessToken')}`
	: process.env.REACT_APP_DEFAULT_API_KEY;
let defaultHeaders = {
	'Content-Type': 'application/json;charset=utf-8',
	Authorization: `Basic ${authorization}`,
};

const updateDefaultHeaders = () => {
	authorization = localStorage.getItem('userId')
		? `${localStorage.getItem('userId')}:${localStorage.getItem('accessToken')}`
		: process.env.REACT_APP_DEFAULT_API_KEY;

	defaultHeaders = {
		'Content-Type': 'application/json;charset=utf-8',
		Authorization: `Basic ${authorization}`,
	};
};

export const getRequest = (url: string, params?: Record<string, string>) => {
	updateDefaultHeaders();
	return axios.get(`${baseURL}/${url}?${new URLSearchParams(params)}`, {
		headers: defaultHeaders,
	});
};

export const getBlobRequest = (
	url: string,
	params?: Record<string, string>
) => {
	updateDefaultHeaders();
	return axios.get(`${baseURL}/${url}?${new URLSearchParams(params)}`, {
		headers: defaultHeaders,
		responseType: 'blob',
	});
};

export const postRequest = (url: string, params?: Record<string, string>) => {
	updateDefaultHeaders();
	return axios.post(`${baseURL}/${url}`, params, { headers: defaultHeaders });
};

export const patchRequest = (url: string, params?: Record<string, string>) => {
	updateDefaultHeaders();
	return axios.patch(`${baseURL}/${url}`, params, { headers: defaultHeaders });
};

export const deleteRequest = (url: string, params?: Record<string, string>) => {
	updateDefaultHeaders();
	return axios.delete(`${baseURL}/${url}?${new URLSearchParams(params)}`, {
		headers: defaultHeaders,
	});
};

export const putFilesRequest = (
	url: string,
	files: UploadedFile[] | null,
	type: EnumFileTypes,
	userId: number
) => {
	updateDefaultHeaders();
	const data = new FormData();
	if (files)
		files.forEach((fileContainer) => {
			data.append(type, fileContainer.file, fileContainer.file.name);
		});

	data.append('user_id', userId);

	return axios.put(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putTeamFilesRequest = (
	url: string,
	files: UploadedFile[],
	type: EnumFileTypes,
	teamId: number
) => {
	updateDefaultHeaders();
	const data: FormData = new FormData();
	files.forEach((fileContainer) => {
		data.append(type, fileContainer.file, fileContainer.file.name);
	});

	data.append('team_id', teamId);

	return axios.put(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putClubFilesRequest = (
	url: string,
	files: UploadedFile[],
	type: EnumFileTypes,
	clubId: number
) => {
	updateDefaultHeaders();
	const data: FormData = new FormData();
	files.forEach((fileContainer) => {
		data.append(type, fileContainer.file, fileContainer.file.name);
	});

	data.append('club_id', clubId);

	return axios.put(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putPlaceFilesRequest = (
	url: string,
	files: (UploadedFile | undefined)[],
	type: EnumFileTypes,
	placeId: number,
	fieldName?: string
) => {
	updateDefaultHeaders();
	const data = new FormData();
	files.forEach((fileContainer) => {
		if (fileContainer)
			data.append(type, fileContainer?.file, fileContainer?.file.name);
	});

	if (!files[0]) data.append('without_location_image', 1);
	if (!files[1]) data.append('without_internal_image', 1);
	data.append('place_id', placeId);
	data.append('field_name', fieldName);

	return axios.put<
		UploadedFile[],
		AxiosResponse<{ files: Image[]; code: number; desc?: string }>,
		FormData
	>(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putNewsFilesRequest = (
	url: string,
	files: UploadedFile[],
	type: EnumFileTypes,
	newsId: number,
	includesMainImage: boolean
) => {
	updateDefaultHeaders();
	const data = new FormData();
	files.forEach((fileContainer) => {
		if (fileContainer)
			data.append(type, fileContainer?.file, fileContainer?.file.name);
	});

	data.append('news_id', newsId);
	data.append('includes_main_image', includesMainImage);

	return axios.put<
		UploadedFile[],
		AxiosResponse<{ files: Image[]; code: number; desc?: string }>,
		FormData
	>(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putStageFilesRequest = (
	url: string,
	files: UploadedFile[],
	type: EnumFileTypes,
	stageNumber: number,
	tournamentId: number
) => {
	updateDefaultHeaders();
	const data = new FormData();
	files.forEach((fileContainer) => {
		if (fileContainer)
			data.append(type, fileContainer?.file, fileContainer?.file.name);
	});

	data.append('stage_number', stageNumber);
	data.append('tournament_id', tournamentId);

	return axios.put<
		UploadedFile[],
		AxiosResponse<{ files: Image[]; code: number; desc?: string }>,
		FormData
	>(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};

export const putTournamentFilesRequest = (
	url: string,
	files: UploadedFile[],
	type: EnumFileTypes,
	tournamentId: number
) => {
	updateDefaultHeaders();
	const data = new FormData();
	files.forEach((fileContainer) => {
		if (fileContainer)
			data.append(type, fileContainer?.file, fileContainer?.file.name);
	});

	data.append('tournament_id', tournamentId);

	return axios.put<
		UploadedFile[],
		AxiosResponse<{ files: Image[]; code: number; desc?: string }>,
		FormData
	>(`${baseURL}/${url}`, data, {
		headers: {
			...defaultHeaders,
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data`,
		},
	});
};
