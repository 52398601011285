enum EnumGameTypes {
	PLAYOFF = 'PLAYOFF',
	LEAGUE = 'LEAGUE',
}

enum EnumGenders {
	male = 'MALE',
	female = 'FEMALE',
}

enum EnumSports {
	volleyball = 'VOLLEYBALL',
	basketball = 'BASKETBALL',
	football5 = 'FOOTBALL_5',
	babyFootball = 'BABY_FOOTBALL',
	football7 = 'FOOTBALL_7',
	football8 = 'FOOTBALL_8',
	football11 = 'FOOTBALL_11',
}

enum EnumWalkoverTypes {
	local = 'WALKOVER_LOCAL',
	visitor = 'WALKOVER_VISITOR',
	both = 'WALKOVER_BOTH',
}

enum EnumF11Positions {
	posGoalKeeper = 'goalKeeper',
	posCenterBack = 'centerBack',
	posRightBack = 'rightBack',
	posLeftBack = 'leftBack',
	posDefendingMidfielder = 'defendingMidfielder',
	posRightMidfielder = 'rightMidfielder',
	posLeftMidfielder = 'leftMidfielder',
	posAttackingMidfielder = 'attackingMidfielder',
	posRightWing = 'rightWing',
	posLeftWing = 'leftWing',
	posStriker = 'striker',
	posSecondStriker = 'secondStriker',
}

enum EnumF5Positions {
	posGoalKeeper = 'goalKeeper',
	posDefender = 'defender',
	posMidfielder = 'midfielder',
	posAtacker = 'atacker',
}

enum EnumBasketballPositions {
	posPointGuard = 'pointGuard',
	posShootingGuard = 'shootingGuard',
	posSmallForward = 'smallForward',
	posPowerForward = 'powerForward',
	posCenter = 'center',
}

enum EnumVolleyballPositions {
	posSetter = 'setter',
	posOutsideHitter = 'outsideHitter',
	posOppositeHitter = 'oppositeHitter',
	posMiddleBlocker = 'middleBlocker',
	posLibero = 'libero',
}

enum EnumStatuses {
	'PENDING' = 'PENDING',
	'NOT_STARTED' = 'NOT_STARTED',
	'PUBLISHED' = 'PUBLISHED',
	'IN_COURSE' = 'IN_COURSE',
	'FINISHED' = 'FINISHED',
	'COMPLETING_MANUALLY' = 'COMPLETING_MANUALLY',
}

enum EnumStageStatuses {
	'NOT_STARTED' = 'NOT_STARTED',
	'IN_COURSE' = 'IN_COURSE',
	'FINISHED' = 'FINISHED',
}

enum EnumCumulativeLeagueStatuses {
	transitioned = 'TRANSITIONED',
	notTransitioned = 'NOT_TRANSITIONED',
}

enum EnumBreadcrumbCategories {
	'activity' = 'activity',
	'container' = 'container',
}

enum EnumUserFields {
	'name',
	'surname',
	'nickname',
	'height',
	'email',
	'primary_position',
	'secondary_position',
	'medical_record_expiration_date',
	'cellphone',
	'id_number',
	'birth_date',
	'start_date',
}

enum EnumUserTypes {
	PLAYER = 'player',
	COLLABORATOR = 'collaborator',
	COACH = 'coach',
	REFEREE = 'referee',
	FIELD_RESPONSIBLE = 'field_responsible',
	TOURNAMENT_MANAGER = 'tournament_manager',
	ADMIN = 'administrator',
}

enum EnumGameModes {
	playoff = 'PLAYOFF',
	league = 'LEAGUE',
}

enum EnumMatchStatuses {
	PENDING = 'PENDING',
	NOT_STARTED = 'NOT_STARTED',
	PUBLISHED = 'PUBLISHED',
	IN_COURSE = 'IN_COURSE',
	FINISHED = 'FINISHED',
	COMPLETING_MANUALLY = 'COMPLETING_MANUALLY',
}

enum EnumSetStatuses {
	'IN_COURSE' = 'IN_COURSE',
	'FINISHED' = 'FINISHED',
}

enum EnumPeriodStatuses {
	'NOT_STARTED' = 'NOT_STARTED',
	'RUNNING' = 'RUNNING',
	'PAUSED' = 'PAUSED',
	'FINISHED' = 'FINISHED',
}

enum EnumInvitationStatuses {
	'ACCEPTED' = 'ACCEPTED',
	'REJECTED' = 'REJECTED',
	'INVITED' = 'INVITED',
	'ASKED' = 'ASKED',
}

enum EnumEntityTypes {
	team = 'team',
	match = 'match',
	tournament = 'tournament',
	league = 'league',
	playoff = 'playoff',
	field = 'field',
}

const EnumParticipationStatuses = {
	accepted: 'ACCEPTED',
	rejected: 'REJECTED',
	pending: 'PENDING',
} as const;

// Disabling no redeclare to use enums as types and enums with the same name.
// eslint-disable-next-line @typescript-eslint/no-redeclare
type EnumParticipationStatuses =
	(typeof EnumParticipationStatuses)[keyof typeof EnumParticipationStatuses];

const EnumFootballParticipationInMatchStatuses = {
	pending: EnumParticipationStatuses.pending,
	notPlaying: 'NOT_PLAYING',
	gkStarter: 'GK_STARTER',
	starter: 'STARTER',
	substitute: 'SUBSTITUTE',
} as const;

enum EnumSuspension {
	twoYellowCard = 0,
	redCard = 1,
}

// Disabling no redeclare to use enums as types and enums with the same name.
// eslint-disable-next-line @typescript-eslint/no-redeclare
type EnumFootballParticipationInMatchStatuses =
	(typeof EnumFootballParticipationInMatchStatuses)[keyof typeof EnumFootballParticipationInMatchStatuses];

const EnumFootballParticipationStatuses = {
	...EnumParticipationStatuses,
	...EnumFootballParticipationInMatchStatuses,
} as const;

// Disabling no redeclare to use enums as types and enums with the same name.
// eslint-disable-next-line @typescript-eslint/no-redeclare
type EnumFootballParticipationStatuses =
	(typeof EnumFootballParticipationStatuses)[keyof typeof EnumFootballParticipationStatuses];

const EnumBasketballParticipationInMatchStatuses = {
	pending: EnumParticipationStatuses.pending,
	notPlaying: 'NOT_PLAYING',
	playing: 'PLAYING',
} as const;

// Disabling no redeclare to use enums as types and enums with the same name.
// eslint-disable-next-line @typescript-eslint/no-redeclare
type EnumBasketballParticipationInMatchStatuses =
	(typeof EnumBasketballParticipationInMatchStatuses)[keyof typeof EnumBasketballParticipationInMatchStatuses];

const EnumBasketballParticipationStatuses = {
	...EnumParticipationStatuses,
	...EnumBasketballParticipationInMatchStatuses,
} as const;

// Disabling no redeclare to use enums as types and enums with the same name.
// eslint-disable-next-line @typescript-eslint/no-redeclare
type EnumBasketballParticipationStatuses =
	(typeof EnumBasketballParticipationStatuses)[keyof typeof EnumBasketballParticipationStatuses];

enum EnumBreadCrumbTypes {
	'activity' = 'activity',
	'container' = 'container',
}

enum EnumPenaltyTypes {
	goal = 'GOAL',
	miss = 'MISS',
	pending = 'PENDING',
	pkExtraGoal = 'PK_EXTRA_GOAL',
	pkExtraMiss = 'PK_EXTRA_MISS',
}

enum EnumRefereeRoles {
	'REFEREE' = 'REFEREE',
	'REFEREE_MAIN' = 'MAIN',
	'REFEREE_SECONDARY' = 'SECONDARY',
	'REFEREE_SCORER' = 'SCORER',
	'REFEREE_ASSISTANT' = 'ASSISTANT',
	'REFEREE_LINEMAN' = 'LINEMAN',
}

enum EnumEventTypes {
	goal = 'GOAL',
	ownGoal = 'OWN_GOAL',
	pkGoal = 'PK_GOAL',
	spkGoal = 'SPK_GOAL',
	yellowCard = 'YELLOW_CARD',
	redCard = 'RED_CARD',
	startsPlaying = 'STARTS_PLAYING',
	finishesPlaying = 'FINISHES_PLAYING',
	startsGoalkeeping = 'STARTS_GOALKEEPING',
	finishesGoalkeeping = 'FINISHES_GOALKEEPING',
	substitution = 'SUBSTITUTION',
}

enum EnumSides {
	'VISITOR' = 'VISITOR',
	'LOCAL' = 'LOCAL',
}

enum EnumFoulTypes {
	'PERSONAL' = 'PERSONAL',
	'TECHNICAL' = 'TECHNICAL',
	'FLAGRANT' = 'FLAGRANT',
	'AVAILABLE' = 'AVAILABLE',
}

enum EnumFileTypes {
	'IMAGE' = 'image',
}

enum EnumActionTypes {
	'ACCEPT_JOIN_TEAM_ASK' = 'ACCEPT_JOIN_TEAM_ASK',
	'ACCEPT_TEAM_INVITATION' = 'ACCEPT_TEAM_INVITATION',
	'ACCEPT_FRIENDLY_INVITATION' = 'ACCEPT_FRIENDLY_INVITATION',
	'CONFIRM_ASSISTANT_REFEREE' = 'CONFIRM_ASSISTANT_REFEREE',
	'CONFIRM_LINEMAN_REFEREE' = 'CONFIRM_LINEMAN_REFEREE',
	'CONFIRM_MAIN_REFEREE' = 'CONFIRM_MAIN_REFEREE',
	'CONFIRM_SCORER_REFEREE' = 'CONFIRM_SCORER_REFEREE',
	'CONFIRM_SECONDARY_REFEREE' = 'CONFIRM_SECONDARY_REFEREE',
	'CONFIRM_TEAM_PARTICIPATION_IN_STAGE' = 'CONFIRM_TEAM_PARTICIPATION_IN_STAGE',
	'REJECT_ASSISTANT_REFEREE' = 'REJECT_ASSISTANT_REFEREE',
	'REJECT_JOIN_TEAM_ASK' = 'REJECT_JOIN_TEAM_ASK',
	'REJECT_LINEMAN_REFEREE' = 'REJECT_LINEMAN_REFEREE',
	'REJECT_MAIN_REFEREE' = 'REJECT_MAIN_REFEREE',
	'REJECT_SCORER_REFEREE' = 'REJECT_SCORER_REFEREE',
	'REJECT_SECONDARY_REFEREE' = 'REJECT_SECONDARY_REFEREE',
	'REJECT_TEAM_INVITATION' = 'REJECT_TEAM_INVITATION',
	'REJECT_TEAM_PARTICIPATION_IN_STAGE' = 'REJECT_TEAM_PARTICIPATION_IN_STAGE',
	'REJECT_FRIENDLY_INVITATION' = 'REJECT_FRIENDLY_INVITATION',
}

enum EnumMemberRoles {
	'BASIC_MEMBER' = 'BASIC_MEMBER',
	'ORGANIZER' = 'ORGANIZER',
}

enum EnumCapabilities {
	'CAPABILITY_CREATE_USER' = 1,
	'CAPABILITY_EDIT_USER' = 2,
	'CAPABILITY_LIST_USERS' = 4,
	'CAPABILITY_CREATE_TEAM' = 5,
	'CAPABILITY_CREATE_TOURNAMENT' = 6,
	'CAPABILITY_EDIT_TOURNAMENT' = 7,
	'CAPABILITY_EDIT_MATCH' = 8,
	'CAPABILITY_VIEW_MATCH' = 9,
	'CAPABILITY_CREATE_TOURNAMENT_MANAGER' = 10,
	'CAPABILITY_CREATE_FIELD' = 11,
	'CAPABILITY_EDIT_PLACE' = 12,
	'CAPABILITY_CREATE_NEWS' = 13,
	'CAPABILITY_EDIT_NEWS' = 14,
	'CAPABILITY_DELETE_TOURNAMENT' = 15,
	'CAPABILITY_DELETE_TEAM' = 16,
	'CAPABILITY_DELETE_STAGE' = 17,
	'CAPABILITY_MANAGE_LIVE_OWN_MATCHES' = 18,
	'CAPABILITY_CREATE_CLUB' = 19,
}

enum EnumNewsFields {
	title = 'title',
	subtitle = 'subtitle',
	description = 'description',
	finalText = 'final',
	date = 'date',
	mainImage = 'mainImage',
}

export {
	EnumActionTypes,
	EnumBasketballParticipationInMatchStatuses,
	EnumBasketballParticipationStatuses,
	EnumBasketballPositions,
	EnumBreadcrumbCategories,
	EnumBreadCrumbTypes,
	EnumCapabilities,
	EnumCumulativeLeagueStatuses,
	EnumEntityTypes,
	EnumEventTypes,
	EnumF5Positions,
	EnumF11Positions,
	EnumFileTypes,
	EnumFootballParticipationInMatchStatuses,
	EnumFootballParticipationStatuses,
	EnumFoulTypes,
	EnumGameModes,
	EnumGameTypes,
	EnumGenders,
	EnumInvitationStatuses,
	EnumMatchStatuses,
	EnumMemberRoles,
	EnumNewsFields,
	EnumParticipationStatuses,
	EnumPenaltyTypes,
	EnumPeriodStatuses,
	EnumRefereeRoles,
	EnumSetStatuses,
	EnumSides,
	EnumSports,
	EnumStageStatuses,
	EnumStatuses,
	EnumSuspension,
	EnumUserFields,
	EnumUserTypes,
	EnumVolleyballPositions,
	EnumWalkoverTypes,
};
