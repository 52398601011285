// import axios from 'axios';
import { convertToBackend } from '@actions/ConvertActions';
import { deleteRequest, getRequest, postRequest } from '@app';
import {
	EnumGenders,
	EnumSports,
	EnumUserTypes,
	FbUserDetails,
	GgUserDetails,
} from '@types';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

type FBType = {
	login: (
		loginFunction: (loginResponse: {
			authResponse: { accessToken: string };
		}) => void,
		scope: { scope: string }
	) => void;
	getLoginStatus: (
		callback: (params: { authResponse: { accessToken: string } }) => void
	) => void;
	api: (
		url: string,
		callback: (apiResponse: { email: string; id: string; name: string }) => void
	) => void;
	init: (params: {
		appId?: string;
		cookie: boolean;
		xfbml: boolean;
		version: string;
	}) => void;
};

type CustomWindow = Window &
	typeof globalThis & { FB: FBType } & { fbAsyncInit: () => void };

const AuthService = {
	loginService: async (email: string, password: string) =>
		postRequest(
			'login.php',
			convertToBackend({ email, password }) as Record<string, string>
		),

	logoutService: async () => {
		const response = await deleteRequest('logout.php');
		return response;
	},

	checkExpiredSession: async () => {
		const accessToken = localStorage.getItem('accessToken');
		const userId = localStorage.getItem('userId');

		const isSpectator = userId && !accessToken;

		/**
		 * Avoiding to call the validate_token
		 * service when accessToken
		 * or userId are not valid.
		 */
		return accessToken && userId && accessToken !== 'null' && userId !== 'null'
			? getRequest(
					'validate_token.php',
					convertToBackend({
						accessToken: localStorage.getItem('accessToken'),
						userId: localStorage.getItem('userId'),
					}) as Record<string, string>
			  )
			: { status: 200, data: { code: !isSpectator ? 400 : 0 } };
	},

	autoRegisterUserService: async (
		nickname: string,
		email: string,
		password: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined
	) => {
		const response = await postRequest(
			'auto_register_user.php',
			convertToBackend({
				nickname,
				email,
				password,
				userType,
				competitionGender,
				sport,
			}) as Record<string, string>
		);
		return response;
	},

	autoRegisterUserWithFacebookService: async (
		nickname: string,
		email: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined,
		fbUserId: string,
		accessToken: string
	) => {
		const response = await postRequest(
			'auto_register_user_with_facebook.php',
			convertToBackend({
				nickname,
				email,
				userType,
				competitionGender,
				sport,
				fbUserId,
				accessToken,
			}) as Record<string, string>
		);
		return response;
	},

	autoRegisterUserWithGoogleService: async (
		nickname: string,
		email: string,
		userType: EnumUserTypes,
		competitionGender: EnumGenders | undefined,
		sport: EnumSports | undefined,
		userId: string,
		accessToken: string
	) => {
		const response = await postRequest(
			'auto_register_user_with_google.php',
			convertToBackend({
				nickname,
				email,
				userType,
				competitionGender,
				sport,
				userId,
				accessToken,
			}) as Record<string, string>
		);
		return response;
	},

	loginWithFacebookService: (
		callback: (userDetails?: FbUserDetails) => void
	) => {
		const loginStatusCallback = (authResponse: { accessToken: string }) => {
			if (authResponse) {
				if (process.env.NODE_ENV !== 'development') {
					AuthService.getFacebookUserDetails((userDetails) => {
						callback({ ...userDetails, accessToken: authResponse.accessToken });
					});
				} else {
					setTimeout(() => {
						callback({
							email: 'ciro8989@gmail.com',
							id: '10224915875478233',
							name: 'Ciro Leonardo SayaguésLaso Verde',
							accessToken: authResponse.accessToken,
						});
					}, 300);
				}
				return;
			}

			if (process.env.NODE_ENV !== 'development') {
				(window as CustomWindow).FB.login(
					(loginResponse: { authResponse: { accessToken: string } }) => {
						if (loginResponse.authResponse) {
							(window as CustomWindow).FB.api(
								'/me?fields=email,id,name',
								(apiResponse) => {
									callback({
										...apiResponse,
										accessToken: loginResponse.authResponse.accessToken,
									});
								}
							);
						} else {
							callback();
						}
					},
					{ scope: 'email' }
				);
			} else {
				const apiResponse = {
					email: 'ciro8989@gmail.com',
					id: '10224915875478233',
					name: 'Ciro Leonardo SayaguésLaso Verde',
					accessToken:
						'EAAEIi6CEMcABAJx3XtcJ5Sr0GHEAHMd4pipcEFFpKhGQdtd6xGlZBV9iO7SH9iAMA1qABowpuTPgwmSC8Rm86XzyWQpu5oq5dFx3o76OHmfg2RFWKqyJlfUpZAINGdZCyjbshloMVbZCCDZAllA6l2KbEAgDAGgcxpXeZAXucLcoCbjZAOXwBYg',
				};

				setTimeout(() => {
					callback(apiResponse);
				}, 500);
			}
		};

		if (process.env.NODE_ENV !== 'development') {
			(window as CustomWindow).FB.getLoginStatus(({ authResponse }) => {
				loginStatusCallback(authResponse);
			});
		} else {
			const authResponse = {
				accessToken:
					'EAAEIi6CEMcABAJx3XtcJ5Sr0GHEAHMd4pipcEFFpKhGQdtd6xGlZBV9iO7SH9iAMA1qABowpuTPgwmSC8Rm86XzyWQpu5oq5dFx3o76OHmfg2RFWKqyJlfUpZAINGdZCyjbshloMVbZCCDZAllA6l2KbEAgDAGgcxpXeZAXucLcoCbjZAOXwBYg',
				expiresIn: 5100035,
				signedRequest:
					'UlKZTbD_Nxli5eWIZiLrFsQs-Ts5PKkVo4C3Tn8H2bo.eyJ1c2VyX2lkIjoiMTAyMjQ5MTU4NzU0NzgyMzMiLCJjb2RlIjoiQVFDWFF4UGczUUZSWEp4SXJOM3NLOEM4cnc5VHE4YW85eXg4T19XdzF2LWZheUdXaldRcmpORzNtdUdhRTY5V0NmNlFqb2puZi13N2dGcUtUVzdLcXlMQmUyUlROeTNaREZrSEplbFA3ZUJkS3FFbXlqb3dvSnB6cDBmWVhSNC1feURXMmhQU21iaDVtVmxSSEtKbU8wM0Rlb0VxYWl1SmJsV2dvU0tHMDEwel8wSzFwVk9CNWZ2QW5GZmIwREx2YW80NFNlVzBHWjl6XzhKWnVvbHVmMnRfS201M0FScHlyNHUwVjZoNXkybW9TdkE1ai1QNENtX2QtRTRXMUJEWFB2ZW4zTjE4YWNBYTdjSlVaNVZKVDN2bkJjOGVYWnI3VDNFNGNDS3JvY2p5b1RMVnVNZVBBNlBTSHhGUjdVaG9lWW8iLCJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImlzc3VlZF9hdCI6MTY1MTAxMDgzNX0',
				userID: '10224915875478233',
			};
			loginStatusCallback(authResponse);
		}
	},

	getFacebookUserDetails: (callback: (userDetails: FbUserDetails) => void) => {
		if (process.env.NODE_ENV !== 'development') {
			(window as CustomWindow).FB.api(
				'/me?fields=email,id,name',
				(response: FbUserDetails) => {
					callback(response);
				}
			);
		} else {
			const apiResponse = {
				email: 'ciro8989@gmail.com',
				id: '10224915875478233',
				name: 'Ciro Leonardo SayaguésLaso Verde',
			};
			callback(apiResponse);
		}
	},

	loginFacebookUserInternally: async (userDetails: FbUserDetails) => {
		const newResponse = {
			...userDetails,
			fbUserId: userDetails.id,
		};
		delete newResponse.id;
		return postRequest(
			'login_with_facebook.php',
			convertToBackend(newResponse) as Record<string, string>
		);
	},

	loginGoogleUserInternally: async (userDetails: GgUserDetails) =>
		postRequest(
			'login_with_google.php',
			convertToBackend(userDetails) as Record<string, string>
		),

	initFacebookSdkService: async () =>
		new Promise(() => {
			// wait for facebook sdk to initialize before starting the react app
			(window as CustomWindow).fbAsyncInit = () => {
				(window as CustomWindow).FB.init({
					appId: facebookAppId,
					cookie: true,
					xfbml: true,
					version: 'v8.0',
				});
			};

			// load facebook sdk script
			((d, s, id) => {
				const fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) {
					return;
				}
				const js = d.createElement(s) as HTMLElement & { src: string };
				js.id = id;
				js.src = 'https://connect.facebook.net/en_US/sdk.js';
				fjs.parentNode?.insertBefore(js, fjs);
			})(document, 'script', 'facebook-jssdk');
		}),
};

export default AuthService;
