import { constants, texts } from '@app';
import ExpandableVisualModal from '@molecules/ExpandableVisualModal';
import { UploadedFile } from '@types';
import { ReactNode, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

type VisualsHandlerProps = {
	acceptedTypes?: string;
	acceptedTypesText?: string;
	className?: string;
	containerClassName?: string;
	editable?: boolean;
	expandableWhenDisabled?: boolean;
	footer?: ReactNode;
	maxAmount?: number;
	mediaUri?: string;
	onMediaDeleted?: () => void;
	onMediaSaved?: (result: UploadedFile[]) => void;
	previewUri?: string;
	title?: string;
	visualName?: string;
};

const VisualsHandler = ({
	acceptedTypes = 'image/*',
	acceptedTypesText = `SVG, PNG, JPG ${texts.or} GIF`,
	className = '',
	containerClassName = 'flex items-center justify-center w-full mt-8',
	editable = true,
	expandableWhenDisabled = false,
	footer = null,
	maxAmount = 1,
	mediaUri,
	onMediaDeleted,
	onMediaSaved,
	previewUri,
	title,
	visualName,
}: VisualsHandlerProps) => {
	const visualUri =
		mediaUri ??
		previewUri ??
		`${process.env.REACT_APP_BACKEND_URL}/media/images/image-not-found.png`;

	const renderDeleteVisualButton = useMemo(
		() =>
			mediaUri &&
			onMediaDeleted && (
				<button
					type="button"
					onClick={(event) => {
						event.stopPropagation();
						onMediaDeleted();
					}}>
					<img
						alt={texts.delete}
						src={`${process.env.REACT_APP_BACKEND_URL}/media/images/trash.png`}
						className="absolute top-1 right-1 cursor-pointer"
					/>
				</button>
			),
		[mediaUri, onMediaDeleted]
	);

	return (
		<>
			{title && (
				<span className="text-lg sm:text-xl font-black flex justify-center">
					{title}
				</span>
			)}
			<div className="relative">
				{editable && onMediaSaved ? (
					<Dropzone
						accept={{
							[acceptedTypes]: [],
						}}
						onDrop={(acceptedFiles, fileRejections) => {
							if (fileRejections?.length > 0)
								toast.error(texts.filesRejectedBecauseOfType);
							if (!acceptedFiles?.[0]) return;

							if (
								acceptedFiles.some(
									(image) => image.size / 1024 > constants.MAX_COVER_IMAGE_SIZE
								)
							) {
								toast.error(texts.maxImageSizeExceeded);
								return;
							}

							if (maxAmount && acceptedFiles.length > maxAmount) {
								toast.error(texts.moreVisualsThanMaximum(maxAmount));
								return;
							}

							const uploadedFiles: UploadedFile[] = acceptedFiles.map(
								(acceptedFile) => ({
									file: acceptedFile,
									mediaUri: URL.createObjectURL(acceptedFile),
								})
							);
							onMediaSaved(uploadedFiles);
						}}>
						{({ getRootProps, getInputProps }) => (
							<div className={containerClassName} {...getRootProps()}>
								{mediaUri || previewUri ? (
									<label htmlFor="id-input-file" className="w-full">
										<img
											alt={texts.visual}
											src={visualUri}
											className={className}
										/>
										{renderDeleteVisualButton}
									</label>
								) : (
									<label
										htmlFor="id-input-file"
										className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
										<div className="flex flex-col items-center justify-center pt-5 pb-6">
											{visualName && (
												<p className="mb-2 text-lg text-black dark:text-gray-400">
													{visualName}
												</p>
											)}
											<svg
												aria-hidden="true"
												className="w-10 h-10 mb-3 text-gray-400"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
												/>
											</svg>
											<p className="mb-2 text-sm text-gray-500 dark:text-gray-400 mx-2 text-center">
												<span className="font-semibold">
													{texts.uploadVisual}
												</span>
												{` ${texts.orDragFilesHere.toLowerCase()}`}
											</p>

											{acceptedTypesText && (
												<p className="text-xs text-gray-500 dark:text-gray-400">
													{acceptedTypesText}
												</p>
											)}
											<p className="text-xs text-gray-500 dark:text-gray-400">
												{texts.maxImageSize(
													constants.MAX_COVER_IMAGE_SIZE / 1024
												)}
											</p>
										</div>
									</label>
								)}
								<input
									className="hidden"
									{...getInputProps({
										accept: acceptedTypes,
										multiple: true,
									})}
								/>
							</div>
						)}
					</Dropzone>
				) : (
					<>
						{expandableWhenDisabled ? (
							<ExpandableVisualModal
								visualUri={visualUri}
								className={className}
								containerClassName={containerClassName}
							/>
						) : (
							<img alt={texts.visual} src={visualUri} className={className} />
						)}
						{renderDeleteVisualButton}
					</>
				)}

				{footer}
			</div>
		</>
	);
};

export default VisualsHandler;
