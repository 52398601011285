import { signOutMTAction } from '@actions/UserActions';
import { texts } from '@app';
import Modal from '@atoms/Modal';
import ConfirmActionModalWithButton from '@organisms/ConfirmActionModalWithButton';
import { setIsLoading, setIsNotLoading } from '@reducers/GenericReducer';
import { Result } from '@types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';

const SignOutModal = () => {
	const [isSigningOut, setIsSigningOut] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSignOutMT = useCallback(() => {
		setIsSigningOut(false);
		dispatch(setIsLoading());
		dispatch(
			signOutMTAction((result: Result) => {
				dispatch(setIsNotLoading());
				if (!result.success) {
					toast.error(result.message ?? texts.errorGeneric());
					return;
				}
				toast.success(texts.signOutSuccesfully);
				navigate(`/`);
			}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);
	}, [dispatch, navigate]);

	const renderSignOutModal = useMemo(
		() => (
			<Modal
				title={texts.signOutMT}
				titleClasses="flex justify-center font-semibold text-red-700 text-center text-2xl mt-2 "
				cancelButtonText={texts.close}
				cancelButtonAction={() => setIsSigningOut(false)}
				startOpened
				content={
					<div className="w-full mx-auto">
						<p className="justify-center text-center text-row-2 text-xl m-4   sm:text-2xl">
							{texts.warningYouAreNextToSignOutMT}
						</p>
						<p className="justify-center text-center text-row-2 text-xl m-4    sm:text-2xl">
							{texts.ifYouSignOutDontLoginAgain}
						</p>
						<div className="justify-center flex">
							<div className="flex justify-between w-full    sm:w-3/4    lg:w-1/2">
								<ConfirmActionModalWithButton
									onConfirm={handleSignOutMT}
									triggerButtonClassName="button-sign-out justify-center text-xs m-2 px-2 mt-2    xs:text-sm    sm:text-base sm:w-auto    2xl:text-xl"
									buttonText={texts.signOut}
									modalTitle={texts.signOutMT}
									modalQuestion={texts.ifYouSignOutDontLoginAgain}
									textToConfirmAction={texts.ok}
								/>
								<button
									type="button"
									className="button-1  text-xs m-2 px-2   xs:text-sm     sm:text-base 2xl:text-xl mt-2 sm:w-auto justify-center"
									onClick={() => {
										setIsSigningOut(false);
									}}>
									{texts.continuousInMT}
								</button>
							</div>
						</div>
					</div>
				}
			/>
		),
		[handleSignOutMT]
	);

	return (
		<div className="flex justify-center">
			<button
				type="button"
				className="button-sign-out  text-xs m-2 px-2   xs:text-sm     sm:text-base 2xl:text-xl mt-2 sm:w-auto justify-center"
				onClick={() => {
					setIsSigningOut(true);
				}}>
				{texts.signOut}
			</button>
			{isSigningOut && renderSignOutModal}
		</div>
	);
};

export default SignOutModal;
