import { constraints } from '@app';

import { isInProduction } from './GenericActions';

export const isValidEmail = (email: string) =>
	String(email)
		.toLowerCase()
		.match(
			isInProduction()
				? /^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/ // Not allowing ñ or á...
				: /[a-zA-Z0-9._-]/
		);

export const isValidNickname = (nickname: string) => {
	const re = new RegExp(
		`^[-_.a-z0-9 áéíóúñ]{${constraints.USER_NICKNAME_MIN_LENGTH},${constraints.USER_NICKNAME_MAX_LENGTH}}$`,
		'i'
	);
	return String(nickname).toLowerCase().match(re);
};

export const isValidTournamentName = (name: string) =>
	String(name)
		.toLowerCase()
		.match(/^[ -_.a-z0-9]{4,}$/);

export const isValidPassword = (password: string) =>
	String(password)
		.toLowerCase()
		.match(/^.{4,}$/);
