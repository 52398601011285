import { getLiveMatchesAction } from '@actions/MatchActions';
import { constants, texts, uris } from '@app';
import Modal from '@atoms/Modal';
import useInterval from '@hooks/useInterval';
import { EnumMatchStatuses, EnumSports, Match } from '@types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';

dayjs.extend(utc);
dayjs.extend(timezone);

type InCourseMatchesButtonProps = {
	startOpened?: boolean;
	className?: string;
};

const InCourseMatchesButton = ({
	startOpened = false,
	className,
}: InCourseMatchesButtonProps) => {
	const [inCourseMatches, setInCourseMatches] = useState<Match[]>([]);
	const [upcomingMatches, setUpcomingMatches] = useState<Match[]>([]);
	const [todayMatches, setTodayMatches] = useState<Match[]>([]);
	const [isShowingLiveMatches, setIsShowingLiveMatches] = useState(startOpened);
	const dispatch = useDispatch();

	const getLiveMatches = useCallback(
		(callback?: () => void) => {
			dispatch(
				getLiveMatchesAction(
					dayjs().tz(dayjs.tz.guess()).utcOffset(),
					(result) => {
						if (
							!result ||
							!result.success ||
							!result.matchesInCourse ||
							!result.matchesUpcoming
						) {
							toast.error(result.message ?? texts.errorGeneric());
							return;
						}

						setInCourseMatches(result.matchesInCourse);
						const halfHourInMs = 30 * 60 * 1000;
						const now = dayjs.utc();
						const minDate = now.subtract(halfHourInMs, 'millisecond');
						const maxDate = now.add(halfHourInMs, 'millisecond');

						const newUpcomingMatches: Match[] = [];
						const newTodayMatches: Match[] = [];
						result.matchesUpcoming.forEach((match) => {
							if (match.startDatetime) {
								const matchDate = dayjs(match.startDatetime);
								if (matchDate.isAfter(minDate) && matchDate.isBefore(maxDate)) {
									newUpcomingMatches.push(match);
								} else {
									newTodayMatches.push(match);
								}
							}
						});
						setUpcomingMatches(newUpcomingMatches);
						newTodayMatches.sort((a, b) => {
							if (a.sport !== b.sport) {
								return a.sport.localeCompare(b.sport);
							}
							if (!a.startDatetime || !b.startDatetime) {
								return 0;
							}
							return (
								new Date(a.startDatetime).getTime() -
								new Date(b.startDatetime).getTime()
							);
						});
						setTodayMatches(newTodayMatches);

						if (callback) callback();
					}
				) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
			);
		},
		[dispatch]
	);

	useInterval(() => {
		if (isShowingLiveMatches) getLiveMatches();
	}, constants.RELOAD_IN_COURSE_MATCH_MODAL);

	useEffect(() => {
		getLiveMatches();
		// eslint-disable-next-line
	}, []);

	const renderTodayMatches = useMemo(
		() => (
			<>
				<div className="flex justify-center text-center text-2xl mt-2">
					{texts.nextTodayMatches}
				</div>

				{todayMatches.map((match, index) => (
					<div
						key={index}
						className="relative color-bg-match justify-between items-center p-1 m-2 rounded-sm shadow-sm">
						<a
							href={`/${uris.viewMatch(match.sport)}?${uris.paramMatchId}=${
								match.id
							}`}>
							<p className="absolute top-2 p-1 left-4 cursor-pointer inline-block text-xs rounded-lg bg-white">
								{texts.sportName(match.sport)}
							</p>

							<div className="flex items-center text-center justify-center mt-8    xs:mt-0">
								<p className="overflow-ellipsis overflow-hidden    sm:text-lg    xl:text-xl">
									{match.tournamentName ? `${match.tournamentName} - ` : ''}
									{match.stageName ?? texts.friendlyMatch}
								</p>
							</div>
							<div className="flex items-center text-center justify-center">
								<p className="overflow-ellipsis overflow-hidden text-blue-900    sm:text-lg    xl:text-xl">
									{match.placeName
										? `${match.placeName} - ${match.fieldName}`
										: texts.withoutPlace}
								</p>
							</div>
							<div className="grid grid-cols-12 place-items-center w-full cursor-pointer">
								<div className="flex flex-col w-full  text-center col-span-4    md:flex-row md:col-span-5">
									<div className="flex my-auto mr-2 justify-center w-full    md:w-10">
										<img
											className="object-cover rounded-full border-2 border-indigo-500 bg-white w-10 h-10"
											src={`${process.env.REACT_APP_BACKEND_URL}/${
												match.local.teamImageUri ??
												'/media/images/team-icon.png'
											}`}
											alt={texts.team}
										/>
									</div>
									<div className="my-auto w-20    2xs:w-28    xs:w-36    sm:w-44    lg:w-64">
										<p className="overflow-ellipsis overflow-hidden    sm:text-base    xl:text-xl">
											{match.local.name}
										</p>
									</div>
								</div>
								<div className="justify-center font-bold text-base text-center col-span-4    md:col-span-2    lg:text-lg">
									{match.startDatetime}
								</div>
								<div className="text-center col-span-4    md:col-span-5">
									<div className="flex flex-col w-full    md:flex-row">
										<div className="flex my-auto mr-2 justify-center w-full    md:w-10">
											<img
												className="object-cover rounded-full border-2 border-indigo-500 bg-white w-10 h-10"
												src={`${process.env.REACT_APP_BACKEND_URL}/${
													match.visitor.teamImageUri ??
													'/media/images/team-icon.png'
												}`}
												alt={texts.team}
											/>
										</div>
										<div className="my-auto w-20    2xs:w-28    xs:w-36    sm:w-44    lg:w-64">
											<p className="overflow-ellipsis overflow-hidden    sm:text-base    xl:text-xl">
												{match.visitor.name}
											</p>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				))}
			</>
		),
		[todayMatches]
	);

	const renderInCourseMatches = useMemo(
		() => (
			<>
				<div className="flex justify-center text-center text-2xl mt-2">
					{texts.inCourseMatches}
				</div>
				{inCourseMatches.concat(upcomingMatches).map((match, index) => (
					<div key={index}>
						<div className="color-bg-match justify-between items-center p-1 m-2 rounded-sm shadow-sm">
							<a
								href={`/${uris.viewMatch(match.sport)}?${uris.paramMatchId}=${
									match.id
								}`}>
								<div className="flex items-center text-center justify-center">
									<p className="overflow-ellipsis overflow-hidden    sm:text-lg    xl:text-xl">
										{match.tournamentName ? `${match.tournamentName} - ` : ''}
										{match.stageName ?? texts.friendlyMatch}
									</p>
								</div>
								<div className="flex items-center text-center justify-center">
									<p className="overflow-ellipsis overflow-hidden text-blue-900    sm:text-lg    xl:text-xl">
										{match.placeName
											? `${match.placeName} - ${match.fieldName}`
											: texts.withoutPlace}
									</p>
								</div>
								<div className="grid grid-cols-12 place-items-center w-full cursor-pointer">
									<div className="text-center col-span-4    md:col-span-5">
										<div className="flex flex-col w-full    md:flex-row">
											<div className="flex my-auto mr-2 justify-center w-full    md:w-10">
												<img
													className="object-cover rounded-full border-2 border-indigo-500 bg-white w-10 h-10"
													src={`${process.env.REACT_APP_BACKEND_URL}/${
														match.local.teamImageUri ??
														'/media/images/team-icon.png'
													}`}
													alt={texts.team}
												/>
											</div>
											<div className="my-auto">
												<p className="overflow-ellipsis overflow-hidden    sm:text-base    xl:text-xl">
													{match.local.name}
												</p>
											</div>
										</div>
									</div>
									<div className="text-center col-span-4 md:col-span-2">
										{match.status === EnumMatchStatuses.IN_COURSE &&
										match.scores ? (
											<div className="grid grid-cols-5 justify-between place-items-center w-full">
												<div className="col-span-2 text-center    md:grid md:grid-cols-2">
													<p className="justify-center text-row-2 font-bold text-lg    md:text-xl    lg:text-2xl">
														{match.scores.local}
													</p>
													<p className="justify-center text-row-2 font-bold text-sm    md:text-base">
														{' '}
														{match.sport === EnumSports.volleyball &&
															` (${match.scores?.setsWonByLocal})`}
													</p>
												</div>
												<div className="justify-center text-center col-span-1">
													<div className="text-row-2    sm:text-xl    xl:text-2xl">
														-
													</div>
												</div>
												<div className="col-span-2 text-center    md:grid md:grid-cols-2">
													<p className="justify-center text-row-2 font-bold text-lg    md:text-xl    lg:text-2xl">
														{match.scores.visitor}
													</p>
													<p className="justify-center text-row-2 font-bold text-sm    md:text-base">
														{' '}
														{match.sport === EnumSports.volleyball &&
															match.scores?.setsWonByVisitor &&
															` (${match.scores.setsWonByVisitor})`}
													</p>
												</div>
											</div>
										) : (
											match.status === EnumMatchStatuses.PUBLISHED && (
												<div className="justify-center font-bold text-base    lg:text-lg">
													{match.startDatetime}
												</div>
											)
										)}
									</div>
									<div className="text-center col-span-4    md:col-span-5">
										<div className="flex flex-col w-full    md:flex-row-reverse">
											<div className="flex my-auto ml-2 justify-center w-full    md:w-10">
												<img
													className="object-cover rounded-full border-2 border-indigo-500 bg-white w-10 h-10"
													src={`${process.env.REACT_APP_BACKEND_URL}/${
														match.visitor.teamImageUri ??
														'/media/images/team-icon.png'
													}`}
													alt={texts.team}
												/>
											</div>
											<div className="my-auto">
												<p className="overflow-ellipsis overflow-hidden    sm:text-base    xl:text-xl">
													{match.visitor.name}
												</p>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				))}
			</>
		),
		[inCourseMatches, upcomingMatches]
	);

	const renderShowMatches = useMemo(
		() => (
			<>
				{!!(inCourseMatches.length + upcomingMatches.length) &&
					renderInCourseMatches}
				{!!todayMatches.length && renderTodayMatches}
			</>
		),
		[
			inCourseMatches.length,
			renderInCourseMatches,
			renderTodayMatches,
			todayMatches.length,
			upcomingMatches.length,
		]
	);

	const renderNoInCourseMatches = useMemo(
		() => (
			<div className="flex flex-col items-center">
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/media/images/mix-sportist.png`}
					alt={texts.viewProfile}
				/>
				<p className="text-center font-bold text-2xl">
					{texts.notFindMatchesInCourse}
				</p>
			</div>
		),
		[]
	);

	const renderModal = useMemo(
		() => (
			<Modal
				content={
					inCourseMatches.length +
						upcomingMatches.length +
						todayMatches.length >
					0
						? renderShowMatches
						: renderNoInCourseMatches
				}
				cancelButtonText={texts.close}
				cancelButtonAction={() => {
					setIsShowingLiveMatches(false);
				}}
				startOpened
			/>
		),

		[
			inCourseMatches.length,
			upcomingMatches.length,
			todayMatches.length,
			renderNoInCourseMatches,
			renderShowMatches,
		]
	);

	return (
		<>
			<button
				type="button"
				className={className}
				onClick={() =>
					getLiveMatches(() => {
						setIsShowingLiveMatches(true);
					})
				}>
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/media/images/in-course-matches.png`}
					alt={texts.inCourseMatches}
					className="mx-auto"
				/>
			</button>
			{isShowingLiveMatches && renderModal}
		</>
	);
};

export default InCourseMatchesButton;
