import { texts } from '@app';
import InCourseMatchesModal from '@molecules/InCourseMatchesModal';
import { useEffect, useState } from 'react';

type QuickAccessInCourseMatchesIconProps = {
	startOpened?: boolean;
};

const QuickAccessInCourseMatchesIcon = ({
	startOpened = false,
}: QuickAccessInCourseMatchesIconProps) => {
	const [hideContent, setHideContent] = useState(false);

	useEffect(() => {
		const handleScroll = () => setHideContent(window.scrollY > 10);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className={`z-10  fixed left-5 bottom-5 items-center justify-center ${
				hideContent ? 'hidden' : 'flex'
			}`}>
			<div className="bg-white rounded-lg flex flex-col items-center justify-center ">
				<InCourseMatchesModal startOpened={startOpened} className="w-24 h-12" />
				<span className="text-sm bg-white rounded-lg">
					{texts.inCourseMatches}
				</span>
			</div>
		</div>
	);
};

export default QuickAccessInCourseMatchesIcon;
