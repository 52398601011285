import { texts } from '@app';
import Modal from '@atoms/Modal';
import { TextField } from '@mui/material';
import { useState } from 'react';

type ConfirmActionModalProps = {
	title: string;
	question?: string;
	imgUri?: string;
	okButtonText?: string;
	cancelButtonText?: string;
	okButtonAction: React.Dispatch<React.SetStateAction<() => void>>;
	setOkButtonAction?: React.Dispatch<
		React.SetStateAction<
			React.Dispatch<React.SetStateAction<() => void>> | undefined
		>
	>;
	textToConfirmAction?: string; // When an action needs special attention, use this to force the user to input a text before confirming.
};

const ConfirmActionModal = ({
	cancelButtonText = texts.no,
	imgUri = `${process.env.REACT_APP_BACKEND_URL}/media/images/question.png`,
	okButtonAction,
	okButtonText = texts.yes,
	question = texts.saveChangesQuestion,
	setOkButtonAction,
	title,
	textToConfirmAction,
}: ConfirmActionModalProps) => {
	const [okButtonDisabled, setOkButtonDisabled] = useState(
		!!textToConfirmAction
	);
	return (
		<Modal
			title={title}
			startOpened
			cancelButtonText={cancelButtonText}
			okButtonText={okButtonText}
			cancelButtonAction={setOkButtonAction}
			okButtonAction={okButtonAction}
			okButtonDisabled={okButtonDisabled}>
			<div className="p-1 flex w-full">
				<img className="w-12 h-12 flex my-2 mx-2" src={imgUri} alt="Question" />
				<span className="m-auto flex    xs:text-lg    sm:text-xl    md:w-3/4 md:text-2xl">
					{question}
				</span>
			</div>
			{textToConfirmAction && (
				<div className="flex mt-1 w-full justify-center">
					<TextField
						id="confirmActionText"
						className="w-96"
						type="text"
						variant="outlined"
						label={texts.enterTextToConfirm(textToConfirmAction)}
						onChange={(e) =>
							setOkButtonDisabled(
								e.target.value.toLocaleLowerCase() !==
									textToConfirmAction.toLocaleLowerCase()
							)
						}
						placeholder={texts.enterTextToConfirm(textToConfirmAction)}
					/>
				</div>
			)}
		</Modal>
	);
};
export default ConfirmActionModal;
