import { CircularProgress } from '@mui/material';

const Loader = () => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '80vh',
		}}>
		<CircularProgress style={{ color: '#000037' }} size={100} />
	</div>
);

export default Loader;
