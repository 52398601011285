import { convertToBackend } from '@actions/ConvertActions';
import { deleteRequest, getRequest, patchRequest, postRequest } from '@app';
import { EnumEntityTypes, EnumSports, EnumUserTypes } from '@types';

const getRequestBodyForFollowService = (
	entityId: number,
	entityType: EnumEntityTypes,
	follow: boolean,
	secondaryEntityId: number | undefined
) => {
	switch (entityType) {
		case EnumEntityTypes.team:
			return { entityType, follow, teamId: entityId };
		case EnumEntityTypes.match:
			return { entityType, follow, matchId: entityId };
		case EnumEntityTypes.tournament:
			return {
				entityType,
				follow,
				tournamentId: entityId,
			};
		case EnumEntityTypes.league:
		case EnumEntityTypes.playoff:
			return {
				entityType: 'stage',
				follow,
				stageNumber: entityId,
				tournamentId: secondaryEntityId,
			};
		default:
			return null;
	}
};

const UserService = {
	getProfilePartService: async (userId: number, profilePartToLoad: string) =>
		getRequest(
			'get_profile_part.php',
			convertToBackend({ userId, profilePartToLoad }) as Record<string, string>
		),
	createInactiveUserService: async (
		email: string,
		userType: EnumUserTypes,
		sport: EnumSports
	) =>
		postRequest(
			'create_inactive_user.php',
			convertToBackend({ email, userType, sport }) as Record<string, string>
		),
	getInactiveUserInfoService: async (token: string) =>
		getRequest(
			'get_inactive_user_info.php',
			convertToBackend({
				token,
			}) as Record<string, string>
		),
	completeUserRegistrationService: async (
		userId: number,
		nickname: string,
		token: string,
		password: string
	) =>
		postRequest(
			'complete_user_registration.php',
			convertToBackend({
				userId,
				nickname,
				token,
				password,
			}) as Record<string, string>
		),
	completeUserConvertionService: async (
		userId: number,
		nickname: string,
		token: string,
		password: string
	) =>
		postRequest(
			'complete_user_convertion.php',
			convertToBackend({
				userId,
				nickname,
				token,
				password,
			}) as Record<string, string>
		),
	updatePasswordService: async (password: string) =>
		patchRequest(
			'update_password.php',
			convertToBackend({
				password,
			}) as Record<string, string>
		),
	restorePasswordService: async (email: string) =>
		postRequest(
			'restore_password.php',
			convertToBackend({
				email,
			}) as Record<string, string>
		),
	reSendEmailValidationService: async (email: string) =>
		postRequest(
			'resend_complete_register_mail.php',
			convertToBackend({
				email,
			}) as Record<string, string>
		),
	completePasswordRestoringService: async (
		password: string,
		email: string,
		token: string
	) =>
		postRequest(
			'complete_password_restoring.php',
			convertToBackend({
				password,
				email,
				token,
			}) as Record<string, string>
		),
	setProfilePhotoService: async (
		userId: number,
		profilePhotoId: number | undefined
	) => {
		const response = await patchRequest(
			'set_profile_photo.php',
			convertToBackend({ userId, profilePhotoId }) as Record<string, string>
		);
		return response;
	},
	updateProfileFieldService: async (
		userId: number,
		fieldName: string,
		newValue: string | number | null
	) => {
		const params = {
			userId,
			[fieldName]: newValue,
		};
		const response = await patchRequest(
			'update_profile_field.php',
			convertToBackend(params) as Record<string, string>
		);
		return response;
	},
	answerRefereeParticipationService: async (
		matchId: number,
		isConfirmation: boolean,
		notificationId: number | null
	) => {
		const response = await postRequest(
			'answer_referee_participation.php',
			convertToBackend({ matchId, isConfirmation, notificationId }) as Record<
				string,
				string
			>
		);
		return response;
	},
	answerMemberParticipationService: async (
		matchId: number,
		isConfirmation: boolean,
		notificationId: number | null
	) => {
		const response = await postRequest(
			'answer_member_participation.php',
			convertToBackend({ matchId, isConfirmation, notificationId }) as Record<
				string,
				string
			>
		);
		return response;
	},
	answerFriendlyInvitationService: async (
		matchId: number,
		isConfirmation: boolean,
		notificationId: number | null
	) => {
		const response = await postRequest(
			'answer_friendly_participation.php',
			convertToBackend({ matchId, isConfirmation, notificationId }) as Record<
				string,
				string
			>
		);
		return response;
	},
	getNotificationsService: () => getRequest('get_notifications.php'),

	changeNotificationReadPropertyService: async (
		notificationId: number,
		markAsRead: boolean
	) => {
		const response = await postRequest(
			'change_notifications_read_property.php',
			convertToBackend({
				notifications: [notificationId],
				markAsRead,
			}) as Record<string, string>
		);
		return response;
	},
	changeNotificationsReadPropertyService: async (
		notifications: number[],
		markAsRead: boolean
	) => {
		const response = await postRequest(
			'change_notifications_read_property.php',
			convertToBackend({ notifications, markAsRead }) as Record<string, string>
		);
		return response;
	},
	deleteNoUserService: async (userId: number) => {
		const response = await deleteRequest(
			'delete_no_user.php',
			convertToBackend({ userId }) as Record<string, string>
		);
		return response;
	},
	signOutMTService: async () => {
		const response = await deleteRequest('sign_out.php');
		return response;
	},
	convertToUserService: async (noUserId: number, email: string) => {
		const response = await patchRequest(
			'convert_to_user.php',
			convertToBackend({ noUserId, email }) as Record<string, string>
		);
		return response;
	},

	deletePhotoService: async (photoId: number) => {
		const response = await deleteRequest(
			'delete_user_image.php',
			convertToBackend({ photoId }) as Record<string, string>
		);
		return response;
	},

	followEntityService: async (
		entityId: number,
		entityType: EnumEntityTypes,
		follow: boolean,
		secondaryEntityId: number | undefined
	) => {
		const requestBody = getRequestBodyForFollowService(
			entityId,
			entityType,
			follow,
			secondaryEntityId
		);

		return postRequest(
			'follow_entity.php',
			convertToBackend(requestBody) as Record<string, string>
		);
	},

	followEntityAsSpectatorService: async (
		email: string,
		entityId: number,
		entityType: EnumEntityTypes,
		follow: boolean,
		secondEntityId: number | undefined
	) => {
		const requestBody = getRequestBodyForFollowService(
			entityId,
			entityType,
			follow,
			secondEntityId
		);

		return postRequest(
			'follow_entity_as_spectator.php',
			convertToBackend({ ...requestBody, email }) as Record<string, string>
		);
	},
};

export default UserService;
