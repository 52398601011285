import { texts } from '@app';
import { BreadcrumbCategory, EnumBreadcrumbCategories } from '@types';
import { useCallback, useEffect, useMemo, useState } from 'react';

type PathElement = {
	text?: string;
	category?: string;
	type: BreadcrumbCategory;
	uriOnClick?: string;
};
type BreadcrumbProps = {
	path: PathElement[];
};
const Breadcrumb = ({ path }: BreadcrumbProps) => {
	const [maxCharsPerPathElement, setMaxCharsPerPathElement] = useState(0);

	const handleResize = useCallback(() => {
		if (window.innerWidth >= 1280) {
			setMaxCharsPerPathElement(30);
			return;
		}

		if (window.innerWidth >= 768) {
			setMaxCharsPerPathElement(25);
			return;
		}

		if (window.innerWidth >= 475) {
			setMaxCharsPerPathElement(20);
			return;
		}

		if (window.innerWidth >= 400) {
			setMaxCharsPerPathElement(15);
			return;
		}
		setMaxCharsPerPathElement(11);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize, false);
		handleResize();
		// eslint-disable-next-line
	}, []);

	const renderContent = useMemo(() => {
		const breadcrumbTextClassName =
			'text-breadcrumb text-xs    sm:text-base    md:text-lg    lg:text-xl';

		return (
			<div className="w-full py-1 mx-auto flex justify-start   md:w-4/5    2xl:w-3/5">
				{path.map((pathElement, index) => {
					const pathElementText =
						pathElement.text &&
						pathElement.text?.length > maxCharsPerPathElement
							? `${pathElement.text.substring(
									0,
									maxCharsPerPathElement - 3
							  )}...`
							: pathElement.text;
					return (
						<div className="flex" key={index}>
							<div className="mr-1    md:mr-2">
								<div className="text-breadcrumb-category text-xs    sm:text-sm    md:text-base">
									{pathElement.category ?? texts.activity}
								</div>
								{pathElement.type === EnumBreadcrumbCategories.container ? (
									pathElement.uriOnClick !==
									window.location.pathname + window.location.search ? (
										<a
											href={pathElement.uriOnClick}
											className={`${breadcrumbTextClassName} hover:bg-gray-300`}
											title={pathElement.text}>
											{pathElementText}
										</a>
									) : (
										<span
											className={breadcrumbTextClassName}
											title={pathElement.text}>
											{pathElementText}
										</span>
									)
								) : (
									<div
										className={breadcrumbTextClassName}
										title={pathElement.text}>
										{pathElementText}
									</div>
								)}
							</div>
							{index < (path?.length ?? 0) - 1 && (
								<span className="mx-1 my-auto    sm:text-lg sm:mx-2    md:text-2xl md:mx-3    lg:text-3xl">
									&gt;
								</span>
							)}
						</div>
					);
				})}
			</div>
		);
	}, [path, maxCharsPerPathElement]);

	return renderContent;
};

export default Breadcrumb;
