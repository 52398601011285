import { texts, useAppSelector } from '@app';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Breakpoint } from '@mui/system';
import { isLoadingSelector } from '@selectors/GenericSelector';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import Loader from './Loader';

type ModalProps = {
	triggerContent?: ReactNode;
	content?: ReactNode;
	children?: ReactNode;

	maxWidthClass?: Breakpoint | undefined;

	triggerButtonClass?: string;
	title?: string;
	titleClasses?: string;
	okButtonText?: string;
	cancelButtonText: string;

	okButtonAction?: React.Dispatch<React.SetStateAction<() => void>>;

	cancelButtonAction?:
		| React.Dispatch<
				React.SetStateAction<
					React.Dispatch<React.SetStateAction<() => void>> | undefined
				>
		  >
		| undefined;

	onOpenControl?: () => boolean;
	controlNotPassedCallback?: () => void;

	startOpened?: boolean;
	closeAfterOk?: boolean;
	closeAfterCancel?: boolean;
	okWithEnterKeyPressing?: boolean;
	preventClosingByClickingOutside?: boolean;

	okButtonDisabled?: boolean;
};

const Modal = ({
	content,
	children,
	triggerContent = null,

	maxWidthClass = 'md',
	triggerButtonClass = 'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm align-middle',
	title,
	titleClasses = 'flex justify-center text-center text-2xl mt-2',
	okButtonText,
	cancelButtonText,

	okButtonAction,
	cancelButtonAction,
	onOpenControl = () => true,
	controlNotPassedCallback,

	startOpened = false,
	closeAfterOk = true,
	closeAfterCancel = true,
	okWithEnterKeyPressing = false,
	preventClosingByClickingOutside = false,

	okButtonDisabled,
}: ModalProps) => {
	const [open, setOpen] = useState(startOpened);
	const modal = useRef(null);
	const isLoading = useAppSelector(isLoadingSelector);

	const auxHandleClose = useCallback(
		(checkKeyDownFunction: (event: KeyboardEvent) => void) => {
			if (!closeAfterCancel && cancelButtonAction) {
				cancelButtonAction(undefined);
				return;
			}
			document.removeEventListener('keydown', checkKeyDownFunction);
			setOpen(false);
			if (cancelButtonAction) cancelButtonAction(undefined);
		},
		[cancelButtonAction, closeAfterCancel]
	);

	const auxHandleConfirm = useCallback(
		(checkKeyDownFunction: (event: KeyboardEvent) => void) => {
			if (okButtonAction) okButtonAction(() => undefined);
			if (closeAfterOk) auxHandleClose(checkKeyDownFunction);
		},
		[closeAfterOk, okButtonAction, auxHandleClose]
	);

	const checkKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (
				event.keyCode !== 16 &&
				event.keyCode !== 17 &&
				event.keyCode !== 18 &&
				event.keyCode !== 37 &&
				event.keyCode !== 38 &&
				event.keyCode !== 39 &&
				event.keyCode !== 40
			) {
				document.removeEventListener('keydown', checkKeyDown);
			}

			if (event.key === 'Escape') {
				auxHandleClose(checkKeyDown);
			}
			if (okWithEnterKeyPressing && event.key === 'Enter') {
				event.preventDefault();
				auxHandleConfirm(checkKeyDown);
			}
		},
		[auxHandleClose, auxHandleConfirm, okWithEnterKeyPressing]
	);

	const handleConfirm = useCallback(() => {
		auxHandleConfirm(checkKeyDown);
	}, [auxHandleConfirm, checkKeyDown]);

	const handleClose = useCallback(
		(event: object, reason?: string) => {
			if (reason === 'backdropClick' && preventClosingByClickingOutside) {
				return;
			}
			auxHandleClose(checkKeyDown);
		},
		[auxHandleClose, checkKeyDown, preventClosingByClickingOutside]
	);

	useEffect(() => {
		setOpen(startOpened);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', checkKeyDown);
	}, [checkKeyDown]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Dialog
				ref={modal}
				open={open}
				onClose={handleClose}
				aria-describedby="alert-dialog-description"
				fullScreen={fullScreen}
				fullWidth
				maxWidth={maxWidthClass}>
				{title && <h1 className={titleClasses}>{title}</h1>}
				<DialogContent>
					{isLoading && <Loader />}
					<div className={isLoading ? 'hidden' : ''}>{content || children}</div>
				</DialogContent>
				<DialogActions>
					{okButtonText && (
						<button
							title={okButtonText}
							type="button"
							className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white    hover:bg-red-700    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500    sm:ml-3 sm:w-auto sm:text-sm    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-red-300"
							onClick={handleConfirm}
							disabled={okButtonDisabled}>
							{okButtonText}
						</button>
					)}
					<button
						type="button"
						className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
						onClick={handleClose}>
						{cancelButtonText}
					</button>
				</DialogActions>
			</Dialog>
			{!startOpened && (
				<button
					title={okButtonText ?? texts.confirm}
					type="button"
					className={triggerButtonClass}
					onClick={() => {
						const controlPassed = onOpenControl();
						if (controlPassed) setOpen(true);
						else if (controlNotPassedCallback) controlNotPassedCallback();
					}}>
					{triggerContent}
				</button>
			)}
		</>
	);
};

export default Modal;
