import { isFootball } from '@actions/SportActions';
import { constants, texts, uris } from '@app';
import CustomCarousel from '@atoms/CustomCarousel';
import EmptyState from '@atoms/EmptyState';
import Modal from '@atoms/Modal';
import NewsCard from '@atoms/NewsCard';
import useLoggedUser from '@hooks/useLoggedUser';
import HorizontalNews from '@templates/HorizontalNews';
import SignOutModal from '@templates/SignOutModal';
import VerticalNews from '@templates/VerticalNews';
import { EnumSports, LoggedUser, News, Team, User } from '@types';
import { useMemo, useState } from 'react';

const getUrls = (loggedUser: User | LoggedUser) => {
	const { teamUrl, playersUrl, refereesUrl } = loggedUser.isAdmin
		? {
				teamUrl: uris.adminTeams,
				playersUrl: uris.adminPlayers,
				refereesUrl: uris.adminReferees,
		  }
		: loggedUser.isTournamentManager
		? {
				teamUrl: uris.managerTeams,
				playersUrl: uris.managerPlayers,
				refereesUrl: uris.managerReferees,
		  }
		: loggedUser.isCoach
		? {
				teamUrl: undefined,
				playersUrl: uris.coachPlayers,
				refereesUrl: undefined,
		  }
		: {
				teamUrl: undefined,
				playersUrl: undefined,
				refereesUrl: undefined,
		  };

	return {
		teamUrl,
		playersUrl,
		refereesUrl,
		fieldsUrl: uris.fields,
		upcomingStagesUrl: uris.listStages,
		inCourseStagesUrl: uris.listStages,
	};
};

type MainContentProps = {
	news?: News[];
	showCreateTournament?: boolean;
	showFields?: boolean;
	showInProgress?: boolean;
	showMyTournaments?: boolean;
	showNews?: boolean;
	showManageNews?: boolean;
	showNextStages?: boolean;
	showPlayers?: boolean;
	showReferees?: boolean;
	showTeams?: boolean;
	showUserTeams?: boolean;
};

const MainContent = ({
	news,
	showCreateTournament,
	showFields,
	showInProgress,
	showMyTournaments,
	showNews,
	showManageNews,
	showNextStages,
	showPlayers,
	showReferees,
	showTeams,
	showUserTeams,
}: MainContentProps) => {
	const { loggedUser } = useLoggedUser();

	const {
		teamUrl,
		playersUrl,
		refereesUrl,
		fieldsUrl,
		upcomingStagesUrl,
		inCourseStagesUrl,
	} = getUrls(loggedUser);

	const [showNewsModal, setShowNewsModal] = useState(false);
	const [selectedNewsItem, setSelectedNewsItem] = useState<News>();

	const renderShowCreateTournament = useMemo(
		() => (
			<div>
				<div className="text-main-page1">{texts.createTournament}</div>
				<a href={`/${uris.createTournament}`}>
					<img
						className="w-32 h-32 mx-auto"
						src={`${process.env.REACT_APP_BACKEND_URL}/media/images/create-tournament.png`}
						alt={texts.createTournament}
					/>
				</a>
			</div>
		),
		[]
	);

	const renderShowMyTournaments = useMemo(
		() => (
			<div>
				<div className="text-main-page1">{texts.myTournaments}</div>
				<a
					href={`/${uris.listTournaments}?${uris.paramFilterResults}=${constants.FILTER_MY_TOURNAMENTS}`}>
					<img
						className="w-32 h-32 mx-auto"
						src={`${process.env.REACT_APP_BACKEND_URL}/media/images/my-tournaments.png`}
						alt={texts.myTournaments}
					/>
				</a>
			</div>
		),
		[]
	);

	const renderShowManageNews = useMemo(
		() => (
			<div>
				<div className="text-main-page1">{texts.listNews}</div>
				<a href={`/${uris.listNews}`}>
					<img
						className="w-32 h-32 mx-auto"
						src={`${process.env.REACT_APP_BACKEND_URL}/media/images/manage-news.png`}
						alt={texts.listNews}
					/>
				</a>
			</div>
		),
		[]
	);

	const renderShowPlayers = useMemo(
		() => (
			<div className="flex flex-col m-2">
				<div className="text-main-page1">{texts.players}</div>
				<div className="m-auto">
					<a href={`/${playersUrl}`}>
						<img
							className="w-32 h-32 mx-auto"
							src={
								process.env.REACT_APP_BACKEND_URL +
								(isFootball(loggedUser.sport)
									? '/media/images/football-players-icon.png'
									: loggedUser.sport === EnumSports.basketball
									? '/media/images/basket-players-icon.png'
									: loggedUser.sport === EnumSports.volleyball
									? '/media/images/volley-players-icon.png'
									: '/media/images/players-icon.png')
							}
							alt={texts.players}
						/>
					</a>
				</div>
			</div>
		),
		[playersUrl, loggedUser]
	);

	const renderShowReferees = useMemo(
		() => (
			<div className="flex flex-col m-2">
				<div className="text-main-page1">{texts.referees}</div>
				<div className="m-auto">
					<a href={`/${refereesUrl}`}>
						<img
							className="w-32 h-32 mx-auto"
							src={`${process.env.REACT_APP_BACKEND_URL}/media/images/referees-icon.png`}
							alt={texts.referees}
						/>
					</a>
				</div>
			</div>
		),
		[refereesUrl]
	);

	const renderShowFields = useMemo(
		() => (
			<div className="flex flex-col m-2">
				<div className="text-main-page1">{texts.fields}</div>
				<div className="m-auto">
					<a href={`/${fieldsUrl}`}>
						<img
							className="w-32 h-32 mx-auto"
							src={`${process.env.REACT_APP_BACKEND_URL}/${
								isFootball(loggedUser.sport)
									? 'media/images/football-fields.png'
									: loggedUser.sport === EnumSports.basketball
									? 'media/images/basket-fields.png'
									: loggedUser.sport === EnumSports.volleyball
									? 'media/images/volley-fields.png'
									: 'media/images/volley-fields.png'
							}`}
							alt={texts.fields}
						/>
					</a>
				</div>
			</div>
		),
		[fieldsUrl, loggedUser]
	);

	const renderShowTeams = useMemo(
		() => (
			<div className="flex flex-col m-2">
				<div className="text-main-page1">{texts.teams}</div>
				<div className="m-auto">
					<a href={`/${teamUrl}`}>
						<img
							className="w-32 h-32 mx-auto"
							src={`${process.env.REACT_APP_BACKEND_URL}/media/images/teams-icon.png`}
							alt={texts.teams}
						/>
					</a>
				</div>
			</div>
		),
		[teamUrl]
	);

	const renderShowNextStages = useMemo(
		() => (
			<div className="m-auto">
				<div className="text-main-page1">{texts.nextStage}</div>
				<a
					href={`/${upcomingStagesUrl}?${uris.paramFilterResults}=${constants.FILTER_UPCOMING_STAGES}`}>
					<img
						className="w-32 h-32 mx-auto"
						src={`${process.env.REACT_APP_BACKEND_URL}/media/images/upcoming-stages.png`}
						alt={texts.nextStage}
					/>
				</a>
			</div>
		),
		[upcomingStagesUrl]
	);

	const renderShowInProgressStages = useMemo(
		() => (
			<div className="m-auto">
				<div className="text-main-page1">{texts.stagesInCourse}</div>
				<a
					href={`/${inCourseStagesUrl}?${uris.paramFilterResults}=${constants.FILTER_IN_COURSE_STAGES}`}>
					<img
						className="w-32 h-32 mx-auto"
						src={`${process.env.REACT_APP_BACKEND_URL}
							${
								isFootball(loggedUser.sport)
									? '/media/images/football-in-progress-stages.webp'
									: loggedUser.sport === EnumSports.basketball
									? '/media/images/basket-in-progress-stages.png'
									: loggedUser.sport === EnumSports.volleyball
									? '/media/images/volley-in-progress-stages.png'
									: '/media/images/volley-in-progress-stages.png'
							}`}
						alt={texts.stagesInCourse}
					/>
				</a>
			</div>
		),
		[inCourseStagesUrl, loggedUser]
	);

	const renderShowUserTeams = useMemo(
		() =>
			loggedUser.belongingTeams?.map((team: Team, index: number) => (
				<div className="mx-auto my-1" key={index}>
					<div className="text-main-page1">{team.name}</div>
					<a href={`/${uris.viewTeam}?${uris.paramTeamId}=${team.id}`}>
						<img
							className="w-32 h-32 mx-auto"
							src={`${process.env.REACT_APP_BACKEND_URL}/${
								team.teamImageUri ?? 'media/images/team-icon.png'
							}`}
							alt={team.name}
						/>
					</a>
				</div>
			)),
		[loggedUser]
	);

	const renderShowNews = useMemo(() => {
		const breakPoints = [
			{ width: 1, itemsToShow: 1 },
			{ width: 580, itemsToShow: 2 },
			{ width: 865, itemsToShow: 3 },
		];

		return news?.length ? (
			<div
				id="newsContainer"
				className="flex mx-auto mt-2 w-full text-center pt-2 md:w-5/6">
				<CustomCarousel breakPoints={breakPoints} itemsToShow={3} isRTL={false}>
					{news.map((newsItem) => (
						<NewsCard
							key={newsItem.id}
							newsItem={newsItem}
							setShowNewsModal={setShowNewsModal}
							setSelectedNewsItem={setSelectedNewsItem}
						/>
					))}
				</CustomCarousel>
			</div>
		) : (
			<EmptyState text={texts.noNewsYet} />
		);
	}, [news]);

	const renderModal = useMemo(
		() => (
			<Modal
				cancelButtonText={texts.close}
				cancelButtonAction={() => setShowNewsModal(false)}
				startOpened
				closeAfterOk
				okWithEnterKeyPressing
				content={
					selectedNewsItem &&
					(selectedNewsItem.secondaryImages?.length ||
					selectedNewsItem.subtitle ||
					selectedNewsItem.finalText ? (
						<VerticalNews {...selectedNewsItem} />
					) : (
						selectedNewsItem.title && (
							<HorizontalNews
								{...selectedNewsItem}
								title={selectedNewsItem.title}
							/>
						)
					))
				}
			/>
		),
		[selectedNewsItem]
	);

	const renderContent = useMemo(
		() => (
			<div className="flex flex-col w-full">
				{(showPlayers || showReferees || showTeams || showFields) && (
					<div className="mx-auto w-full rounded mt-10 text-center flex flex-wrap justify-center   md:w-5/6">
						{showTeams && renderShowTeams}
						{showPlayers && renderShowPlayers}
						{showReferees && renderShowReferees}
						{showFields && renderShowFields}
					</div>
				)}
				<div className="flex flex-col mx-auto w-full text-center md:w-5/6">
					{(showInProgress ||
						showNextStages ||
						showUserTeams ||
						showCreateTournament ||
						showMyTournaments) && (
						<div className="flex flex-wrap gap-2 w-full mx-auto mt-5 rounded justify-center      md:w-5/6">
							{showNextStages && renderShowNextStages}
							{showInProgress && renderShowInProgressStages}
							{showUserTeams && renderShowUserTeams}
							{showCreateTournament && renderShowCreateTournament}
							{showMyTournaments && renderShowMyTournaments}
							{showManageNews && renderShowManageNews}
						</div>
					)}
				</div>
				{showNews && renderShowNews}
				{showNewsModal && renderModal}
				<SignOutModal />
			</div>
		),
		[
			renderModal,
			renderShowCreateTournament,
			renderShowFields,
			renderShowInProgressStages,
			renderShowManageNews,
			renderShowMyTournaments,
			renderShowNews,
			renderShowNextStages,
			renderShowPlayers,
			renderShowReferees,
			renderShowTeams,
			renderShowUserTeams,
			showCreateTournament,
			showFields,
			showInProgress,
			showManageNews,
			showMyTournaments,
			showNews,
			showNewsModal,
			showNextStages,
			showPlayers,
			showReferees,
			showTeams,
			showUserTeams,
		]
	);

	return renderContent;
};

export default MainContent;
