import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GenericState {
	isLoading: boolean;
	loadingText?: string;
}

const initialState: GenericState = {
	isLoading: false,
};

export const genericSlice = createSlice({
	name: 'generic',
	initialState,
	reducers: {
		setIsLoading: (_, { payload }: PayloadAction<string | undefined>) => ({
			isLoading: true,
			loadingText: payload,
		}),
		setIsNotLoading: () => ({ isLoading: false }),
	},
});

export const { setIsLoading, setIsNotLoading } = genericSlice.actions;

export default genericSlice.reducer;
