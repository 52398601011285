import { texts } from '@app';
import MediaService from '@services/MediaService';
import NewsService from '@services/NewsService';
import {
	CreatingNews,
	EnumEntityTypes,
	EnumFileTypes,
	EnumNewsFields,
	EnumSports,
	Image,
	News,
	NewsImageWithFile,
	Result,
} from '@types';

import { convertFromBackend } from './ConvertActions';

type ResultWithSingleNews = Result & { news?: News };
type NewsId = { newsId?: number };
type ResultWithNewsAndRelatedId = Result & NewsId;

type ResultWithNews = Result & {
	news?: News[];
	canEdit?: boolean;
	sport?: EnumSports;
};

type ResultWithMainAndSecondaryImages = Result & {
	mainImage?: Image;
	secondaryImages?: Image[];
};

export const createNewsAction =
	({
		callback,
		...news
	}: {
		callback: (result: ResultWithNewsAndRelatedId) => void;
	} & CreatingNews) =>
	async () => {
		let result: ResultWithNewsAndRelatedId;

		try {
			const response = await NewsService.createNewsService(news);

			const data = convertFromBackend(response.data) as NewsId;

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				newsId: data.newsId,
			};
		} catch (error) {
			console.error(error); // eslint-disable-line no-console
			result = {
				success: false,
				forbidden: true,
				message: texts.errorCreatingNews,
			};
		}
		callback(result);
	};

export const getNewsForUserAction =
	(
		userId: number,
		numberOfNews: number,
		pageNumber: number,
		callback: (result: ResultWithNews) => void
	) =>
	async () => {
		let result: ResultWithNews;

		try {
			const response = await NewsService.getNewsForUser(
				userId,
				numberOfNews,
				pageNumber
			);

			const news = (convertFromBackend(response?.data?.news) as News[]).map(
				(newsWithRelativeUri) => ({
					...newsWithRelativeUri,
					uri: `${process.env.REACT_APP_BACKEND_URL}/${newsWithRelativeUri.uri}`,
				})
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				news,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getNewsForEntityAction =
	(
		entityType: EnumEntityTypes,
		relatedId: number,
		secondaryRelatedId: number | undefined,
		callback: (result: ResultWithNews) => void
	) =>
	async () => {
		let result: ResultWithNews;

		try {
			const response = await NewsService.getNewsForEntityService(
				entityType,
				relatedId,
				secondaryRelatedId
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};

			if (result.success) {
				const convertedResponse = convertFromBackend(response.data) as {
					news: News[];
					canEdit: boolean;
					sport: EnumSports;
				};

				result.news = convertedResponse.news.map((newsWithRelativeUri) => ({
					...newsWithRelativeUri,
					uri: `${process.env.REACT_APP_BACKEND_URL}/${newsWithRelativeUri.uri}`,
				}));

				result.canEdit = convertedResponse.canEdit;
				result.sport = convertedResponse.sport;
			}
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getNewsAction =
	(newsId: number, callback: (result: ResultWithSingleNews) => void) =>
	async () => {
		let result: ResultWithSingleNews;

		try {
			const response = await NewsService.getNews(newsId);

			const convertedResponse = convertFromBackend(response?.data) as {
				news: News;
				sport: EnumSports;
			};

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				news: {
					...convertedResponse.news,
					files: convertedResponse.news.files.map((file) => ({
						...file,
						uri: `${process.env.REACT_APP_BACKEND_URL}/${file.uri}`,
					})),
					sport: convertedResponse.sport,
				},
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const uploadNewsImagesAction =
	(
		mainImage: NewsImageWithFile,
		secondaryImages: NewsImageWithFile[],
		newsId: number,
		callback: (result: ResultWithMainAndSecondaryImages) => void
	) =>
	async () => {
		let result: ResultWithMainAndSecondaryImages;

		try {
			const newsImages = [mainImage, ...secondaryImages];

			const addingImages = newsImages
				.filter((newsImage) => newsImage.uri && newsImage.file)
				.map((newsImage) => ({
					file: newsImage.file as File,
					mediaUri: newsImage.uri as string,
				}));

			const response = await MediaService.uploadNewsImagesService(
				addingImages,
				newsId,
				EnumFileTypes.IMAGE,
				!!mainImage.uri && !!mainImage.file
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				mainImage: response.data.files[0],
				secondaryImages: response.data.files.slice(1),
			};
		} catch (error) {
			console.error(error); // eslint-disable-line no-console
			result = {
				success: false,
				forbidden: false,
				message: texts.errorCreatingNews,
			};
		}
		callback(result);
	};

export const searchNewsAction =
	(
		concept: EnumEntityTypes,
		dateFrom: Date,
		dateTo: Date,
		callback: (result: ResultWithNews) => void
	) =>
	async () => {
		let result: ResultWithNews;

		try {
			const response = await NewsService.searchNewsService(
				concept,
				dateFrom,
				dateTo
			);

			const news = (convertFromBackend(response?.data?.news) as News[]).map(
				(newsWithRelativeUri) => ({
					...newsWithRelativeUri,
					uri: `${process.env.REACT_APP_BACKEND_URL}/${newsWithRelativeUri.uri}`,
				})
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
				news,
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const deleteNewsAction =
	(newsId: number, callback: (result: Result) => void) => async () => {
		let result: Result;

		try {
			const response = await NewsService.deleteNewsService(newsId);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const updateNewsFieldAction =
	(
		newsId: number,
		field: EnumNewsFields,
		value: string | NewsImageWithFile,
		callback: (result: Result) => void
	) =>
	async () => {
		let result: Result;

		try {
			const response = await NewsService.updateNewsFieldService(
				newsId,
				field,
				value
			);

			result = {
				success: response.data.code === 0,
				forbidden: response.data.code === 403,
				message: response.data.desc ?? '',
			};
		} catch (error) {
			result = {
				success: false,
				forbidden: true,
				message: texts.errorGeneric(),
			};
		}
		callback(result);
	};

export const getNewsRelatedIds = ({
	relatedId,
	secondaryRelatedId,
}: {
	relatedId?: number;
	secondaryRelatedId?: number;
}) => {
	if (!relatedId) return [];
	if (!secondaryRelatedId) return [relatedId];
	return [relatedId, secondaryRelatedId];
};
