import { texts } from '@app';
import ConfirmActionModal from '@molecules/ConfirmActionModal';
import { useState } from 'react';

type ConfirmActionModalWithButtonProps = {
	onConfirm: () => void;
	triggerButtonClassName: string;
	buttonText: string;
	modalQuestion: string;
	modalTitle: string;
	modalOkButtonText?: string;
	modalCancelButtonText?: string;
	textToConfirmAction?: string; // When an action needs special attention, use this to force the user to input a text before confirming.
};

const ConfirmActionModalWithButton = ({
	onConfirm,
	triggerButtonClassName,
	buttonText,
	modalQuestion,
	modalTitle,
	modalOkButtonText = texts.confirm,
	modalCancelButtonText = texts.cancel,
	textToConfirmAction,
}: ConfirmActionModalWithButtonProps) => {
	const [modalCallback, setModalCallback] =
		useState<React.Dispatch<React.SetStateAction<() => void>>>();

	return (
		<>
			{modalCallback && (
				<ConfirmActionModal
					title={modalTitle}
					question={modalQuestion}
					okButtonAction={modalCallback}
					setOkButtonAction={setModalCallback}
					okButtonText={modalOkButtonText}
					cancelButtonText={modalCancelButtonText}
					textToConfirmAction={textToConfirmAction}
				/>
			)}

			<button
				type="button"
				className={triggerButtonClassName}
				title={buttonText}
				onClick={() =>
					setModalCallback(() => () => {
						onConfirm();
					})
				}>
				{buttonText}
			</button>
		</>
	);
};

export default ConfirmActionModalWithButton;
