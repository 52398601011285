import { z } from 'zod';

// Define your environment variable schema
const envSchema = z.object({
	REACT_APP_API_URL: z.string().url(),
	REACT_APP_BACKEND_URL: z.string().url(),
	REACT_APP_URL: z.string().url(),
	REACT_APP_T3_URL: z.string().url().optional(),
	PORT: z.string().optional().default('3000').transform(Number),
	FAST_REFRESH: z.boolean().optional(),
	REACT_APP_DEFAULT_API_KEY: z.string(),
	REACT_APP_GOOGLE_CLIENT_ID: z.string(),
	REACT_APP_FACEBOOK_APP_ID: z.string(),
	REACT_APP_FEATURE_FLAG_SHOW_OBS_IN_MATCH_PDF: z.string().optional(),
});

// Parse and validate the environment variables
const validatedEnv = envSchema.safeParse(process.env);

if (!validatedEnv.success) {
	// eslint-disable-next-line no-console
	console.error(
		'❌ Invalid environment variables:',
		validatedEnv.error.format()
	);
	process.exit(1); // Exit if validation fails
}

// Export the validated environment variables
// eslint-disable-next-line import/prefer-default-export
export const env = validatedEnv.data;
