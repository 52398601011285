import { convertToBackend } from '@actions/ConvertActions';
import { constants, deleteRequest, getRequest, postRequest } from '@app';
import {
	CreatingNews,
	EnumEntityTypes,
	EnumNewsFields,
	NewsImageWithFile,
} from '@types';
import dayjs from 'dayjs';

const NewsService = {
	createNewsService: async ({
		relatedId,
		secondaryRelatedId,
		entityType,
		templateNumber,
		description = '',
		descriptionColor,
		backgroundColor = '000000',
		date,
		title = '',
		titleColor,
		subtitle = '',
		subtitleColor,
		finalText = '',
		finalTextColor,
		mainImage,
		bodyColor = '000000',
		secondaryImages = [],
		secondaryImagesBackgroundColor = '000000',
	}: CreatingNews) => {
		const bodyRequest = {
			relatedId,
			secondaryRelatedId,
			entityType,
			title,
			titleColor,
			templateType: templateNumber,
			date,
			mainFile: {
				type: 'image',
				size: mainImage?.file?.size ?? 0,
				extension: mainImage?.file?.name.split('.').pop() ?? 'png',
			},
			carouselImgs: secondaryImages.map((secondaryImage) => ({
				type: 'image',
				size: secondaryImage?.file?.size ?? 0,
				extension: secondaryImage?.file?.name.split('.').pop() ?? 'png',
			})),
			files: [],
			texts: [] as {
				text: string;
				color: string;
				type: string;
			}[],
			colors: [] as {
				color: string;
				type: string;
			}[],
		};

		if (templateNumber === constants.TEMPLATE_NEWS_HORIZONTAL) {
			bodyRequest.texts = [
				{
					text: description,
					color: descriptionColor ?? '',
					type: 'description',
				},
			];

			bodyRequest.colors = [
				{
					color: backgroundColor,
					type: 'background',
				},
			];
		} else if (templateNumber === constants.TEMPLATE_NEWS_VERTICAL) {
			bodyRequest.texts = [
				{
					text: subtitle,
					color: subtitleColor ?? '',
					type: 'subtitle',
				},
				{
					text: description,
					color: descriptionColor ?? '',
					type: 'description',
				},
				{
					text: finalText,
					color: finalTextColor ?? '',
					type: 'final',
				},
			];
			bodyRequest.colors = [
				{
					color: bodyColor,
					type: 'background',
				},
				{
					color: backgroundColor,
					type: 'header',
				},
				{
					color: secondaryImagesBackgroundColor,
					type: 'footer',
				},
			];
		} else {
			return { data: { code: 400, desc: 'Template not found' } };
		}

		const response = await postRequest(
			'create_news.php',
			convertToBackend(bodyRequest) as Record<string, string>
		);
		return response;
	},

	getNews: async (newsId: number) => {
		const response = await getRequest(
			'get_news.php',
			convertToBackend({ newsId }) as Record<string, string>
		);
		return response;
	},

	getNewsForUser: async (userId: number, numNews: number, page: number) => {
		const response = await getRequest(
			'get_user_news.php',
			convertToBackend({ userId, numNews, page }) as Record<string, string>
		);
		return response;
	},

	getNewsForEntityService: (
		entityType: EnumEntityTypes,
		relatedId: number,
		secondaryRelatedId: number | undefined
	) => {
		const parameters: {
			entityType: string;
			relatedId: number;
			secondaryRelatedId?: number;
		} = { entityType, relatedId };
		if (secondaryRelatedId) parameters.secondaryRelatedId = secondaryRelatedId;
		return getRequest(
			'get_entity_news.php',
			convertToBackend(parameters) as Record<string, string>
		);
	},

	searchNewsService: async (
		entityType: EnumEntityTypes,
		dateFrom: Date,
		dateTo: Date
	) => {
		const response = await getRequest(
			'search_news.php',
			convertToBackend({
				entityType,
				dateFrom: dayjs(dateFrom).format('YYYY-MM-DD'),
				dateTo: dayjs(dateTo).format('YYYY-MM-DD'),
			}) as Record<string, string>
		);
		return response;
	},

	deleteNewsService: async (newsId: number) => {
		const response = await deleteRequest(
			'delete_news.php',
			convertToBackend({ newsId }) as Record<string, string>
		);
		return response;
	},

	updateNewsFieldService: async (
		newsId: number,
		field: EnumNewsFields,
		valueToSend: string | NewsImageWithFile
	) => {
		const value =
			typeof valueToSend === 'string'
				? valueToSend
				: {
						type: 'image',
						size: valueToSend?.file?.size ?? 0,
						extension: valueToSend?.file?.name.split('.').pop() ?? 'png',
				  };

		const response = await postRequest(
			'update_news_field.php',
			convertToBackend({
				newsId,
				field,
				value,
			}) as Record<string, string>
		);
		return response;
	},
};

export default NewsService;
